import styled from "styled-components";

const Panel = styled.div`
    background-color: #52c565;
    color: #fff;
    padding: 12px;
    font-weight: 500;
    font-size: 14px;
    border-radius: 16px;
    border-top-left-radius: 7px;
`

export const BoxText = (props) => {
    return(
        <Panel
            {...props}
        >
           <props.icon style={{padding: 0}} size={20}/> {props.text}
        </Panel>
    )

}