import React from 'react';
import InputMask from 'react-input-mask';
import { InputText } from './InputText';


export const InputMasked = (props) => {
    return (
        <InputMask onChange={props.onChange} {...props} >
          {() => <InputText {...props} />}
        </InputMask>
    )
}