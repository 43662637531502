import React from "react"
import { MdCheck, MdClear } from "react-icons/md"
import { Button, Card, CardBody, Col, Row } from "reactstrap"
import bandeira from '../../assets/bandeiras/mastercard.svg';
import NumberFormat from 'react-number-format';
import styled from "styled-components";

var Barcode = require('react-barcode');

const PagamentoDetalhes = styled.div`
text-align: center;
.tabela-machine-sifrao{
    font-size: 1.2rem;
}
.valor-painel{
    display: flex;
    place-content: center;
    .secound-value{
        border-left: 1px solid
    }
    h4{
        padding-right: 15px;
        padding-left: 15px;
        small{
            margin: 3px;
            font-size: 13px
        }
    }
}
.title-painel{
}
.painel-valor-total{}
`;

const getBarcode = (billetNumber) => {
  return billetNumber.replace(/^(\d{4})(\d{5})\d{1}(\d{10})\d{1}(\d{10})\d{1}(\d{15})$/, '$1$5$2$3$4');
}

export const StepFinal = (props) => {

    const getValorTotal = () => {
        var valor = 0;
        
        props.maquininhas.forEach(e => {
            valor += (e.valor * e.quantidade);
            
            if(props.funcionarios){
                var funcionarios = parseInt(props.funcionarios);

                if(funcionarios > e.funcionarios){
                    var valor_adicional = 25 * (funcionarios - e.funcionarios);
                    valor += valor_adicional;
                }

            }

            if (props.certificado_a1) {
                if (props.certificado_a1 > 0) {
                    var valor_adicional_a1 = 99;
                    valor += valor_adicional_a1;
                }
            }

            if (props.endereco_fical) {
                if (props.endereco_fical > 0) {
                    var valor_adicional_end = 99;
                    valor += valor_adicional_end;
                }
            }

        });
        if(props.cupom !== null){
            if(props.cupom.condicao !== 'plano'){
                if(props.cupom.valor_tipo == 'porcentagem'){
                    var desconto = (valor * props.cupom.valor) / 100;
                    valor = valor - desconto;
                }
                else{
                    valor = valor - props.cupom.valor;
                }
            }
            else{
                var desconto = 0;

                props.maquininhas.forEach(e => {
                    if(e.texto == props.cupom.plano){
                        if(props.cupom.valor_tipo == 'porcentagem'){
                            desconto = (e.valor * props.cupom.valor) / 100;
                            valor = valor - desconto;
                        }
                        else{
                            valor = valor - props.cupom.valor;
                        }
                    }
                })

            }
        }


        return valor;
    }
    
    const getImageParam = (cartao) => {
      if (cartao !== '' &&  cartao !== null) {
        var image = require('../../assets/bandeiras/' +
        cartao +
          '.svg');
        return image.default;
      }
      else {
        var image = require('../../assets/bandeiras/undefined.svg');
        return image.default;
      }
    };

    const validar_integracao = () => {
        var validar = false;
        props.maquininhas.forEach(p => {
            if(p.modelo == 'INTEGRACAO-PAGAMENTO'){
                validar = true;
            }
        });

        return validar;
    }
    return(
        <div style={{backgroundColor: 'white', padding: '25px', borderRadius: '25px'}}>
            <center></center>
            <br/>
                {props.api.tipo_pagamento !== 'erro' ?
                    <>
                        <h4 style={{textAlign: 'center'}}> <MdCheck size="40" color="green"/> Compra realizada com sucesso</h4>
                        <hr style={{maxWidth: '700px'}}/>
                    </>
                :
                    <>
                        <h4 style={{textAlign: 'center'}}> <MdClear size="40" color="red"/> Compra não realizada</h4>
                        <hr style={{maxWidth: '700px'}}/>
                    </>
                }
            <center>
                {props.api.tipo_pagamento == 'boleto' ?
                    <Card style={{maxWidth: '700px'}}>
                        <CardBody>
                            <p>ID da transação: {props.api.id_transacao}</p>
                            <div style={{display: 'flex'}}>
                            <Barcode 
                                format="ITF"
                                margin={10} 
                                height={90} 
                                width={1.7}
                                displayValue={false} 
                                value={getBarcode(props.api.codigo_barras)}/>
                            </div>
                                <hr/>
                                O boleto foi enviado para seu e-mail<br/>
                                <a href={props.api.url} target="_blank">Ver Boleto</a>
                        </CardBody>
                        <img src={"https://afiliados.aqbank.com.br/tracking?v="+getValorTotal()+"&o="+props.api.id_transacao+"&f=p"} alt="" width="1" height="1" style={{ border:0 }} />
                    </Card>
                    : props.api.tipo_pagamento == 'cartao' ? 
                        <Card style={{maxWidth: '700px'}}>
                            <CardBody>
                                <Row>
                                    <Col md={3}>
                                        <img src={getImageParam('undefined')} />
                                    </Col>
                                    <Col style={{textAlign: 'left'}}>
                                        <p>ID da transação: {props.api.id_transacao}
                                        <br/>Parcelas: {props.api.parcelas}x</p>
                                    </Col>
                                </Row>
                            </CardBody>
                            <img src={"https://afiliados.aqbank.com.br/tracking?v="+getValorTotal()+"&o="+props.api.id_transacao+"&f=p"} alt="" width="1" height="1" style={{ border:0 }} />
                        </Card>
                    :
                    <Card style={{maxWidth: '700px'}}>
                        <CardBody>
                            {props.api.message_error}
                        </CardBody>
                    </Card>

                }
                <hr style={{maxWidth: '700px'}}/>
                <PagamentoDetalhes>
                    <div className="painel-valor-total">
                        {props.cupom !== null ?
                        <>
                            <h5>Cupom de desconto:</h5>
                            <h4 style={{color: 'green'}}><span className="tabela-machine-sifrao">{props.cupom.valor_tipo === 'moeda' ? 'R$' : ''} </span> - {props.cupom.valor_tipo === 'moeda' ? parseFloat(getValorTotal() / 12).toFixed(2).replace('.', ',') : parseInt(props.cupom.valor)} <small>{props.cupom.valor_tipo === 'porcentagem' ? '%' : ''} de desconto</small></h4>
                            <hr/>
                        </>
                        :
                        null}
                        {validar_integracao() == false ?
                            <div>
                                <h5>VALOR TOTAL</h5>
                                <h4 style={{margin: 0}}><span className="tabela-machine-sifrao">R$ </span>{parseFloat(getValorTotal() / props.api.parcelas).toFixed(2).replace('.', ',')}<small> {props.api.parcelas}x</small></h4>
                            </div>
                        :
                        <></>}
                    </div>
                </PagamentoDetalhes>
            </center>
        </div>
    )
}
