import React from 'react';
import { VscCheck, VscError } from 'react-icons/vsc';
import PropagateLoader from "react-spinners/PropagateLoader";
import { Button } from 'reactstrap';

const ModalReturn = (props) => {
    return(
        <>
            <center hidden={
                props.loading === true && 
                props.error_message.length === 0 &&  
                props.success_message.length === 0 ? false : true 
                }
                >
                <div className="sweet-loading">
                    <PropagateLoader color={'#52c565'} loading={20} size={30} />
                </div>
            </center>

            {/*Success */}
            <div hidden={
                props.loading === true && 
                props.error_message.length > 0 ? false : true
                }>
                <center>
                    <VscError style={{height: '100px', width: '100px', opacity: '0.7'}} color="red" />
                    <br/>
                    <h5 style={{fontWeight: '400'}}>{props.error_message}</h5>
                    <Button color="primary" onClick={props.onError} style={{width: '90px', height: '40px'}}>Voltar</Button>
                    <br/>
                </center>
            </div>

            {/*Success */}
            <div hidden={
                props.loading === true && 
                props.success_message.length > 0 ? false : true}>
                <center>
                    <VscCheck style={{height: '100px', width: '100px', opacity: '0.7'}} color="green" />
                    <br/>
                    <h4 style={{fontWeight: '400'}}>{props.success_message}</h4>
                    <br/>
                    <Button color="dark" onClick={() => props.onClose(false)} style={{width: '90px', height: '40px'}}>OK</Button>
                </center>
            </div>
        </>
    );
}

export default ModalReturn;