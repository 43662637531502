import axios from "axios";
import { cnpj, cpf } from "cpf-cnpj-validator";
import { useState } from "react";
import { MdCheck, MdPeople, MdSend } from "react-icons/md";
import { toast } from "react-toastify";
import { FormFeedback, FormGroup, Input } from "reactstrap";
import { ENDPOINT } from "../../auth/endpoint";
import { ButtonDefault } from "../Button/ButtonDefault";
import { InputMasked } from "../InputText/InputMasked";
import FadeLoader from "react-spinners/FadeLoader";
import { ModalTokenDocs } from "./ModalTokenDocs";
import { StepAll } from "../Pages/StepAll";
import Select from 'react-select';
import { ModalStore } from "../Modal/ModalStore";
import { InputText } from "../InputText/InputText";



var CryptoJS = require("crypto-js");

const StepPessoaisDocs = (props) => {
    const [loading, setLoading] = useState(false);
    const [documento, setDocumento] = useState('');
    const [documento_invalid, setDocumentoInvalid] = useState(false);
    const [documento_cpf, setDocumentoCpf] = useState('');
    const [documento_cpf_invalid, setDocumentoCpfInvalid] = useState(false);
    const [nome_completo, setNomeCompleto] = useState('');
    const [nome_completo_invalid, setNomeCompletoInvalid] = useState(false);
    const [data_nascimento, setDataNascimento] = useState('');
    const [data_nascimento_invalid, setDataNascimentoInvalid] = useState(false);
    const [id, setId] = useState(0);
    const [step, setStep] = useState(0);
    const [isCnpj, setIsCnpj] = useState(null);
    const [token_validate_show, setTokenValidateShow] = useState(false);

    
    const [nome, setNome] = useState('');
    const [documents, setDocuments] = useState([]);

    const [modal_store, setModalStore] = useState(false);
    const [request, setRequest] = useState({});
    const [isCnpjType, setCnpjTipo] = useState(false);
    const [tipo_documento, setTipoDocumento] = useState(null);
    
    const getUrlUser = () => {
        var url_user = 'cpf_cnpj='+documento.replace(/([^\d])+/gim, '')+'&name_or_business='+nome_completo+
                                '&birthdate='+data_nascimento.replace(/([^\d])+/gim, '');
        
        if(isCnpj === true){
            url_user += '&cpf_business='+documento_cpf.replace(/([^\d])+/gim, '')
        }
        if(nome_completo.length > 9){
            function dataAtualFormatada(){
                var currentdate = new Date(); 
                let weekday = new Date().toLocaleString('en-us', {weekday:'long'});
                var datetime = currentdate.getDate().toString().padStart(2, '0') + "/"
                            + (currentdate.getMonth()+1).toString().padStart(2, '0')  + "/" 
                            + currentdate.getFullYear() + " - "  
                            + currentdate.getHours().toString().padStart(2, '0') + ":"  
                            + currentdate.getMinutes().toString().padStart(2, '0') + ":" 
                            + currentdate.getSeconds().toString().padStart(2, '0') + '#' + weekday;

                return datetime;
            }
            function dataAtual(){
                var currentdate = new Date(); 
                var datetime = currentdate.getDate().toString().padStart(2, '0') + "/"
                            + (currentdate.getMonth()+1).toString().padStart(2, '0')  + "/" 
                            + currentdate.getFullYear() + " - "  
                            + currentdate.getHours().toString().padStart(2, '0') + ":"  
                            + currentdate.getMinutes().toString().padStart(2, '0') + ":" 
                            + currentdate.getSeconds().toString().padStart(2, '0');

                return datetime;
            }

            var tokenHash = CryptoJS.MD5(
                'aqbank_key@'
                +nome_completo.replace(/\s/g, '').substr(0, 9).toUpperCase()+''
                +nome_completo.replace(/\s/g, '').substr(-8).toUpperCase()
                +'#'+documento.replace(/([^\d])+/gim, '').substr(0, 3)
                +'#'+documento.replace(/([^\d])+/gim, '')
                +'#'+data_nascimento.replace(/([^\d])+/gim, '')
                +'#d'+dataAtualFormatada());
            url_user += '&aqbank_key='+tokenHash.toString()+'&date_atual='+dataAtual();

            props.setUrlUser({
                documento: documento,
                nome_completo: nome_completo,
                data_nascimento: data_nascimento,
                documento_cpf: documento_cpf
            });
        }

        return url_user;
    }
    const getUser = () => {
        var url = ENDPOINT + 'documentacao/gerar-token/'+documento.replace(/([^\d])+/gim, '')+'?'+getUrlUser();
        axios.get(url)
        .then((response) => {
            /* setLoading(false);
            var response = response.data.data;
            setTokenValidateShow(true); */
            
            setLoading(false);
            var response = response.data.data;
            if(response.status === true){
                if(response.validacao_token === false){
                    var data = response.user
                    setNome(data.nome + ' '+ data.sobrenome);
                    setDocumento(data.cpf !== "" ? data.cpf : data.cnpj);
                    setCnpjTipo(data.cpf !== "" ? false : true);
                    setId(data.id);
                    props.setUserId(data.id);

                    if(data.cpf == ""){
                        props.setValidarCpf(true);
                    }

                    if(step === 0){
                        setStep(1);
                    }
                    setDocuments(data.documentos);
                }
                else{
                    setTokenValidateShow(true);
                }
            }
            else{
                var data = response.user;
                data.tipo = response.tipo;
                data.email_validate = response.email_validate;
                setModalStore(true);
                setRequest(data);
            }

        })
        .catch((error) => {
            console.log(error)
            var response = error.response.data;
            setLoading(false);
            setStep(0)
            toast.error(response.errors); 
        });
    }

    const handleChange = (event, callback) => {
        let value = event.target.value;

        callback(value);
    }

    const onSubmit = () => {
        if(validField() === true){
            setLoading(true);
            getUser();
        }
    }

    const validField = () => {
        var invalids = 0;


        if(isCnpj == false){
            if(cpf.isValid(documento.replace(/([^\d])+/gim, '')) == true){
                setDocumentoInvalid(false);
            }
            else{
                invalids++;
                setDocumentoInvalid(true);
            }
        }
        else{
            if(cnpj.isValid(documento.replace(/([^\d])+/gim, '')) == true){
                setDocumentoInvalid(false);
            }
            else{
                invalids++;
                setDocumentoInvalid(true);
            }

            if(cpf.isValid(documento_cpf.replace(/([^\d])+/gim, '')) == true){
                setDocumentoCpfInvalid(false);
            }
            else{
                invalids++;
                setDocumentoCpfInvalid(true);
            }
        }

        if(nome_completo.length > 0){
            setNomeCompletoInvalid(false);
        }
        else{
            invalids++;
            setNomeCompletoInvalid(true);
        }

        if(data_nascimento.replace(/([^\d])+/gim, '').length > 7){
            setDataNascimentoInvalid(false);
        }
        else{
            invalids++;
            setDataNascimentoInvalid(true);
        }

        if(invalids > 0){
            return false;
        }

        return true;
    }

    const stepCurrent = () => {
        setStep(2);
    }
    const step_current_fisica = () => {
        //if(isCnpjType === false){
            switch(step){
                case 0: return <></>
                case 1: return <StepAll
                                    id={id} 
                                    stepCurrent={stepCurrent}
                                    documentos_enviados={documents}
                                    documento={documento}
                                    url_user={getUrlUser}
                                    documentacao={true}
                                    tipo_documento={tipo_documento} />
                case 2: return <>
                        <center><MdCheck size="50" /></center>
                        <h5 style={{textAlign: 'center'}}>Os documentos foram enviados com sucesso</h5> <br/>
                        <div style={{marginLeft: 8}}>
                            <small>
                                Qualquer dúvida sobre o status de aprovação de seu credenciamento, segue contato de nosso time:<br/>
                                <br/>
                                Telefone de atendimento credenciamento: <a href="tel:1121890770">(11) 2189-0770</a> <br/>
                                WhatsApp de atendimento credenciamento: <a href="tel:1121890770">(11) 2189-0770</a> <br/>
                                Horário de atendimento: Segunda à Sexta - 08:30 ás 17:30, em dias úteis.
                            </small>
                        </div>
                        <br/>
                    </>
                default: return <></>
            }
        //}
    }

    const onCloseToken = (token_validate_show = false, request = {}) => {
        setTokenValidateShow(token_validate_show);
        props.setTokenEmail(request.token_email);
        var data = request.user;
        setId(data.id);
        setDocumento(data.cpf !== "" ? data.cpf : data.cnpj);
        props.setUserId(data.id);
        setStep(1);
        setDocuments(data.documentos);
    }


    const updateRequestNotRefresh = (name, value) => {
        setRequest(prevState => ({...prevState, [name]: value }));
    }

    const updateRequest = (name, value) => {
        setRequest(prevState => ({...prevState, [name]: value }));
        getUser();
    }

    const onClose = (user = null, close = false) => {
        if(user !== null){
            var data = user;
            setId(data.id);
            props.setUserId(data.id);
            setNome(data.nome + ' '+ data.sobrenome);
            setDocumento(data.cpf !== "" ? data.cpf : data.cnpj);
            setCnpjTipo(data.cpf !== "" ? false : true);
            if(step === 0){
                setStep(1);
            }
            setDocuments(data.documentos);
        }

        setModalStore(close);
        setRequest({});
    }

    return (
        <>
            <div hidden={loading} style={{ minHeight: '250px'}}>
                    {step === 0 ?
                        <>
                            <h4>
                                <MdPeople style={{padding: 0}}/> INICIO 
                            </h4>
                            <br/>
                            <h6 style={{paddingLeft: '5px'}}>Informe o documento de tipo de cadastro que você fez ou deseja fazer:</h6>
                            <FormGroup>
                                &nbsp;&nbsp;<input type="radio" checked={isCnpj === false} onChange={() => setIsCnpj(false)}/>&nbsp;CPF &nbsp;&nbsp;
                                <input type="radio" checked={isCnpj} onChange={() => setIsCnpj(true)}/>&nbsp;CNPJ
                            </FormGroup>
                            {isCnpj !== null ?
                                <>
                                    <div style={{display: 'flex', width: '100%'}}>
                                        <InputMasked
                                            mask={isCnpj === true ? "99.999.999/9999-99" :"999.999.999-99"}
                                            value={documento}
                                            placeholder={isCnpj === true ? 'CNPJ' : 'CPF'}
                                            invalid={documento_invalid}
                                            onChange={(e) => handleChange(e, setDocumento)}
                                        />
                                    </div>
                                    {isCnpj === true ?
                                        <div style={{display: 'flex', width: '100%', margin: 5}}>
                                            <InputMasked
                                                mask={"999.999.999-99"}
                                                value={documento_cpf}
                                                placeholder={'CPF'}
                                                invalid={documento_cpf_invalid}
                                                onChange={(e) => handleChange(e, setDocumentoCpf)}
                                            />
                                        </div>
                                    : null}
                                    <div style={{display: 'flex', width: '100%', margin: 5}}>
                                        <InputMasked
                                            mask="99/99/9999"
                                            placeholder="Data de nascimento"
                                            value={data_nascimento}
                                            invalid={data_nascimento_invalid}
                                            onChange={(e) => handleChange(e, setDataNascimento)}
                                        />
                                    </div>
                                    <div style={{display: 'flex', width: '100%', margin: 5}}>
                                        <InputText
                                            placeholder="Nome completo"
                                            value={nome_completo}
                                            invalid={nome_completo_invalid}
                                            onChange={(e) => handleChange(e, setNomeCompleto)}
                                        />
                                        <ButtonDefault onClick={() => onSubmit()}><MdSend style={{padding: 0, margin: 5}}/></ButtonDefault>
                                    </div>
                                </>
                            :
                            <></>}
                        </>
                        :
                        step_current_fisica()
                    }
                    {modal_store === true ?
                        <ModalStore 
                            visible={modal_store}
                            user={request}
                            onClose={onClose}
                            updateRequest={updateRequest}
                            updateRequestNotRefresh={updateRequestNotRefresh}
                        />
                        :
                        <></>
                    }
            </div>
            <div hidden={!loading} style={{height: '100px', textAlign: 'center', textAlignLast: 'center'}}>
                <center>
                    <FadeLoader color={'#004723'} margin={0} loading={50} size={60} /><br />
                </center>
            </div>
            <ModalTokenDocs 
                visible={token_validate_show} 
                url_user={getUrlUser}
                document={documento.replace(/([^\d])+/gim, '')}
                onClose={onCloseToken}
            />
        </>
    )
}

export default StepPessoaisDocs;