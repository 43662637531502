import axios from "axios";
import { useState } from "react";
import { FaRegPaperPlane } from "react-icons/fa";
import { toast } from "react-toastify";
import { Button, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ENDPOINT } from "../../auth/endpoint";
import { InputMasked } from "../InputText/InputMasked";
import ModalReturn from "../ReturnsApiModals/ModalReturn";

export const ModalTokenDocs = (props) => {
    const [token, setToken] = useState('');
    const [tokenInvalid, setTokenInvalid] = useState(false);
    const [request_api, setApi] = useState({
        request: {},
        loading: false,
        error_message: '',
        success_message: ''
    })

    const validateFields = () => {
        var invalids = 0;
        if(token.replace(/([^\d])+/gim, '').length > 5){
            setTokenInvalid(false);
        }
        else{
            invalids++;
            setTokenInvalid(true);
        }

        return invalids;
    }

    const onRegister = () => {
        if(validateFields() === 0){
            store();
        }
    }

    const store = () => {
        setApi(prevState => ({...prevState, loading: true }));

        var url = ENDPOINT+"documentacao/confirmar_token/"+props.document.replace(/([^\d])+/gim, '')+'?'+props.url_user();

        var formData = new FormData();
        formData.append('token', token);

        axios.post(url, formData)
        .then((response) => {
            var data = response.data;
            onData(data);
        })
        .catch((error) => {
            var response_erro = error.response.data;
            onData(response_erro);
            toast.error(response_erro.errors);
        });

    }

    const onError = () => {
        setApi(prevState => ({...prevState, loading: false }));
        setApi(prevState => ({...prevState, error_message: '' }));
        setApi(prevState => ({...prevState, request: {} }));
    }

    const onData = (data) => {
        if(data.success === true){
            setApi(prevState => ({...prevState, request: data.data }));
            setApi(prevState => ({...prevState, success_message: data.message }));
        }
        else{
            setApi(prevState => ({...prevState, error_message: data.errors }));
        }
    }

    const onClose = () => {
        var request_is = request_api.request;

        //if(request_is.type){
        request_is.token_email = token;
        //}

        props.onClose(false, request_is);

        setToken('');
        setTokenInvalid(false);
        setApi({
            request: {},
            loading: false,
            error_message: '',
            success_message: ''
        })

    } 
    return (<Modal isOpen={props.visible} centered>
        <ModalHeader>
            Código enviado para o e-mail
        </ModalHeader>
        <ModalBody hidden={request_api.loading}>
            <center>
                <FaRegPaperPlane style={{height: '30px', width: '30px', margin: '15px', color: 'black'}}/>
                <h5>Digite o código enviado para seu e-mail</h5>
                <Form style={{width: '80%'}}>
                    <FormGroup>
                        <InputMasked
                            mask="999999"
                            name="token"
                            placeholder="Token enviado para E-mail"
                            value={token}
                            invalid={tokenInvalid}
                            onChange={(e) => setToken(e.target.value)}
                        />
                    </FormGroup>
                </Form>
            </center>
        </ModalBody>
        <div 
            className="modal-body"
            hidden={!request_api.loading}
            style={{
                minHeight: '114px',
                display: 'flex',
                alignItems: 'center',
                alignSelf: 'center',
                paddingBottom: '47px'
            }}>
            <ModalReturn
                error_message={request_api.error_message}
                success_message={request_api.success_message}
                loading={request_api.loading}
                onClose={onClose}
                onError={onError}
            />

        </div>
        <ModalFooter hidden={request_api.loading}>
            <Button color="danger" onClick={() => props.onClose()}>Cancelar</Button>
            <Button color="success" onClick={() => onRegister()}>Confirmar</Button>
        </ModalFooter>
    </Modal>)
}