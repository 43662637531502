/* import React from "react"
import { MdAttachMoney, MdCheck, MdCreditCard, MdPeople } from "react-icons/md"
import { AiOutlineBarcode } from "react-icons/ai"
import { Button, ButtonGroup, Col, Form, FormGroup, Input, Label, Row } from "reactstrap"
import { InputMasked } from "../InputText/InputMasked"

export const StepPessoais = () => {
    return(
        <>
            <br/>
            <h4>
                <MdPeople style={{padding: 0}}/> DADOS PESSOAIS - CREDENCIAMENTO
            </h4>
            <br/>
            <p>Para começar, escolha o tipo de conta que deseja realizar e digite o documento referente:</p>
            <Form>
                <InputMasked mask="999.999.999-99" placeholder="CPF/CNPJ" />
            </Form>
            <br/>
            <hr/>
            <Button color="success" style={{float: 'right'}}>
                <h5 style={{margin: 0}}> Próximo</h5>
            </Button>
        </>
    )
} */


import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, FormGroup, Input, Row } from "reactstrap";
import styled from "styled-components";
import Basic from "../../DropFile";
import 'bootstrap/dist/css/bootstrap.min.css';
import background from '../../assets/background.svg';
import axios from "axios";
import { ENDPOINT } from '../../auth/endpoint';
import { toast } from "react-toastify";
import { InputMasked } from "../InputText/InputMasked";
import { ButtonDefault } from "../Button/ButtonDefault";
import { MdHome, MdSend, MdCameraFront, MdCheck, MdMenu, MdBusiness, MdPeople } from "react-icons/md";
import { HiIdentification } from "react-icons/hi";
import { cpf, cnpj } from 'cpf-cnpj-validator';
import { StepFinal } from "../Pages/StepFinal";
import { ModalStore } from "../Modal/ModalStore";
import { StepAll } from "./StepAll";
import FadeLoader from "react-spinners/FadeLoader";
import { LayouPagamento } from "./LayouPagamento";
import { ModalToken } from "../Modal/ModalToken";
import { InputText } from "../InputText/InputText";

var CryptoJS = require("crypto-js");

export const list_docs = [
    { value: 'Documento de Identidade (RG/CNH) - Frente', label: 'Documento de Identidade (RG/CNH) - Frente'},
    { value: 'Documento de Identidade (RG/CNH) - Verso', label: 'Documento de Identidade (RG/CNH) - Verso'},
    { value: 'Comprovante de Endereço', label: 'Comprovante de Endereço'},
    { value: 'Selfie com você segurando seu documento de Identidade', label: 'Selfie com você segurando seu documento de Identidade'},
    { value: 'Certificado MEI', label: 'Certificado MEI'}
];

const Div = styled.div`
    min-height: 100vh;
    height: 100%;
    max-width: 100%;
    background: #906a9f;
    background-image: url(${background});
    margin: 0;
    min-height: 100vh;
    font-family: 'FiraSans Light';
    
    .dropzone {
        background: #f5f5f5;
        border: 1px dashed #c2c2c2;
        border-radius: 3px;
        text-align: center;
        padding: 36px;
        font-family: "Montserrat", sans-serif;
        font-size: 12px;
        font-weight: 600;
        font-family: 'FiraSans Light';
      }
      
      .dropzone:hover {
        background: rgb(144 106 159);
        border: 1px dashed #fff;
        color: #fff;
      }

      .dropzone:[drop-active=true] {
        background: rgb(144 106 159);
        border: 1px dashed #fff;
        color: #fff;
      }
      
    @media screen and (max-width: 800px) {
        .name-step{
            display: none;
        }
    }

      
`;
export const StepPessoais = (props) => {
    const [nome, setNome] = useState('');
    const [loading, setLoading] = useState(false);
    const [documento, setDocumento] = useState('');
    const [documento_invalid, setDocumentoInvalid] = useState(false);
    const [documento_cpf, setDocumentoCpf] = useState('');
    const [documento_cpf_invalid, setDocumentoCpfInvalid] = useState(false);
    const [nome_completo, setNomeCompleto] = useState('');
    const [nome_completo_invalid, setNomeCompletoInvalid] = useState(false);
    const [data_nascimento, setDataNascimento] = useState('');
    const [data_nascimento_invalid, setDataNascimentoInvalid] = useState(false);
    const [id, setId] = useState(0);
    const [step, setStep] = useState(0);
    const [isCnpj, setIsCnpj] = useState(null);
    const [documents, setDocuments] = useState([]);

    const [modal_store, setModalStore] = useState(false);
    const [request, setRequest] = useState({});

    const [isCnpjType, setCnpjTipo] = useState(false);
    const [tipo_documento, setTipoDocumento] = useState(null);
    
    const [token_validate_show, setTokenValidateShow] = useState(false);

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const isCnpjChange = (value, isValue) => {
        props.setTipoValue(isValue);
        setIsCnpj(value);
    }
    const validField = () => {
        var invalids = 0;
        if(isCnpj == true){
            if(cnpj.isValid(documento.replace(/([^\d])+/gim, ''))){
                setDocumentoInvalid(false);
            }
            else{
                invalids++;
                setDocumentoInvalid(true);
            }
    
            if(cpf.isValid(documento_cpf.replace(/([^\d])+/gim, ''))){
                setDocumentoCpfInvalid(false);
            }
            else{
                invalids++;
                setDocumentoCpfInvalid(true);
            }
        }
        else{
            if(cpf.isValid(documento.replace(/([^\d])+/gim, ''))){
                setDocumentoInvalid(false);
            }
            else{
                invalids++;
                setDocumentoInvalid(true);
            }
        }

        if(data_nascimento.replace(/([^\d])+/gim, '').length > 7){
            setDataNascimentoInvalid(false);
        }
        else{
            invalids++;
            setDataNascimentoInvalid(true);
        }

        if(nome_completo.length > 7){
            setNomeCompletoInvalid(false);
        }
        else{
            invalids++;
            setNomeCompletoInvalid(true);
        }
        
        if(invalids > 0){
            return false;
        }

        return true;
    }

    const onSubmit = () => {
        if(validField() === true){
            setLoading(true);
            getUser();
        }
    }

    const updateRequestNotRefresh = (name, value) => {
        setRequest(prevState => ({...prevState, [name]: value }));
    }

    const updateRequest = (name, value) => {
        setRequest(prevState => ({...prevState, [name]: value }));
        getUser();
    }

    const getUrlUser = () => {
        var url_user = 'cpf_cnpj='+documento.replace(/([^\d])+/gim, '')+'&name_or_business='+nome_completo+
                                '&birthdate='+data_nascimento.replace(/([^\d])+/gim, '');
        if(isCnpj === true){
            url_user += '&cpf_business='+documento_cpf.replace(/([^\d])+/gim, '')
        }

        if(nome_completo.length > 9){
            function dataAtualFormatada(){
                var currentdate = new Date(); 
                let weekday = new Date().toLocaleString('en-us', {weekday:'long'});
                var datetime = currentdate.getDate().toString().padStart(2, '0') + "/"
                            + (currentdate.getMonth()+1).toString().padStart(2, '0')  + "/" 
                            + currentdate.getFullYear() + " - "  
                            + currentdate.getHours().toString().padStart(2, '0') + ":"  
                            + currentdate.getMinutes().toString().padStart(2, '0') + ":" 
                            + currentdate.getSeconds().toString().padStart(2, '0') + '#' + weekday;

                return datetime;
            }

            function dataAtual(){
                var currentdate = new Date(); 
                let weekday = new Date().toLocaleString('en-us', {weekday:'long'});
                var datetime = currentdate.getDate().toString().padStart(2, '0') + "/"
                            + (currentdate.getMonth()+1).toString().padStart(2, '0')  + "/" 
                            + currentdate.getFullYear() + " - "  
                            + currentdate.getHours().toString().padStart(2, '0') + ":"  
                            + currentdate.getMinutes().toString().padStart(2, '0') + ":" 
                            + currentdate.getSeconds().toString().padStart(2, '0');

                return datetime;
            }

            var tokenHash = CryptoJS.MD5(
                'aqbank_key@'
                +nome_completo.replace(/\s/g, '').substr(0, 9).toUpperCase()+''
                +nome_completo.replace(/\s/g, '').substr(-8).toUpperCase()
                +'#'+documento.replace(/([^\d])+/gim, '').substr(0, 3)
                +'#'+documento.replace(/([^\d])+/gim, '')
                +'#'+data_nascimento.replace(/([^\d])+/gim, '')
                +'#d'+dataAtualFormatada());

            url_user += '&aqbank_key='+tokenHash.toString()+'&date_atual='+dataAtual();
        }

        return url_user;
    }

    const setarUrl = () => {
        props.setUrlUser({
            documento: documento,
            nome_completo: nome_completo,
            data_nascimento: data_nascimento,
            documento_cpf: documento_cpf
        });

    }
    const getUser = () => {
        setarUrl();
        var url = ENDPOINT + 'contabilidade/usuario?cpf_cnpj='+documento.replace(/([^\d])+/gim, '')+'&name_or_business='+nome_completo+
                                '&birthdate='+data_nascimento.replace(/([^\d])+/gim, '');
        if(isCnpj === true){
            url += '&cpf_business='+documento_cpf.replace(/([^\d])+/gim, '')
        }

        if(nome_completo.length > 9){
            function dataAtualFormatada(){
                var currentdate = new Date(); 
                let weekday = new Date().toLocaleString('en-us', {weekday:'long'});
                var datetime = currentdate.getDate().toString().padStart(2, '0') + "/"
                            + (currentdate.getMonth()+1).toString().padStart(2, '0')  + "/" 
                            + currentdate.getFullYear() + " - "  
                            + currentdate.getHours().toString().padStart(2, '0') + ":"  
                            + currentdate.getMinutes().toString().padStart(2, '0') + ":" 
                            + currentdate.getSeconds().toString().padStart(2, '0') + '#' + weekday;

                return datetime;
            }

            function dataAtual(){
                var currentdate = new Date(); 
                var datetime = currentdate.getDate().toString().padStart(2, '0') + "/"
                            + (currentdate.getMonth()+1).toString().padStart(2, '0')  + "/" 
                            + currentdate.getFullYear() + " - "  
                            + currentdate.getHours().toString().padStart(2, '0') + ":"  
                            + currentdate.getMinutes().toString().padStart(2, '0') + ":" 
                            + currentdate.getSeconds().toString().padStart(2, '0');

                return datetime;
            }

            var tokenHash = CryptoJS.MD5(
                'aqbank_key@'
                +nome_completo.replace(/\s/g, '').substr(0, 9).toUpperCase()+''
                +nome_completo.replace(/\s/g, '').substr(-8).toUpperCase()
                +'#'+documento.replace(/([^\d])+/gim, '').substr(0, 3)
                +'#'+documento.replace(/([^\d])+/gim, '')
                +'#'+data_nascimento.replace(/([^\d])+/gim, '')
                +'#d'+dataAtualFormatada());

            url += '&aqbank_key='+tokenHash.toString()+'&date_atual='+dataAtual();
        }

        axios.get(url)
        .then((response) => {
            setLoading(false);
            var response = response.data.data;
            if(response.status === true){
                if(response.validacao_token === false){
                    var data = response.user
                    setNome(data.nome + ' '+ data.sobrenome);
                    setDocumento(data.cpf !== "" ? data.cpf : data.cnpj);
                    setCnpjTipo(data.cpf !== "" ? false : true);
                    setId(data.id);
                    props.setUserId(data.id);
                    if(response.usuario_cliente === true){
                        if(data.cpf == ""){
                            props.setValidarCpf(true);
                        }

                        if(step === 0){
                            //setStep(1);
                            props.setStepCurrent(2);
                        }
                    }
                    props.validar_cupom(data);
                    setDocuments(data.documentos);
                }
                else{
                    setTokenValidateShow(true);
                }
            }
            else{
                var data = response.user;
                data.tipo = response.tipo;
                data.email_validate = response.email_validate;
                console.log(data)
                setModalStore(true);
                setRequest(data);
            }

        })
        .catch((error) => {
            console.log(error)
            var response = error.response.data;
            setLoading(false);
            setStep(0)
            toast.error(response.errors); 
        });
    }
    useEffect(() => {
        //getUser();
    }, []);

    const handleChange = (event, callback) => {
        let value = event.target.value;

        callback(value);
    }

    const onClose = (user = null, close = false) => {
        if(user !== null){
            var data = user;
            props.validar_cupom(data);
            setId(data.id);
            props.setUserId(data.id);
            setNome(data.nome + ' '+ data.sobrenome);
            setDocumento(data.cpf !== "" ? data.cpf : data.cnpj);
            setCnpjTipo(data.cpf !== "" ? false : true);
            props.setStepCurrent(2);
            setDocuments(data.documentos);
        }

        setModalStore(close);
        setRequest({});
    }

    const onCloseToken = (token_validate_show = false, request = {}) => {
        setTokenValidateShow(token_validate_show);
        console.log(request)
        var data = request.user
        setNome(data.nome + ' '+ data.sobrenome);
        setDocumento(data.cpf !== "" ? data.cpf : data.cnpj);
        setCnpjTipo(data.cpf !== "" ? false : true);
        setId(data.id);
        props.setTokenEmail(request.token_email);
        props.setUserId(data.id);
        props.setStepCurrent(2);
        props.validar_cupom(data);
        setDocuments(data.documentos);
    }
    
    const validar_se_existe_link_ou_eccomerce = () => {
        var validar = false;
        props.maquininhas.forEach(p => {
            if(p.modelo == 'LINKPAY' || p.modelo == 'SHOPAQPAGO'){
                validar = true;
            }
        });

        return validar;
    }
    
    return(
        <>
            <br/>
            <LayouPagamento 
                dropdownOpen={dropdownOpen}
                setDropdownOpen={setDropdownOpen}
                toggleDropdown={toggle}
                {...props}>
                {/* <h4>
                    <MdPeople style={{padding: 0}}/> CREDENCIAMENTO 
                </h4> */}
                <div hidden={modal_store == true || token_validate_show == true}>
                    <div hidden={loading} style={{ minHeight: '250px'}}>
                        <>
                            <p>Para começar, informe o tipo de cadastro que você deseja:</p>
                            <FormGroup>
                                &nbsp;&nbsp;
                                <input type="radio" checked={isCnpj === false} onChange={() => isCnpjChange(false, 'Não tenho CNPJ e desejo abrir minha empresa')}/>&nbsp;Não tenho CNPJ e desejo abrir minha empresa &nbsp;&nbsp;<br/>
                                &nbsp;&nbsp;<input type="radio" checked={isCnpj} onChange={() => isCnpjChange(true, 'Tenho CNPJ e desejo mudar de contador')}/>&nbsp;Tenho CNPJ e desejo mudar de contador
                            </FormGroup>
                            {isCnpj !== null ?
                                <>
                                {isCnpj === true ?
                                    <div style={{display: 'flex', width: '100%', margin: 5}}>
                                        <InputMasked
                                            mask={"999.999.999-99"}
                                            value={documento_cpf}
                                            placeholder={'CPF'}
                                            invalid={documento_cpf_invalid}
                                            onChange={(e) => handleChange(e, setDocumentoCpf)}
                                        />
                                    </div>
                                : null}

                                <div style={{display: 'flex', width: '100%', margin: 5}}>
                                    <InputMasked
                                        mask={isCnpj === true ? "99.999.999/9999-99" :"999.999.999-99"}
                                        value={documento}
                                        placeholder={isCnpj === true ? 'CNPJ' : 'CPF'}
                                        invalid={documento_invalid}
                                        onChange={(e) => handleChange(e, setDocumento)}
                                    />
                                </div>
                                <div style={{display: 'flex', width: '100%', margin: 5}}>
                                    <InputMasked
                                        mask="99/99/9999"
                                        placeholder="Data de nascimento"
                                        value={data_nascimento}
                                        invalid={data_nascimento_invalid}
                                        onChange={(e) => handleChange(e, setDataNascimento)}
                                    />
                                </div>
                                <div style={{display: 'flex', width: '100%', margin: 5}}>
                                    <InputText
                                        placeholder="Nome completo"
                                        value={nome_completo}
                                        invalid={nome_completo_invalid}
                                        onChange={(e) => handleChange(e, setNomeCompleto)}
                                    />
                                    <ButtonDefault onClick={() => onSubmit()}><MdSend style={{padding: 0, margin: 5}}/></ButtonDefault>
                                </div>
                            </>
                            :
                            <></>}
                        </>
                    </div>
                    <div hidden={!loading} style={{height: '100px', textAlign: 'center', textAlignLast: 'center'}}>
                        <center>
                            <FadeLoader color={'#004723'} margin={0} loading={50} size={60} /><br />
                        </center>
                    </div>
                </div>
                {modal_store === true ?
                    <ModalStore 
                        visible={modal_store}
                        user={request}
                        onClose={onClose}
                        updateRequest={updateRequest}
                        url_user={getUrlUser}
                        updateRequestNotRefresh={updateRequestNotRefresh}
                    />
                    :
                    <></>
                }
                <ModalToken 
                    visible={token_validate_show} 
                    document={documento.replace(/([^\d])+/gim, '')}
                    onClose={onCloseToken}
                    url_user={getUrlUser}
                />
            </LayouPagamento>
            
        </>
    )
}