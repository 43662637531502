import axios from "axios";
import { useState } from "react";
import { FaRegPaperPlane } from "react-icons/fa";
import { toast } from "react-toastify";
import { Button, Form, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ENDPOINT } from "../../auth/endpoint";
import { InputMasked } from "../InputText/InputMasked";
import ModalReturn from "../ReturnsApiModals/ModalReturn";
import ReactCodeInput from 'react-code-input';
import { MdArrowBack, MdArrowForward } from "react-icons/md";

export const ModalToken = (props) => {
    const [token, setToken] = useState('');
    const [tokenInvalid, setTokenInvalid] = useState(false);
    const [request_api, setApi] = useState({
        request: {},
        loading: false,
        error_message: '',
        success_message: ''
    })

    const validateFields = () => {
        var invalids = 0;
        if(token.replace(/([^\d])+/gim, '').length > 5){
            setTokenInvalid(false);
        }
        else{
            invalids++;
            setTokenInvalid(true);
        }

        return invalids;
    }

    const onRegister = () => {
        if(validateFields() === 0){
            store();
        }
    }

    const store = () => {
        setApi(prevState => ({...prevState, loading: true }));

        var url = ENDPOINT+"contabilidade/confirmar_token/"+props.document.replace(/([^\d])+/gim, '');

        if(props.url_user){
            url += '?'+props.url_user();
        }
        
        var formData = new FormData();
        formData.append('token', token);

        axios.post(url, formData)
        .then((response) => {
            var data = response.data;
            onData(data);
        })
        .catch((error) => {
            var response_erro = error.response.data;
            onData(response_erro);
            toast.error(response_erro.errors);
        });

    }

    const onError = () => {
        setApi(prevState => ({...prevState, loading: false }));
        setApi(prevState => ({...prevState, error_message: '' }));
        setApi(prevState => ({...prevState, request: {} }));
    }

    const onData = (data) => {
        if(data.success === true){
            setApi(prevState => ({...prevState, request: data.data }));
            setApi(prevState => ({...prevState, success_message: data.message }));
        }
        else{
            setApi(prevState => ({...prevState, error_message: data.errors }));
        }
    }

    const onClose = () => {
        var request_is = request_api.request;

        //if(request_is.type){
        request_is.token_email = token;
        //}

        props.onClose(false, request_is);

        setToken('');
        setTokenInvalid(false);
        setApi({
            request: {},
            loading: false,
            error_message: '',
            success_message: ''
        })

    } 
    return (
    <div hidden={!props.visible} centered>
        <div hidden={request_api.loading}>
                <p className="bold" style={{ fontSize: '12px' }}>Enviamos um código para seu e-mail</p>
                <Form>
                    <FormGroup>
                        <ReactCodeInput
                            type="text"
                            fields={6}
                            value={token}
                            onChange={setToken}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input hidden invalid={tokenInvalid} />
                        <FormFeedback>Token obrigatório</FormFeedback>
                    </FormGroup>
                </Form>
        </div>
        <div hidden={!request_api.loading}>
            <ModalReturn
                error_message={request_api.error_message}
                success_message={request_api.success_message}
                loading={request_api.loading}
                onClose={onClose}
                onError={onError}
            />

        </div>
        <div hidden={request_api.loading}>
            <hr/>
            <Button color="danger" style={{margin: '5px'}} onClick={() => props.onClose()}><MdArrowBack/> Cancelar</Button>
            <Button className="btn-next" style={{margin: '5px'}} onClick={() => onRegister()}>Confirmar <MdArrowForward/></Button>
        </div>
    </div>
)
}