import { Button, Col, Collapse, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import styled from "styled-components";
import { StepPessoais } from "./StepPessoais";
import aqcontabil_lines from './../../assets/lines-back.svg';

const DataPayment = styled.div`
    background-color: #F1FCF9;
    border-radius: 5px;
    height: 100%;
    padding: 34px;
`
const PagamentoDetalhes = styled.div`
text-align: center;
.tabela-machine-sifrao{
    font-size: 1.2rem;
}
.valor-painel{
    display: flex;
    place-content: center;
    .secound-value{
        border-left: 1px solid
    }
    h4{
        padding-right: 15px;
        padding-left: 15px;
        small{
            margin: 3px;
            font-size: 13px
        }
    }
}
.title-painel{
}
.painel-valor-total{}
`

export const LayouPagamento = (props) => {
    const getImage = (modelo) => {
        var image_src = '';
        props.maquininhas_list.forEach(e => {
            if (modelo === e.modelo) {
                image_src = e.image
            }
        });

        return image_src;
    }

    const getValorTotal = () => {
        var valor = 0;
        props.maquininhas.forEach(e => {
            valor += (e.valor * e.quantidade);

            if (props.funcionarios) {
                var funcionarios = parseInt(props.funcionarios);

                if (funcionarios > e.funcionarios) {
                    var valor_adicional = 25 * (funcionarios - e.funcionarios);
                    valor += valor_adicional;
                }
            }

            if (props.certificado_a1) {
                if (props.certificado_a1 > 0) {
                    var valor_adicional_a1 = 99;
                    valor += valor_adicional_a1;
                }
            }

            if (props.endereco_fical) {
                if (props.endereco_fical > 0) {
                    var valor_adicional_end = 99;
                    valor += valor_adicional_end;
                }
            }
        });


        if (props.cupom !== null) {
            if (props.cupom.condicao !== 'plano') {
                if (props.cupom.valor_tipo == 'porcentagem') {
                    var desconto = (valor * props.cupom.valor) / 100;
                    valor = valor - desconto;
                }
                else {
                    valor = valor - props.cupom.valor;
                }
            }
            else {
                var desconto = 0;

                props.maquininhas.forEach(e => {
                    if (e.texto == props.cupom.plano) {
                        if (props.cupom.valor_tipo == 'porcentagem') {
                            desconto = (e.valor * props.cupom.valor) / 100;
                            valor = valor - desconto;
                        }
                        else {
                            valor = valor - props.cupom.valor;
                        }
                    }
                })

            }
        }

        return valor;
    }


    const validar_integracao = () => {
        var validar = false;
        props.maquininhas.forEach(p => {
            if (p.modelo == 'INTEGRACAO-PAGAMENTO') {
                validar = true;
            }
        });

        return validar;
    }
    return (

        <div>
            <div className="mobile-pagamento">
                <Button className="button-payment-resumo" color="light" onClick={props.toggleDropdown} style={{ marginBottom: '1rem', width: '100%' }}>
                    Ver resumo de compra
                </Button>
                <Collapse isOpen={props.dropdownOpen}>
                    <DataPayment>
                        <PagamentoDetalhes>
                            <h4 className="title-painel" style={{ fontSize: '24px' }}>Resumo</h4>
                            <br />
                            {props.maquininhas.map(p => (
                                <div>
                                    <small style={{ color: '#65656E', fontSize: '14px' }}>Plano selecionado:</small>
                                    <h5 className="bold" style={{ fontSize: '14px' }}>{p.texto2}</h5>
                                    <div style={{ marginTop: 0 }}>
                                        <h3 style={{ color: '#174945', fontSize: '36px', fontWeight: '600', margin: 0 }}>R$ {parseFloat(p.valor * p.quantidade).toFixed(2).replace('.', ',')}</h3>
                                        <b style={{ color: '#737373', fontSize: '16px' }}>/mês</b>
                                    </div>
                                    <div style={{ marginTop: 15 }}>
                                        {p.funcionarios < parseInt(props.funcionarios) ?
                                            <>
                                                <Row style={{ fontSize: '14px' }}>
                                                    <Col style={{ minWidth: '170px' }}>
                                                        Funcionário adicional:
                                                    </Col>
                                                    <Col>
                                                        {parseInt(props.funcionarios) - p.funcionarios}x R$ {parseFloat(25 * (parseInt(props.funcionarios) - p.funcionarios)).toFixed(2).replace('.', ',')}
                                                    </Col>
                                                </Row>
                                            </>
                                            : <></>}
                                        {props.certificado_a1 > 0 ?
                                            <Row style={{ fontSize: '14px' }}>
                                                <Col style={{ minWidth: '170px' }}>
                                                    Certificado A1:
                                                </Col>
                                                <Col>
                                                    {parseInt(props.certificado_a1)}x R$ 99.00
                                                </Col>
                                            </Row>
                                            :
                                            <></>
                                        }
                                        {props.endereco_fical > 0 ?
                                            <Row style={{ fontSize: '14px' }}>
                                                <Col style={{ minWidth: '170px' }}>
                                                    Endereço Fiscal:
                                                </Col>
                                                <Col>
                                                    {parseInt(props.endereco_fical)}x R$ 99.00
                                                </Col>
                                            </Row>
                                            :
                                            <></>
                                        }
                                    </div>
                                </div>
                            ))}
                            <br />
                            <div className="painel-valor-total">
                                {props.cupom !== null ?
                                    <>
                                        <b>Cupom de desconto:</b>
                                        <p style={{ color: 'green', margin: 0 }}><span className="tabela-machine-sifrao">{props.cupom.valor_tipo === 'moeda' ? 'R$' : ''} </span> - {props.cupom.valor_tipo === 'moeda' ? parseFloat(getValorTotal() / 12).toFixed(2).replace('.', ',') : parseInt(props.cupom.valor)}<small>{props.cupom.valor_tipo === 'porcentagem' ? '%' : ''} de desconto</small></p>
                                        {props.cupom.condicao == 'plano' ?
                                            <>
                                                <small>no plano {props.cupom.plano}</small>
                                            </>
                                            :
                                            props.cupom.condicao == 'planos' ?
                                                <>
                                                    <small>nos planos {props.cupom.planos.map(e => e.plano + ", ")}</small>
                                                </>
                                                :
                                                null}
                                    </>
                                    :
                                    null}
                                <hr style={{ backgroundColor: 'rgb(5, 189, 174)' }} />
                                {validar_integracao() == false ?
                                    <div>
                                        <span style={{ fontSize: '16px', fontWeight: '400' }}>Valor total</span>
                                        <div className="valor-painel">
                                            <h5 className="bold" style={{ fontSize: '24px', fontWeight: '600', color: '#2F9461' }}>
                                                <span className="tabela-machine-sifrao">R$ </span>{parseFloat(getValorTotal()).toFixed(2).replace('.', ',')}
                                            </h5>
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                        </PagamentoDetalhes>
                    </DataPayment>
                </Collapse>

            </div>

            <Row>
                <Col md={8} className="border-card">
                    {props.children}
                </Col>
                <Col md={4} className="desktop-pagamento">
                    <DataPayment>
                        <PagamentoDetalhes>
                            <h4 className="title-painel" style={{ fontSize: '24px' }}>Resumo</h4>
                            <br />
                            {props.maquininhas.map(p => (
                                <div>
                                    <small style={{ color: '#65656E', fontSize: '14px' }}>Plano selecionado:</small>
                                    <h5 className="bold" style={{ fontSize: '14px' }}>{p.texto2}</h5>
                                    <div style={{ marginTop: 0 }}>
                                        <h3 style={{ color: '#174945', fontSize: '36px', fontWeight: '600', margin: 0 }}>R$ {parseFloat(p.valor * p.quantidade).toFixed(2).replace('.', ',')}</h3>
                                        <b style={{ color: '#737373', fontSize: '16px' }}>/mês</b>
                                    </div>
                                    <div style={{ marginTop: 15 }}>
                                        {p.funcionarios < parseInt(props.funcionarios) ?
                                            <>
                                                <Row style={{ fontSize: '14px' }}>
                                                    <Col style={{ minWidth: '169px', textAlign: 'left' }}>
                                                        Funcionário adicional:
                                                    </Col>
                                                    <Col>
                                                        {parseInt(props.funcionarios) - p.funcionarios}x R$ {parseFloat(25 * (parseInt(props.funcionarios) - p.funcionarios)).toFixed(2).replace('.', ',')}
                                                    </Col>
                                                </Row>
                                            </>
                                            : <></>}

                                        {props.certificado_a1 > 0 ?
                                            <Row style={{ fontSize: '14px' }}>
                                                <Col style={{ minWidth: '165px', textAlign: 'left' }}>
                                                    Certificado A1:
                                                </Col>
                                                <Col>
                                                    {parseInt(props.certificado_a1)}x R$ 99.00
                                                </Col>
                                            </Row>
                                            :
                                            <></>
                                        }

                                        {props.endereco_fical > 0 ?
                                            <Row style={{ fontSize: '14px', textAlign: 'left' }}>
                                                <Col style={{ minWidth: '165px' }}>
                                                    Endereço Fiscal:
                                                </Col>
                                                <Col>
                                                    {parseInt(props.endereco_fical)}x R$ 99.00
                                                </Col>
                                            </Row>
                                            :
                                            <></>
                                        }
                                    </div>
                                </div>
                            ))}
                            {/* {props.maquininhas.map(p => (
                    <div style={{padding: 15, borderBottom: '1px solid #e5e5e5'}}>
                        <Row>
                            <Col md={4} style={{ alignSelf: 'center' }}>
                                <img width="100px" src={getImage(p.modelo)}/><br/>
                            </Col>
                            <Col md={8}>
                                <h5>
                                     {p.texto}<br/>
                                     <small>
                                         {p.descricao}<br/>
                                     </small>
                                    <small>
                                    <div style={{marginTop: 15}}>
                                        <u>VALOR MENSAL</u> <br/> R$ {parseFloat(p.valor * p.quantidade).toFixed(2).replace('.', ',')}
                                    </div>
                                    <div style={{marginTop: 15}}>
                                        {p.funcionarios < parseInt(props.funcionarios) ?
                                            <><u>{parseInt(props.funcionarios) - p.funcionarios} FUNCIONARIO(S) ADICIONAIS</u> <br/> R$ {parseFloat(25 * (parseInt(props.funcionarios) - p.funcionarios)).toFixed(2).replace('.', ',')}</>
                                        : <></>}
                                    </div>
                                    </small>
                                </h5>
                            </Col>
                        </Row>
                        

                    </div>
                ))} */}

                            <br />
                            <div className="painel-valor-total">
                                {props.cupom !== null ?
                                    <>
                                        <b>Cupom de desconto:</b>
                                        <p style={{ color: 'green', margin: 0 }}><span className="tabela-machine-sifrao">{props.cupom.valor_tipo === 'moeda' ? 'R$' : ''} </span> - {props.cupom.valor_tipo === 'moeda' ? parseFloat(getValorTotal() / 12).toFixed(2).replace('.', ',') : parseInt(props.cupom.valor)}<small>{props.cupom.valor_tipo === 'porcentagem' ? '%' : ''} de desconto</small></p>
                                        {props.cupom.condicao == 'plano' ?
                                            <>
                                                <small>no plano {props.cupom.plano}</small>
                                            </>
                                            :
                                            props.cupom.condicao == 'planos' ?
                                                <>
                                                    <small>nos planos {props.cupom.planos.map(e => e.plano + ", ")}</small>
                                                </>
                                                :
                                                null}
                                    </>
                                    :
                                    null}
                                <hr style={{ backgroundColor: 'rgb(5, 189, 174)' }} />
                                {validar_integracao() == false ?
                                    <div>
                                        <span style={{ fontSize: '16px', fontWeight: '400' }}>Valor total</span>
                                        <div className="valor-painel">
                                            <h5 className="bold" style={{ fontSize: '24px', fontWeight: '600', color: '#2F9461' }}>
                                                <span className="tabela-machine-sifrao">R$ </span>{parseFloat(getValorTotal()).toFixed(2).replace('.', ',')}
                                            </h5>
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                        </PagamentoDetalhes>
                    </DataPayment>
                </Col>
            </Row>
        </div>);
}