import { useState } from "react";
import { Button, Card, CardBody, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { MdAdd } from "react-icons/md";
import d195 from "../../assets/d195.webp";
import mp35p from "../../assets/mp35p.png";
import logo_basico from "../../assets/icons-aqcontabil/basico.svg";
import logo_padrao from "../../assets/icons-aqcontabil/padrao.svg";
import logo_contador_essencial from "../../assets/icons-aqcontabil/contador__essencial.svg";
import logo_contador_pro from "../../assets/icons-aqcontabil/contador_pro.svg";
import contador_empreendedor from "../../assets/icons-aqcontabil/contador_empreendedor.svg";
import contador_a1 from "../../assets/icons-aqcontabil/contador_a1.svg";

const maquininhas_list = [
    {
        modelo: 'CONTADOR-BASICO',
        texto: 'Contador Básico',
        texto2: 'Básico',
        valor: 79.00,
        quantidade_disponivel: 1,
        limite: 255,
        funcionarios: 0,
        descricao: 'Sem Funcionário Incluso',
        image: logo_basico,
        entrega: ''
    },
    {
        modelo: 'CONTADOR-PADRAO',
        texto: 'Contador Padrão',
        texto2: 'Padrão',
        valor: 129.00,
        quantidade_disponivel: 1,
        limite: 1,
        funcionarios: 1,
        descricao: '1 Funcionário Incluso',
        image: logo_padrao,
        entrega: ''
    },
    {
        modelo: 'CONTADOR-EMPREENDEDOR',
        texto: 'Contador Empreendedor Plus+',
        texto2: 'Empreendedor',
        valor: 293.00,
        quantidade_disponivel: 1,
        limite: 1,
        funcionarios: 1,
        descricao: '1 Funcionários Inclusos',
        image: contador_empreendedor,
        entrega: ''
    },
    {
        modelo: 'CONTADOR-ESSENCIAL',
        texto: 'Contador Essencial',
        texto2: 'Essencial',
        valor: 399.00,
        quantidade_disponivel: 1,
        limite: 1,
        funcionarios: 3,
        descricao: '3 Funcionários Inclusos',
        image: logo_contador_essencial,
        entrega: ''
    },
    {
        modelo: 'CONTADOR-PRO',
        texto: 'Contador PRO',
        texto2: 'Pro',
        valor: 599.00,
        quantidade_disponivel: 1,
        limite: 1,
        funcionarios: 5,
        descricao: '5 Funcionários Inclusos',
        image: logo_contador_pro,
        entrega: ''
    }
];

export const ModalMaquinas = (props) => {
    const validar_maquininhas = (modelo_maquininha) => {
        var existe = false;
        props.maquininhas.forEach(e => {
            if(e.modelo === modelo_maquininha){
                existe = true
            }
        });

        return existe;
    }
    return (
        <Modal 
            isOpen={props.visible} 
            size="xl"
            style={{
                borderRadius: '1px'
            }}
        >
            <ModalHeader style={{ border: 'none' }} toggle={() => props.onClose()}>
                <h4>Escolha um plano</h4>
            </ModalHeader>
            <ModalBody>
                <Row style={{ justifyContent: 'center' }}>
                    {maquininhas_list.map(e => (
                        <Col md={3} style={{}}>
                            <Card className="plans-card" style={{ padding: 6, margin: 5}}>
                                <CardBody style={{padding: 10}}>
                                        <div style={{height: '55px'}}>
                                            <img src={e.image} height="45px"/>
                                        </div>
                                        <p style={{fontSize: '18px'}}>{e.texto2}</p>
                                        <small className="bold" style={{ fontSize: '16px' }}>A partir de</small>
                                        <div style={{display: 'flex', textDecoration: e.quantidade_disponivel == 0 ? 'line-through' : ''}}>
                                            <h3 className="bold" style={{margin: 0, color: '#174945', fontSize: '32px'}}>
                                                R$ {parseFloat(e.valor).toFixed(2).replace('.', ',')} 
                                            </h3>
                                        </div>
                                            <small className="bold" style={{ color: '#737373'}}>/mês</small>
                                        <center>
                                            {validar_maquininhas(e.modelo) == false ? 
                                                (e.quantidade_disponivel > 0 ?
                                                    <Button 
                                                        className="btn-color-c-default" 
                                                        onClick={() => props.adicionar_maquininha(e.modelo)}
                                                        style={{
                                                            fontSize: '15px',
                                                            padding: '10px',
                                                            width: '100%'
                                                        }}
                                                    >
                                                        &nbsp;&nbsp;Quero esse&nbsp;&nbsp;
                                                    </Button>
                                                    :
                                                    <p style={{ color: "red" }}><b>PRODUTO ESGOTADO</b></p>
                                                )

                                                :
                                                <p style={{ color: "#104743" }}><b>PLANO ESCOLHIDO</b></p>
                                            }
                                        </center>
                                        <br/>
                                </CardBody>
                            </Card>
                        </Col>
                    ))}
                    <Col md={6} style={{}}>
                        <Card className="plans-card" style={{ padding: 6, margin: 5}}>
                            <CardBody style={{padding: 10}}>
                                    <div style={{height: '55px'}}>
                                        <img src={contador_a1} height="45px"/>
                                    </div>
                                    <p style={{fontSize: '18px'}}>Certificado A1</p>
                                    <small className="bold" style={{ fontSize: '16px' }}>A partir de</small>
                                    <div style={{display: 'flex'}}>
                                        <h3 className="bold" style={{margin: 0, color: '#174945', fontSize: '32px'}}>
                                            R$ 99.00
                                        </h3>
                                    </div>
                                        <small className="bold" style={{ color: '#7C7B87' }}>*Certificado emitido pela empresa Soluti</small>
                                    <center>
                                        <Button 
                                            className="btn-color-c-default" 
                                            onClick={() => props.adicionar_maquininha("CERTIFICADO_A1")}
                                            style={{
                                                fontSize: '15px',
                                                padding: '10px',
                                                width: '100%'
                                            }}
                                        >
                                            &nbsp;&nbsp;Quero meu certificado&nbsp;&nbsp;
                                        </Button>
                                    </center>
                                    <br/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
}