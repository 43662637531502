import styled from "styled-components";
import { Button, Card, CardBody, FormFeedback, Col, Collapse, Form, FormGroup, Input, InputGroup, InputGroupAddon, Label, Row } from "reactstrap";
import axios from "axios";
import logo_aqpago from "../../assets/logo-aqpag.png";
import background from '../../assets/background.svg';
import { useState } from "react";
import Select from 'react-select';
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import ClipLoader from 'react-spinners/ClipLoader';
import { ENDPOINT } from "../../auth/endpoint";
import { toast } from "react-toastify";
import { useEffect } from "react";
import Carousel from 'nuka-carousel';
import { useLocation, useParams } from "react-router-dom";

const Div = styled.div`
min-height: 100vh;
height: 100%;
max-width: 100%;
background: #724185;
background-size: 35%;
background-image: url(${background});
margin: 0;
min-height: 100vh;
font-family: 'FiraSans Light';

.dropzone {
    background: #f5f5f5;
    border: 1px dashed #c2c2c2;
    border-radius: 3px;
    text-align: center;
    padding: 36px;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 600;
    font-family: 'FiraSans Light';
  }
  
  .dropzone:hover {
    background: rgb(144 106 159);
    border: 1px dashed #fff;
    color: #fff;
  }

  .dropzone:[drop-active=true] {
    background: rgb(144 106 159);
    border: 1px dashed #fff;
    color: #fff;
  }
  
@media screen and (max-width: 800px) {
    .name-step{
        display: none;
    }
} 
`;

const extensoes_list = [
    {
        value: '.com',
        label: '.com'
    },
    {
        value: '.com.br',
        label: '.com.br'
    },
    {
        value: '.net',
        label: '.net'
    },
    {
        value: '.net.br',
        label: '.net.br'
    }
]
const lojas = [
    {
        tema_id: '1',
        titulo: 'BABY KID',
        flag: 'BABY_KID',
        img_web: 'assets/page-ecommerce/BABY_KID_WEB.png',
        img_mob: 'assets/page-ecommerce/BABY_KID_MOB.png',
        link: 'https://lojademo.shopaqpago.com.br/baby_kid',
        compra: 'https://euquero.aqpago.com.br/shopaqpago?tema=baby_kid'
    },
    {
        tema_id: '2',
        titulo: 'beauty',
        flag: 'beauty',
        img_web: 'assets/page-ecommerce/beauty_WEB.png',
        img_mob: 'assets/page-ecommerce/beauty_MOB.png',
        link: 'https://lojademo.shopaqpago.com.br/beauty',
        compra: 'https://euquero.aqpago.com.br/shopaqpago?tema=beauty'

    },
    {
        tema_id: '3',
        titulo: 'bigmarket',
        flag: 'bigmarket',
        img_web: 'assets/page-ecommerce/bigmarket_web.png',
        img_mob: 'assets/page-ecommerce/bigmarket_mob.png',
        link: 'https://lojademo.shopaqpago.com.br/bigmarket',
        compra: 'https://euquero.aqpago.com.br/shopaqpago?tema=bigmarket'

    },
    {
        tema_id: '4',
        titulo: 'car',
        flag: 'car',
        img_web: 'assets/page-ecommerce/car_web.png',
        img_mob: 'assets/page-ecommerce/car_mob.png',
        link: 'https://lojademo.shopaqpago.com.br/car',
        compra: 'https://euquero.aqpago.com.br/shopaqpago?tema=car'

    },

    {
        tema_id: '5',
        titulo: 'drug pharmacy',
        flag: 'drug_pharmacy',
        img_web: 'assets/page-ecommerce/drug_pharmacy_web.png',
        img_mob: 'assets/page-ecommerce/drug_pharmacy_mob.png',
        link: 'https://lojademo.shopaqpago.com.br/drug_pharmacy',
        compra: 'https://euquero.aqpago.com.br/shopaqpago?tema=drug_pharmacy'

    },
    {
        tema_id: '6',
        titulo: 'ellyana',
        flag: 'ellyana',
        img_web: 'assets/page-ecommerce/ellyana_web.png',
        img_mob: 'assets/page-ecommerce/ellyana_mob.png',
        link: 'https://lojademo.shopaqpago.com.br/ellyana',
        compra: 'https://euquero.aqpago.com.br/shopaqpago?tema=ellyana'

    },
    {
        tema_id: '7',
        titulo: 'fashion',
        flag: 'fashion',
        img_web: 'assets/page-ecommerce/fashion_web.png',
        img_mob: 'assets/page-ecommerce/fashion_mob.png',
        link: 'https://lojademo.shopaqpago.com.br/fashion',
        compra: 'https://euquero.aqpago.com.br/shopaqpago?tema=fashion'

    },

    {
        tema_id: '8',
        titulo: 'fishing',
        flag: 'fishing',
        img_web: 'assets/page-ecommerce/fishing_web.png',
        img_mob: 'assets/page-ecommerce/fishing_mob.png',
        link: 'https://lojademo.shopaqpago.com.br/fishing',
        compra: 'https://euquero.aqpago.com.br/shopaqpago?tema=fishing'

    },
    {
        tema_id: '9',
        titulo: 'food drink',
        flag: 'food_drink',
        img_web: 'assets/page-ecommerce/food_drink_web.png',
        img_mob: 'assets/page-ecommerce/food_drink_mob.png',
        link: 'https://lojademo.shopaqpago.com.br/food_drink',
        compra: 'https://euquero.aqpago.com.br/shopaqpago?tema=food_drink'

    },
    {
        tema_id: '10',
        titulo: 'furniture',
        flag: 'furniture',
        img_web: 'assets/page-ecommerce/furniture_web.png',
        img_mob: 'assets/page-ecommerce/furniture_mob.png',
        link: 'https://lojademo.shopaqpago.com.br/furniture',
        compra: 'https://euquero.aqpago.com.br/shopaqpago?tema=furniture'

    },
    {
        tema_id: '11',
        titulo: 'garden',
        flag: 'garden',
        img_web: 'assets/page-ecommerce/garden_web.png',
        img_mob: 'assets/page-ecommerce/garden_mob.png',
        link: 'https://lojademo.shopaqpago.com.br/garden',
        compra: 'https://euquero.aqpago.com.br/shopaqpago?tema=garden'

    },
    {
        tema_id: '12',
        titulo: 'handbag',
        flag: 'handbag',
        img_web: 'assets/page-ecommerce/handbag_web.png',
        img_mob: 'assets/page-ecommerce/handbag_mob.png',
        link: 'https://lojademo.shopaqpago.com.br/handbag',
        compra: 'https://euquero.aqpago.com.br/shopaqpago?tema=handbag'

    },
    {
        tema_id: '13',
        titulo: 'hightech',
        flag: 'hightech',
        img_web: 'assets/page-ecommerce/hightech_web.png',
        img_mob: 'assets/page-ecommerce/hightech_mob.png',
        link: 'https://lojademo.shopaqpago.com.br/hightech',
        compra: 'https://euquero.aqpago.com.br/shopaqpago?tema=hightech'

    },
    {
        tema_id: '14',
        titulo: 'jewelry',
        flag: 'jewelry',
        img_web: 'assets/page-ecommerce/jewelry_web.png',
        img_mob: 'assets/page-ecommerce/jewelry_mob.png',
        link: 'https://lojademo.shopaqpago.com.br/jewelry',
        compra: 'https://euquero.aqpago.com.br/shopaqpago?tema=jewelry'

    },
    {
        tema_id: '15',
        titulo: 'mechanic tools',
        flag: 'mechanic_tool',
        img_web: 'assets/page-ecommerce/mechanic_tools_web.png',
        img_mob: 'assets/page-ecommerce/mechanic_tools_mob.png',
        link: 'https://lojademo.shopaqpago.com.br/mechanic_tools',
        compra: 'https://euquero.aqpago.com.br/shopaqpago?tema=mechanic_tool'

    },
    {
        tema_id: '16',
        titulo: 'medical',
        flag: 'medical',
        img_web: 'assets/page-ecommerce/medical_web.png',
        img_mob: 'assets/page-ecommerce/medical_mob.png',
        link: 'https://lojademo.shopaqpago.com.br/medical',
        compra: 'https://euquero.aqpago.com.br/shopaqpago?tema=medical'

    },
    {
        tema_id: '17',
        titulo: 'outdoor',
        flag: 'outdoor',
        img_web: 'assets/page-ecommerce/outdoor_web.png',
        img_mob: 'assets/page-ecommerce/outdoor_mob.png',
        link: 'https://lojademo.shopaqpago.com.br/outdoor',
        compra: 'https://euquero.aqpago.com.br/shopaqpago?tema=outdoor'

    },
    {
        tema_id: '18',
        titulo: 'sportswear',
        flag: 'sportswear',
        img_web: 'assets/page-ecommerce/sportswear_web.png',
        img_mob: 'assets/page-ecommerce/sportswear_mob.png',
        link: 'https://lojademo.shopaqpago.com.br/sportswear',
        compra: 'https://euquero.aqpago.com.br/shopaqpago?tema=sportswear'

    },
    {
        tema_id: '19',
        titulo: 'westylefashion',
        flag: 'westylefashion',
        img_web: 'assets/page-ecommerce/westylefashion_web.png',
        img_mob: 'assets/page-ecommerce/westylefashion_mob.png',
        link: 'https://lojademo.shopaqpago.com.br/westylefashion',
        compra: 'https://euquero.aqpago.com.br/shopaqpago?tema=westylefashion'

    },
    {
        tema_id: '20',
        titulo: 'westylejewelry',
        flag: 'westylejewelry',
        img_web: 'assets/page-ecommerce/westylejewelry_web.png',
        img_mob: 'assets/page-ecommerce/westylejewelry_mob.png',
        link: 'https://lojademo.shopaqpago.com.br/westylejewelry',
        compra: 'https://euquero.aqpago.com.br/shopaqpago?tema=westylejewelry'

    },

];

const getImage = (url) => {
    var image = require('../../' + url);
    return image.default;
};

const StepEcommerce = (props) => {
    const [selected_shop, setSelectShop] = useState(null);
    const [selected_shop_invalid, setSelectShopInvalid] = useState(false);
    const [show_templates, setTemplatesShow] = useState(false);
    const [exist_domain, setExistDomain] = useState(false);
    const [new_domain, setNewDomain] = useState(null);
    const [new_domain_invalid, setNewDomainInvalid] = useState(false);
    const [nome_loja, setNomeLoja] = useState('');
    const [nome_loja_invalid, setNomeLojaInvalid] = useState(false);
    const [domain, setDomain] = useState('');
    const [domain_invalid, setDomainInvalid] = useState(false);
    const [subdomain, setSubDomain] = useState('');
    const [subdomain_invalid, setSubDomainInvalid] = useState(false);
    const [extension_domain, setExtensionDomain] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error_message, setErrorMessage] = useState('');

    const { transacao_id } = useParams();
    const parametros_url = useLocation().search;

    useEffect(() => {
        const tema = new URLSearchParams(parametros_url).get('tema');

        if (tema !== null) {
            lojas.map(e => {
                if (tema == e.flag) {
                    setSelectShop(tema);
                    setTemplatesShow(true);
                }
            });
        }

        //getDataPayment(transacao_id);
    }, []);

    const handleChange = (event, callback) => {
        let value = event.target.value;
        callback(value);
    }

    const valid_fields = () => {
        var invalids = 0;

        if (nome_loja.length > 0) {
            setNomeLojaInvalid(false);
        }
        else {
            invalids++;
            setNomeLojaInvalid(true);
        }

        if (selected_shop !== null) {
            setSelectShopInvalid(false);
        }
        else {
            invalids++;
            setSelectShopInvalid(true);
        }

        if (exist_domain == true) {
            if (new_domain !== null) {
                setNewDomainInvalid(false);

                if (domain.length > 0) {
                    setDomainInvalid(false)
                }
                else {
                    invalids++;
                    setDomainInvalid(true)
                }
            }
            else {
                invalids++;
                setNewDomainInvalid(true);
            }
        }
        else {
            if (subdomain.length > 0) {
                setSubDomainInvalid(false)
            }
            else {
                invalids++;
                setSubDomainInvalid(true)
            }
        }

        if (invalids > 0) {
            return false;
        }

        return true;
    }

    const onRegister = () => {
        if (valid_fields() > 0) {
            var json_loja = {
                'nome_loja': nome_loja,
                'tema': selected_shop,
                'uso_dominio_existente': exist_domain
            };

            if(exist_domain == true){
                json_loja.dominio = domain;
                if(new_domain == true){
                    json_loja.tipo_dominio = extension_domain !== null ? extension_domain.value : '.com'
                }
            }
            else{
                json_loja.subdominio = subdomain;
            }
            cadastrarLoja(json_loja);
        }
    }
    const cadastrarLoja = (json_loja) => {
        props.setShopAqpago(json_loja);
        props.setStepCurrent(1);
    }

    return <div>
        <br/>
        <h4><img src={logo_aqpago} height="35px" /> REGISTRO DE E-COMMERCE</h4>
        <br/>
        <Form>
            <FormGroup>
                <Input
                    placeholder="Nome da loja"
                    name="nome_loja"
                    value={nome_loja}
                    invalid={nome_loja_invalid}
                    onChange={e => handleChange(e, setNomeLoja)}
                />
                <FormFeedback>Insira o nome da loja</FormFeedback>
            </FormGroup>
            <FormGroup hidden={exist_domain}>
                <InputGroup style={{ maxWidth: '500px' }}>
                    <InputGroupAddon addonType="prepend">www.</InputGroupAddon>
                    <Input
                        placeholder="seudominio"
                        name="subdomain"
                        value={subdomain}
                        invalid={subdomain_invalid}
                        onChange={e => handleChange(e, setSubDomain)} />
                    <InputGroupAddon addonType="append">
                        .shopaqpago.com.br
                    </InputGroupAddon>
                </InputGroup>
            </FormGroup>
            <FormGroup>
                <input
                    type="checkbox"
                    value={exist_domain}
                    onChange={() => setExistDomain(!exist_domain)}
                /> Desejo usar um domínio já existente
            </FormGroup>
            <FormGroup hidden={!exist_domain}>
                <Card style={{ marginBottom: 15, marginBottom: '15px', padding: 8, borderRadius: '18px', borderColor: '#7d358652' }}>
                    <CardBody style={{ padding: 8 }}>
                        <Label>Escolha seu dominio:</Label>
                        <br />
                        <input
                            name="domain"
                            type="radio"
                            checked={new_domain == true}
                            onChange={() => setNewDomain(true)}
                        /> Vou registrar um novo dominio
                        <br />
                        <input
                            name="domain"
                            type="radio"
                            onChange={() => setNewDomain(false)}
                            checked={new_domain == false}
                        /> Vou usar um dominio que já tenho
                        <Input hidden invalid={new_domain_invalid} />
                        <FormFeedback>Selecione o Tema</FormFeedback>


                        <InputGroup hidden={new_domain != false}>
                            <InputGroupAddon addonType="prepend">www.</InputGroupAddon>
                            <Input
                                placeholder="seudominio.com.br"
                                name="domain"
                                value={domain}
                                invalid={domain_invalid}
                                onChange={e => handleChange(e, setDomain)} />
                        </InputGroup>

                        <InputGroup hidden={new_domain != true}>
                            <InputGroupAddon addonType="prepend">www.</InputGroupAddon>
                            <Input
                                placeholder="seudominio"
                                name="domain"
                                value={domain}
                                invalid={domain_invalid}
                                onChange={e => handleChange(e, setDomain)} />
                            <InputGroupAddon addonType="append">
                                <div style={{ width: 200 }}>
                                    <Select
                                        placeholder=".com"
                                        value={extension_domain}
                                        onChange={value => setExtensionDomain(value)}
                                        options={extensoes_list}
                                    />
                                </div>
                            </InputGroupAddon>
                        </InputGroup>
                    </CardBody>
                </Card>
            </FormGroup>
            <FormGroup>
                {/* <Select
                                    placeholder="Selecione o tema"
                                /> */}
                <Button disabled={selected_shop != null} color="dark" outline onClick={() => setTemplatesShow(!show_templates)}>
                    <AiFillCaretDown hidden={show_templates} style={{ padding: 0 }} />
                    <AiFillCaretUp hidden={!show_templates} style={{ padding: 0 }} /> Selecione o Tema
                </Button>
                <Input hidden invalid={selected_shop_invalid} />
                <FormFeedback>Selecione o Tema</FormFeedback>
                <Collapse isOpen={show_templates}>
                    <br />
                    <Row hidden={selected_shop == null}>
                        <Col md={6}>
                            {lojas.map(e => {
                                if (selected_shop == e.flag) {
                                    return <Card style={{ margin: 5, boxShadow: selected_shop == e.flag ? '0px 0px 7px #724185' : '0px 0px 0px' }}>
                                        <CardBody>
                                            <Row>
                                                <Col md={9} className="d-none d-sm-block">
                                                    <img
                                                        style={{
                                                            width: '100%',
                                                            border: '2px solid rgb(1 0 8 / 24%)',
                                                            borderRadius: '15px'
                                                        }}
                                                        src={getImage(e.img_web)} />
                                                </Col>
                                                <Col md={3} className="d-none d-sm-block">
                                                    <center>
                                                        <img
                                                            style={{
                                                                maxWidth: '100%',
                                                                maxHeight: '280px'
                                                            }}
                                                            src={getImage(e.img_mob)} />
                                                    </center>
                                                </Col>
                                                <Col sm={12} className="d-sm-none">
                                                    <Carousel
                                                        defaultControlsConfig={{
                                                            nextButtonText: ">",
                                                            prevButtonText: "<",
                                                            pagingDotsStyle: {
                                                                fill: "red"
                                                            }
                                                        }}>
                                                        <center>
                                                            <img
                                                                style={{
                                                                    width: 'auto',
                                                                    height: '280px'
                                                                }}
                                                                src={getImage(e.img_mob)} />
                                                        </center>
                                                        <center>
                                                            <img
                                                                style={{
                                                                    maxWidth: '100%',
                                                                    border: '2px solid rgb(1 0 8 / 24%)',
                                                                    borderRadius: '15px'
                                                                }}
                                                                src={getImage(e.img_web)} />
                                                        </center>
                                                    </Carousel>
                                                </Col>
                                            </Row>
                                            <hr />
                                            <h5>{e.titulo}</h5>
                                            <hr />
                                            <div>
                                                <Button
                                                    onClick={() => setSelectShop(null)}
                                                    color="danger"
                                                    style={{
                                                        margin: 5
                                                    }}
                                                    outline>
                                                    Voltar
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        window.open(e.link, '_blank')
                                                    }}
                                                    style={{
                                                        margin: 5
                                                    }}
                                                    color="info"
                                                    outline>Visualizar</Button>
                                            </div>
                                        </CardBody>
                                    </Card>
                                }
                            })}
                        </Col>
                    </Row>
                    <Row hidden={selected_shop !== null} style={{ maxHeight: '400px', position: 'relative', overflow: 'auto' }}>
                        {lojas.map(e => (
                            <Col md={6}>
                                <Card style={{ margin: 5, boxShadow: selected_shop == e.flag ? '0px 0px 7px #724185' : '0px 0px 0px' }}>
                                    <CardBody>
                                        <Row>
                                            <Col md={9} className="d-none d-sm-block">
                                                <img
                                                    style={{
                                                        width: '100%',
                                                        border: '2px solid rgb(1 0 8 / 24%)',
                                                        borderRadius: '15px'
                                                    }}
                                                    src={getImage(e.img_web)} />
                                            </Col>
                                            <Col md={3} className="d-none d-sm-block">
                                                <center>
                                                    <img
                                                        style={{
                                                            maxWidth: '100%',
                                                            maxHeight: '280px'
                                                        }}
                                                        src={getImage(e.img_mob)} />
                                                </center>
                                            </Col>
                                            <Col sm={12} className="d-sm-none">
                                                <Carousel
                                                    defaultControlsConfig={{
                                                        nextButtonText: ">",
                                                        prevButtonText: "<",
                                                        pagingDotsStyle: {
                                                            fill: "#724185"
                                                        }
                                                    }}>
                                                    <center>
                                                        <img
                                                            style={{
                                                                width: 'auto',
                                                                height: '280px'
                                                            }}
                                                            src={getImage(e.img_mob)} />
                                                    </center>
                                                    <center>
                                                        <img
                                                            style={{
                                                                maxWidth: '100%',
                                                                border: '2px solid rgb(1 0 8 / 24%)',
                                                                borderRadius: '15px'
                                                            }}
                                                            src={getImage(e.img_web)} />
                                                    </center>
                                                </Carousel>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <h5>{e.titulo}</h5>
                                        <hr />
                                        <div>
                                            <Button
                                                onClick={() => setSelectShop(e.flag)}
                                                color="success"
                                                style={{
                                                    margin: 5
                                                }}
                                                outline>
                                                Selecionar
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    window.open(e.link, '_blank')
                                                }}
                                                style={{
                                                    margin: 5
                                                }}
                                                color="info"
                                                outline>Visualizar</Button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Collapse>
            </FormGroup>
        </Form>
        <Button
            className="btn-next"
            color="success"
            style={{ float: 'right' }}
            onClick={() => onRegister()}
        >
            Salvar
        </Button>
    </div>
}
export default StepEcommerce;