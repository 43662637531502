import { Input } from "reactstrap"
import styled from "styled-components";

const Div = styled.div `
    .form-control:focus{
        border-color: rgb(79 176 117 / 25%);
        box-shadow: 3px 2px 4px -1px rgb(79 176 117 / 25%);
    }
    .form-control.is-invalid:focus{
        border-color: rgb(220 53 69 / 25%);
        box-shadow: 3px 2px 4px -1px rgb(220 53 69 / 50%);
    }
    width: 100%;
`;
export const InputText = (props) => {
    return (
        <Div>
            <Input 
                style={{
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    borderBottomRightRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                }}
                {...props}/>
            </Div>
    )
}