import styled from "styled-components";

const Button = styled.button`
    font-size: 1em;
    margin: 1em;
    padding: 0.25em 1em;
    border: 1px solid #38B76E;
    border-radius: .25rem;
    color: #38B76E;
    font-size: 16px;
    font-weight: 400;
    padding: 7px;
    background-color: #fff;

    &:hover{
        background-color: #38B76E;
        color: #fff;
    }
`

export const ButtonOutline = (props) => {
    return(
        <Button
            type="button"
            {...props}
        />
    )

}