import styled from "styled-components";

const ButtonD = styled.button`
    font-size: 1em;
    margin-left: 1em;
    padding: 0.25em 1em;
    border: 1px solid #004723;
    border-radius: 1.25rem;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    padding: 7px;
    background-color: #004723;

    &:disabled {
        border: 1px solid #004723;
        background-color: #004723;
    }
`

export const ButtonDefault = (props) => {
    return(
        <ButtonD
            type="button"
            {...props}
        />
    )

}