import axios from "axios"
import { cpf } from "cpf-cnpj-validator"
import { useEffect, useState } from "react"
import { MdArrowForward, MdMail, MdMailOutline, MdPhone, MdVerifiedUser } from "react-icons/md"
import NumberFormat from "react-number-format"
import Select from "react-select"
import { toast } from "react-toastify"
import { Button, Card, Col, Form, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import { ENDPOINT } from "../../auth/endpoint"
import { InputMasked } from "../InputText/InputMasked"
import { InputText } from "../InputText/InputText"
import ModalReturn from "../ReturnsApiModals/ModalReturn"
import asset_user from "../../assets/user_aqcontabil.svg";
import asset_date from "../../assets/date_aqcontabil.svg";
import asset_date_aqcontabil_birth from "../../assets/date_aqcontabil_birth.svg";
import { RiPhoneLine } from "react-icons/ri"

var CurrencyInput = require('react-currency-masked-input');

const conta_tipo = [
    {value: 'checking', label: 'Conta Poupança'},
    {value: 'savings', label: 'Conta Corrente'}
];


const FormByEmpresarial = (props) => (
    <Form>
        <h6><b>Nome da Empresa: </b> {props.user.nomeEmpresarial}</h6>
        <h6><b>Data de Constituição: </b> {props.user.dataAbertura}</h6>
        <h6><b>Natureza Juridica: </b>{props.user.naturezaJuridica.descricao}</h6>
        {props.user.email_validate === 'validated' ? 
            <>
                <div  className="bold" style={{ color: '#174945', fontSize: '11px' }}>
                    <FormGroup>
                        <h6>&nbsp;<b><MdMailOutline style={{width: '22px', height: '22px'}}/></b> {props.form.email}</h6>
                    </FormGroup>
                    <FormGroup>
                        <h6>&nbsp;<b><RiPhoneLine style={{width: '22px', height: '22px'}}/></b> {props.form.telefone}</h6>
                    </FormGroup>
                    <FormGroup>
                        <h6><b>Sócios: </b> </h6>
                        <Select
                            placeholder="Sócios"
                            options={props.form.socios}
                            value={props.form.socio}
                            onChange={(value) => props.handleSelectChange('socio', value)}
                        />
                        <Input hidden invalid={props.form.socio_invalid} />
                        <FormFeedback>Campo Obrigatório</FormFeedback>
                    </FormGroup>
                </div>
                
            </>
            :
            props.user.email_validate === 'sending' ?
            <>
                <div  className="bold" style={{ color: '#174945', fontSize: '11px' }}>
                    <FormGroup>
                        <h6>&nbsp;<b><MdMailOutline style={{width: '22px', height: '22px'}}/></b> {props.form.email}</h6>
                        <h6>&nbsp;<b><RiPhoneLine style={{width: '22px', height: '22px'}}/></b> {props.form.telefone}</h6>
                    </FormGroup>
                    <FormGroup>
                        <Button color="success" onClick={() => props.sendToken()}>Reenviar Token</Button>&nbsp;
                        <Button color="info" onClick={() => props.updateRequestNotRefresh('email_validate', 'pending')}>Revisar Dados</Button>
                    </FormGroup>
                </div>
                <FormGroup>
                    <h6><b>Token enviado para E-mail:</b> </h6>
                    <InputMasked
                        mask="999999"
                        name="token_email"
                        value={props.form.token_email}
                        invalid={props.form.token_email_invalid}
                        onChange={props.handleChange}
                    />
                </FormGroup>
                <FormGroup>
                    <h6><b>Token enviado para SMS:</b> </h6>
                    <InputMasked
                        mask="999999"
                        name="token_sms"
                        value={props.form.token_sms}
                        invalid={props.form.token_sms_invalid}
                        onChange={props.handleChange}
                    />
                </FormGroup>
            </>
            : 
            <>
                <FormGroup>
                    <h6><b>E-mail Pessoal: </b> </h6>
                    <InputText 
                        name="email"
                        value={props.form.email}
                        placeholder="Digite o e-mail"
                        invalid={props.form.email_invalid}
                        onChange={props.handleChange}
                    />
                    <Input hidden invalid={props.form.email_invalid} />
                    <FormFeedback>Campo Obrigatório</FormFeedback>
                </FormGroup>
                {/* {props.user.telefones ?
                    <h6><b>Telefone: </b> {'('+props.user.telefones[0].ddd + ') '+props.user.telefones[0].numero}</h6>
                : */}
                <FormGroup>
                    <h6><b>Telefone:</b> </h6>
                    {/* <InputText placeholder="Telefone"/> */}
                    <InputMasked
                        mask="(99) 99999-9999"
                        name="telefone"
                        value={props.form.telefone}
                        placeholder="Digite o telefone"
                        invalid={props.form.telefone_invalid}
                        onChange={props.handleChange}/>
                    </FormGroup>
                {/* } */}
            </>
        }
    </Form>
)

const FormByIndividual = (props) => (
    <>
        <Form>
            <div  className="bold" style={{ color: '#174945', fontSize: '11px' }}>
                <h6> &nbsp;<b><img src={asset_user} /> </b>&nbsp; {props.user.nome}</h6>
                <h6> &nbsp;<b><img src={asset_date} /> </b> &nbsp;
                    <NumberFormat 
                        displayType={'text'}  
                        format="###.###.###-##" 
                        value={props.user.ni} /></h6>
                <h6> &nbsp;<b><img src={asset_date_aqcontabil_birth} /> </b> &nbsp;
                    <NumberFormat 
                        displayType={'text'}  
                        format="##/##/####" 
                        value={props.user.nascimento} /></h6>
                <hr/>
            </div>
        {props.user.email_validate === 'validated' ? 
            <>
                {/* <div  className="bold" style={{ color: '#174945', fontSize: '11px' }}>
                    <FormGroup>
                        <h6>&nbsp;<b><MdMailOutline style={{width: '22px', height: '22px'}}/></b> {props.form.email}</h6>
                        <h6>&nbsp;<b><RiPhoneLine style={{width: '22px', height: '22px'}}/></b> {props.form.telefone}</h6>
                    </FormGroup>
                </div> */}
                <div hidden={props.vendas_step != 1}>
                    <FormGroup>
                        <h6><b>Agora informe qual o objetivo social de seu CNPJ, quais as atividades que você vai realizar:</b> </h6>
                        <InputText 
                            name="mcc_info"
                            value={props.form.mcc_info}
                            placeholder="Atividade de vendas"
                            invalid={props.form.mcc_info_invalid}
                            onChange={props.handleChange}/>
                        <FormFeedback>Campo obrigatório</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <h6><b>Informe qual o nome fantasia que você irá usar na abertura de seu cnpj:</b> </h6>
                        <InputText 
                            name="nome_fantasia_pretendido"
                            value={props.form.nome_fantasia_pretendido}
                            placeholder="Nome Fantasia"
                            invalid={props.form.nome_fantasia_pretendido_invalid}
                            onChange={props.handleChange}/>
                        <FormFeedback>Campo obrigatório</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <h6><b>Qual será o capital social que vai abrir/registrar seu CNPJ:</b> </h6>
                        <CurrencyInput
                            inputMode="tel"
                            className="form-control" 
                            currency="BRL"
                            name="capital_social"
                            //value={valor}
                            defaultValue={'0.00'}
                            onChange={(event) => props.handleChange(event)}/>
                        <Input hidden invalid={props.form.capital_social_invalid}/>
                        <FormFeedback>Campo obrigatório</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <h6><b>Qual tipo/porte de empresa deseja abrir? </b></h6>
                        <Input 
                            type="select" 
                            placeholder="Porte da Empresa"
                            name="porte_empresa"
                            invalid={props.form.porte_empresa_invalid}
                            onChange={(event) => props.handleChange(event)}>
                            <option value="">Porte da Empresa</option>
                            <option value="MEI">MEI</option>
                            <option value="EIRELI">EIRELI</option>
                            <option value="Empresario Individual">Empresario Individual</option>
                            <option value="Sociedade Empresária Limitada">Sociedade Empresária Limitada</option>
                            <option value="Sociedade Simples">Sociedade Simples</option>
                        </Input>
                    </FormGroup>
                </div>
                <div hidden={props.vendas_step != 2}>
                    <b>Informe o endereço que será registrado abertura de seu CNPJ:</b>
                    <br/>
                    <br/>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <h6><b>Inscrição IPTU: </b> </h6>
                                <InputText 
                                    name="iptu"
                                    value={props.form.iptu}
                                    placeholder="Digite a inscrição do IPTU do endereço"
                                    invalid={props.form.iptu_invalid}
                                    onChange={props.handleChange}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <h6><b>CEP: </b> </h6>
                                <InputMasked 
                                    name="cep"
                                    mask="99999-999"
                                    value={props.form.cep}
                                    placeholder="Digite o cep"
                                    invalid={props.form.cep_invalid}
                                    onChange={props.handleChange}/>
                            </FormGroup>
                            <FormGroup>
                                <h6><b>Logradouro: </b> </h6>
                                <InputText 
                                    name="logradouro"
                                    value={props.form.logradouro}
                                    placeholder="Digite o nome da Rua"
                                    invalid={props.form.logradouro_invalid}
                                    onChange={props.handleChange}/>
                            </FormGroup>
                            <FormGroup>
                                <h6><b>Número: </b> </h6>
                                <InputText 
                                    name="numero"
                                    value={props.form.numero}
                                    placeholder="Digite o numero"
                                    invalid={props.form.numero_invalid}
                                    onChange={props.handleChange}/>
                            </FormGroup>
                            <FormGroup>
                                <h6><b>Complemento: </b> </h6>
                                <InputText 
                                    name="complemento"
                                    value={props.form.complemento}
                                    placeholder="Digite o complemento"
                                    invalid={props.form.complemento_invalid}
                                    onChange={props.handleChange}/>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <h6><b>Bairro: </b> </h6>
                                <InputText 
                                    name="bairro"
                                    value={props.form.bairro}
                                    placeholder="Digite o bairro"
                                    invalid={props.form.bairro_invalid}
                                    onChange={props.handleChange}/>
                            </FormGroup>
                            <FormGroup>
                                <h6><b>Cidade: </b> </h6>
                                <InputText 
                                    name="cidade"
                                    value={props.form.cidade}
                                    placeholder="Digite o cidade"
                                    invalid={props.form.cidade_invalid}
                                    onChange={props.handleChange}/>
                            </FormGroup>
                            <FormGroup>
                                <h6><b>Estado (UF): </b> </h6>
                                <InputText 
                                    name="uf"
                                    maxLength={2}
                                    value={props.form.uf}
                                    placeholder="Digite o uf"
                                    invalid={props.form.uf_invalid}
                                    onChange={props.handleChange}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>A empresa terá mais algum sócio além de você? &nbsp;&nbsp;
                            <input type="radio" name="socio_checked_add" value={true} onChange={props.handleChange}/> Sim &nbsp;
                            <input type="radio" name="socio_checked_add" value={false} onChange={props.handleChange}/> Não</p>
                        </Col>
                    </Row>
                </div>
                <div hidden={props.form.socio_checked_add != true}>
                    <hr/>
                    <Row>
                        <Col>
                            <FormGroup>
                                <h6><b>CPF: </b> </h6>
                                <InputMasked 
                                    name="cpf_add"
                                    mask="999.999.999-99"
                                    value={props.form.cpf_add}
                                    placeholder="Digite o cpf do socio"
                                    invalid={props.form.cpf_add_invalid}
                                    onChange={props.handleChange}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <FormGroup>
                                <h6><b>CEP: </b> </h6>
                                <InputMasked 
                                    name="cep_add"
                                    mask="99999-999"
                                    value={props.form.cep_add}
                                    placeholder="Digite o cep"
                                    invalid={props.form.cep_add_invalid}
                                    onChange={props.handleChange}/>
                            </FormGroup>
                            <FormGroup>
                                <h6><b>Número: </b> </h6>
                                <InputText 
                                    name="numero_add"
                                    value={props.form.numero_add}
                                    placeholder="Digite o numero"
                                    invalid={props.form.numero_add_invalid}
                                    onChange={props.handleChange}/>
                            </FormGroup>
                            <FormGroup>
                                <h6><b>Cidade: </b> </h6>
                                <InputText 
                                    name="cidade_add"
                                    value={props.form.cidade_add}
                                    placeholder="Digite o cidade"
                                    invalid={props.form.cidade_add_invalid}
                                    onChange={props.handleChange}/>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <h6><b>Logradouro: </b> </h6>
                                <InputText 
                                    name="logradouro_add"
                                    value={props.form.logradouro_add}
                                    placeholder="Digite o nome da Rua"
                                    invalid={props.form.logradouro_add_invalid}
                                    onChange={props.handleChange}/>
                            </FormGroup>
                            <FormGroup>
                                <h6><b>Complemento: </b> </h6>
                                <InputText 
                                    name="complemento_add"
                                    value={props.form.complemento_add}
                                    placeholder="Digite o complemento"
                                    invalid={props.form.complemento_add_invalid}
                                    onChange={props.handleChange}/>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <h6><b>Bairro: </b> </h6>
                                <InputText 
                                    name="bairro_add"
                                    value={props.form.bairro_add}
                                    placeholder="Digite o bairro"
                                    invalid={props.form.bairro_add_invalid}
                                    onChange={props.handleChange}/>
                            </FormGroup>
                            <FormGroup>
                                <h6><b>Estado (UF): </b> </h6>
                                <InputText 
                                    name="uf_add"
                                    maxLength={2}
                                    value={props.form.uf_add}
                                    placeholder="Digite o uf"
                                    invalid={props.form.uf_add_invalid}
                                    onChange={props.handleChange}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button 
                                color="primary"
                                onClick={() => props.addSocio()}
                            >Adicionar Sócio</Button>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>
                            <ul>
                                {props.form.socios_adds.map((e, index) => {
                                    return <li>{e.cpf} - <Button color="danger" style={{padding: 2, fontSize: '12px'}} onClick={() => props.removeSocio(index)}>Remover</Button></li>
                                })}
                            </ul>
                        </Col>
                    </Row>
                    <Input hidden invalid={props.form.socio_checked_add_invalid} />
                    <FormFeedback>Nenhum sócio adicionado</FormFeedback>
                </div>
            </>
            :
            props.user.email_validate === 'sending' ?
            <>
                {/* <FormGroup>
                    <h6><b>E-mail:</b> {props.form.email}</h6>
                    <h6><b>Telefone:</b> {props.form.telefone}</h6>
                </FormGroup> */}
                
                <div  className="bold" style={{ color: '#174945', fontSize: '11px' }}>
                    <FormGroup>
                        <h6>&nbsp;<b><MdMailOutline style={{width: '22px', height: '22px'}}/></b> {props.form.email}</h6>
                        <InputMasked
                            mask="999999"
                            name="token_email"
                            placeholder="Token enviado para seu E-mail"
                            value={props.form.token_email}
                            invalid={props.form.token_email_invalid}
                            onChange={props.handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <h6>&nbsp;<b><RiPhoneLine style={{width: '22px', height: '22px'}}/></b> {props.form.telefone}</h6>
                        <InputMasked
                            mask="999999"
                            name="token_sms"
                            placeholder="Token enviado para seu Telefone"
                            value={props.form.token_sms}
                            invalid={props.form.token_sms_invalid}
                            onChange={props.handleChange}
                        />
                    </FormGroup>
                </div>
                <FormGroup>
                    <Button color="success" outline style={{ padding: 3, border: 'none'}} onClick={() => props.sendToken()}>Reenviar Token</Button>&nbsp;
                    <Button color="info" outline style={{ padding: 3, border: 'none'}} onClick={() => props.updateRequestNotRefresh('email_validate', 'pending')}>Revisar Dados</Button>
                </FormGroup>
            </>
            :
            <>
                <h6>Para continuar, precisamos de alguns dados</h6>
                <FormGroup style={{ display: 'flex' }}>
                    <MdMailOutline style={{  width: '26px', height: '26px', marginTop: '6px'  }}/>
                    <InputText 
                        name="email"
                        value={props.form.email}
                        placeholder="E-mail"
                        invalid={props.form.email_invalid}
                        onChange={props.handleChange}/>
                </FormGroup>
                <FormGroup style={{ display: 'flex' }}>
                    <RiPhoneLine  style={{  width: '26px', height: '26px', marginTop: '6px'  }}/>
                    <InputMasked
                        mask="(99) 99999-9999"
                        name="telefone"
                        value={props.form.telefone}
                        placeholder="Telefone"
                        invalid={props.form.telefone_invalid}
                        onChange={props.handleChange}/>
                </FormGroup>
            </>
        }
        </Form>
    </>
)

export const ModalStore = (props) => {
    const [forms, setForms] = useState({
        mcc: null,
        mcc_invalid: false,
        mcc_info: '',
        mcc_info_invalid: false,
        nome_fantasia_pretendido: '',
        nome_fantasia_pretendido_invalid: false,
        capital_social: 0,
        capital_social_invalid: false,
        porte_empresa: '',
        porte_empresa_invalid: false,
        plano: null,
        plano_invalid: false,
        email: '',
        email_invalid: false,
        telefone: '',
        telefone_invalid: false, 
        iptu: '',
        iptu_invalid: false,
        cep: '',
        cep_invalid: false,
        logradouro: '',
        logradouro_invalid: false,
        numero: '',
        numero_invalid: false,
        complemento: '',
        complemento_invalid: false,
        bairro: '',
        bairro_invalid: false,
        cidade: '',
        cidade_invalid: false,
        uf: '',
        uf_invalid: false,

        cpf_add: '',
        cpf_add_invalid: false,
        cep_add: '',
        cep_add_invalid: false,
        logradouro_add: '',
        logradouro_add_invalid: false,
        numero_add: '',
        numero_add_invalid: false,
        complemento_add: '',
        complemento_add_invalid: false,
        bairro_add: '',
        bairro_add_invalid: false,
        cidade_add: '',
        cidade_add_invalid: false,
        uf_add: '',
        uf_add_invalid: false,

        socio_checked_add: null,
        socio_checked_add_invalid: false,
        socios_adds: [],
        holder_name: '',
        holder_name_invalid: false,
        routing_number: '',
        routing_number_invalid: false,
        account_number: '',
        account_number_invalid: false,
        type: null,
        type_invalid: false,
        socios: [],
        socio: null,
        socio_invalid: false,
        email_validate: 'pending',
        bank_code: null,
        bank_code_invalid: false,
        token_sms: '',
        token_sms_invalid: false,
        token_email: '',
        token_email_invalid: false
    });
    //const [planos_list, setPlanoList] = useState([]);
    const [banks_list, setBancosList] = useState([]);
    const [vendas_step, setVendaStep] = useState(1);
    const [mcc_list, setMccList] = useState([]);
    const [request_api, setApi] = useState({
        request: null,
        loading: false,
        error_message: '',
        success_message: ''
    })


    const onRegister = () => {
        if(validateFields() === 0){
            if(props.user.email_validate === 'pending'){
                sendToken();
            }
            else if(props.user.email_validate === 'sending'){
                validateToken();
            }
            else{
                if(props.user.tipo == 'titular'){
                    if(vendas_step == 1){
                        setVendaStep(2);
                    }
                    else if(vendas_step == 2){
                        storeUser();
                    }
                }
                else{
                    storeUser(); 
                }
            }
        }
    }
    const validateFields = () => {
        var invalids = 0;
        if(props.user.email_validate === 'pending'){
            if(props.user.tipo === 'titular'){
                if(forms.email.length > 0){
                    setForms(prevState => ({...prevState, email_invalid: false }));
                }
                else{
                    invalids++;
                    setForms(prevState => ({...prevState, email_invalid: true }));
                }

                if(forms.telefone.replace(/([^\d])+/gim, '').length > 9){
                    setForms(prevState => ({...prevState, telefone_invalid: false }));
                }
                else{
                    invalids++;
                    setForms(prevState => ({...prevState, telefone_invalid: true }));
                }
            }
            else{
                if(forms.email.length > 0){
                    setForms(prevState => ({...prevState, email_invalid: false }));
                }
                else{
                    invalids++;
                    setForms(prevState => ({...prevState, email_invalid: true }));
                }

                //if(!props.user.telefones){
                    if(forms.telefone.length > 0){
                        setForms(prevState => ({...prevState, telefone_invalid: false }));
                    }
                    else{
                        invalids++;
                        setForms(prevState => ({...prevState, telefone_invalid: true }));
                    }
                //}

            }

        }
        else if(props.user.email_validate === 'sending'){
            if(forms.token_sms.replace(/([^\d])+/gim, '').length > 5){
                setForms(prevState => ({...prevState, token_sms_invalid: false }));
            }
            else{
                invalids++;
                setForms(prevState => ({...prevState, token_sms_invalid: true }));
            }
            
            if(forms.token_email.replace(/([^\d])+/gim, '').length > 5){
                setForms(prevState => ({...prevState, token_email_invalid: false }));
            }
            else{
                invalids++;
                setForms(prevState => ({...prevState, token_email_invalid: true }));
            }

        }
        else{
            if(props.user.tipo === 'titular'){
                if(vendas_step == 1){
                    if(forms.mcc_info !== ''){
                        setForms(prevState => ({...prevState, mcc_info_invalid: false }));
                    }
                    else{
                        invalids++;
                        setForms(prevState => ({...prevState, mcc_info_invalid: true }));
                    }

                    if(forms.nome_fantasia_pretendido.length > 0){
                        setForms(prevState => ({...prevState, nome_fantasia_pretendido_invalid: false }));
                    }
                    else{
                        invalids++;
                        setForms(prevState => ({...prevState, nome_fantasia_pretendido_invalid: true }));
                    }

                    if(parseFloat(forms.capital_social, 10) > 0){
                        setForms(prevState => ({...prevState, capital_social_invalid: false }));
                    }
                    else{
                        invalids++;
                        setForms(prevState => ({...prevState, capital_social_invalid: true }));
                    }

                    if(forms.porte_empresa !== ''){
                        setForms(prevState => ({...prevState, porte_empresa_invalid: false }));
                    }
                    else{
                        invalids++;
                        setForms(prevState => ({...prevState, porte_empresa_invalid: true }));
                    }
                    
                    
                }
                else if(vendas_step == 2){

                    if(forms.email.length > 0){
                        setForms(prevState => ({...prevState, email_invalid: false }));
                    }
                    else{
                        invalids++;
                        setForms(prevState => ({...prevState, email_invalid: true }));
                    }
        
        
                    if(forms.cep.replace(/([^\d])+/gim, '').length > 7){
                        setForms(prevState => ({...prevState, cep_invalid: false }));
                    }
                    else{
                        invalids++;
                        setForms(prevState => ({...prevState, cep_invalid: true }));
                    }
        
        
                    if(forms.telefone.replace(/([^\d])+/gim, '').length > 9){
                        setForms(prevState => ({...prevState, telefone_invalid: false }));
                    }
                    else{
                        invalids++;
                        setForms(prevState => ({...prevState, telefone_invalid: true }));
                    }
            
            
                    if(forms.logradouro.length > 0){
                        setForms(prevState => ({...prevState, logradouro_invalid: false }));
                    }
                    else{
                        invalids++;
                        setForms(prevState => ({...prevState, logradouro_invalid: true }));
                    }
            
                    if(forms.numero.length > 0){
                        setForms(prevState => ({...prevState, numero_invalid: false }));
                    }
                    else{
                        invalids++;
                        setForms(prevState => ({...prevState, numero_invalid: true }));
                    }
            
                    if(forms.bairro.length > 0){
                        setForms(prevState => ({...prevState, bairro_invalid: false }));
                    }
                    else{
                        invalids++;
                        setForms(prevState => ({...prevState, bairro_invalid: true }));
                    }
            
                    if(forms.cidade.length > 0){
                        setForms(prevState => ({...prevState, cidade_invalid: false }));
                    }
                    else{
                        invalids++;
                        setForms(prevState => ({...prevState, cidade_invalid: true }));
                    }
            
                    if(forms.uf.length > 0){
                        setForms(prevState => ({...prevState, uf_invalid: false }));
                    }
                    else{
                        invalids++;
                        setForms(prevState => ({...prevState, uf_invalid: true }));
                    }
                    
                    /* if(forms.plano !== null){
                        setForms(prevState => ({...prevState, plano_invalid: false }));
                    }
                    else{
                        invalids++;
                        setForms(prevState => ({...prevState, plano_invalid: true }));
                    } */
                    if(forms.socio_checked_add == true){
                        if(forms.socios_adds.length > 0){
                            setForms(prevState => ({...prevState, socio_checked_add_invalid: false }));
                        }
                        else{
                            invalids++;
                            setForms(prevState => ({...prevState, socio_checked_add_invalid: true }));
                        }
                    }
                }
    
            }
            else {
                
                /* if(forms.plano !== null){
                    setForms(prevState => ({...prevState, plano_invalid: false }));
                }
                else{
                    invalids++;
                    setForms(prevState => ({...prevState, plano_invalid: true }));
                } */
                
                if(forms.email.length > 0){
                    setForms(prevState => ({...prevState, email_invalid: false }));
                }
                else{
                    invalids++;
                    setForms(prevState => ({...prevState, email_invalid: true }));
                }
                
                /* if(forms.holder_name.length > 0){
                    setForms(prevState => ({...prevState, holder_name_invalid: false }));
                }
                else{
                    invalids++;
                    setForms(prevState => ({...prevState, holder_name_invalid: true }));
                } */
            }
        }

        return invalids;
    }
    const validSocios = () => {
        var invalids = 0;

        if(cpf.isValid(forms.cpf_add.replace(/([^\d])+/gim, '')) == true){
            setForms(prevState => ({...prevState, cpf_add_invalid: false }));
        }
        else{
            invalids++;
            setForms(prevState => ({...prevState, cpf_add_invalid: true }));
        }

        if(forms.cep_add.replace(/([^\d])+/gim, '').length > 7){
            setForms(prevState => ({...prevState, cep_add_invalid: false }));
        }
        else{
            invalids++;
            setForms(prevState => ({...prevState, cep_add_invalid: true }));
        }


        if(forms.logradouro_add.length > 0){
            setForms(prevState => ({...prevState, logradouro_add_invalid: false }));
        }
        else{
            invalids++;
            setForms(prevState => ({...prevState, logradouro_add_invalid: true }));
        }

        if(forms.numero_add.length > 0){
            setForms(prevState => ({...prevState, numero_add_invalid: false }));
        }
        else{
            invalids++;
            setForms(prevState => ({...prevState, numero_add_invalid: true }));
        }

        if(forms.bairro_add.length > 0){
            setForms(prevState => ({...prevState, bairro_add_invalid: false }));
        }
        else{
            invalids++;
            setForms(prevState => ({...prevState, bairro_add_invalid: true }));
        }

        if(forms.cidade_add.length > 0){
            setForms(prevState => ({...prevState, cidade_add_invalid: false }));
        }
        else{
            invalids++;
            setForms(prevState => ({...prevState, cidade_add_invalid: true }));
        }

        if(forms.uf_add.length > 0){
            setForms(prevState => ({...prevState, uf_add_invalid: false }));
        }
        else{
            invalids++;
            setForms(prevState => ({...prevState, uf_add_invalid: true }));
        }

        forms.socios_adds.forEach(e => {
            if(e.cpf == forms.cpf_add){
                invalids++;
                setForms(prevState => ({...prevState, cpf_add_invalid: true }));
            }
        })

        return invalids;
    }
    const getCep = (value) => {
        var url = ENDPOINT+'search_cep_17e6-11ec-9621-0242ac130002?cep='+value;
        axios.get(url)

        .then((response) => {
            var data = response.data;
            if(data.success === true){
                setForms(prevState => ({...prevState, logradouro: data.data.end }));
                setForms(prevState => ({...prevState, complemento: data.data.complemento2 }));
                setForms(prevState => ({...prevState, uf: data.data.uf }));
                setForms(prevState => ({...prevState, bairro: data.data.bairro }));
                setForms(prevState => ({...prevState, cidade: data.data.cidade }));
            }
            return data;
        })
    
    }

    const getCepSocio = (value) => {
        var url = ENDPOINT+'search_cep_17e6-11ec-9621-0242ac130002?cep='+value;
        axios.get(url)

        .then((response) => {
            var data = response.data;
            if(data.success === true){
                setForms(prevState => ({...prevState, logradouro_add: data.data.end }));
                setForms(prevState => ({...prevState, complemento_add: data.data.complemento2 }));
                setForms(prevState => ({...prevState, uf_add: data.data.uf }));
                setForms(prevState => ({...prevState, bairro_add: data.data.bairro }));
                setForms(prevState => ({...prevState, cidade_add: data.data.cidade }));
            }
            return data;
        })
    
    }

    const handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if(name == 'socio_checked_add'){
            var isTrueSet = (value === 'true');
            setForms(prevState => ({...prevState, [name]: isTrueSet }));
        }
        else{
            setForms(prevState => ({...prevState, [name]: value }));
        }

        if(name === 'cep'){
            if(value.replace(/([^\d])+/gim, '').length > 7){
                getCep(value)
            }
        }
        else if(name === 'cep_add'){
            if(value.replace(/([^\d])+/gim, '').length > 7){
                getCepSocio(value)
            }
        }
        else if(name == 'capital_social'){
            var valor_current = value;
            if (valor_current.length > 1) {
                valor_current = valor_current.replaceAll('.', '');
                var digits = valor_current.substr(valor_current.length - 2);
                valor_current = valor_current.slice(0, -2);
                valor_current = valor_current + '.' + digits;
            }

            var valido = /^[\d,.?!]+$/.test(valor_current);
            if (valido === true || valor_current === '') {
                setForms(prevState => ({...prevState, [name]: valor_current }));
            }
        }
    }

    const handleSelectChange = (name, value) => {
        setForms(prevState => ({...prevState, [name]: value }));
    }

    const onClose = () => {
        props.onClose(request_api.request);

        setForms({
            mcc: null,
            mcc_invalid: false,
            mcc_info: '',
            mcc_info_invalid: false,
            nome_fantasia_pretendido: '',
            nome_fantasia_pretendido_invalid: false,
            capital_social: 0,
            capital_social_invalid: false,
            porte_empresa: '',
            porte_empresa_invalid: false,
            plano: '',
            plano_invalid: false,
            email: '',
            email_invalid: false,
            telefone: '',
            telefone_invalid: false, 
            iptu: '',
            iptu_invalid: false,
            cep: '',
            cep_invalid: false,
            logradouro: '',
            logradouro_invalid: false,
            numero: '',
            numero_invalid: false,
            complemento: '',
            complemento_invalid: false,
            bairro: '',
            bairro_invalid: false,
            cidade: '',
            cidade_invalid: false,
            uf: '',
            uf_invalid: false,

            cpf_add: '',
            cpf_add_invalid: false,
            cep_add: '',
            cep_add_invalid: false,
            logradouro_add: '',
            logradouro_add_invalid: false,
            numero_add: '',
            numero_add_invalid: false,
            complemento_add: '',
            complemento_add_invalid: false,
            bairro_add: '',
            bairro_add_invalid: false,
            cidade_add: '',
            cidade_add_invalid: false,
            uf_add: '',
            uf_add_invalid: false,

            socio_checked_add: null,
            socio_checked_add_invalid: false,
            socios_adds: [],
            holder_name: '',
            holder_name_invalid: false,
            routing_number: '',
            routing_number_invalid: false,
            account_number: '',
            account_number_invalid: false,
            type: null,
            type_invalid: false,
            socio: null,
            socio_invalid: false,
            socios: [],
            bank_code: null,
            bank_code_invalid: false,
            token_sms: '',
            token_sms_invalid: false,
            token_email: '',
            token_email_invalid: false
        });
        setApi({
            request: null,
            loading: false,
            error_message: '',
            success_message: ''
        })

    } 
    
    useEffect(() => {
        getMCC();
    }, []);

    useEffect(() => {
        if(props.user.tipo === `empresarial`){
            var socios = [];
            var socio = null;

            if(props.user.socios){
                props.user.socios.forEach(e => {
                    socios.push({
                        value: e.nome,
                        label: e.nome
                    })
                });
    
                socio = {
                    value: props.user.socios[0].nome,
                    label: props.user.socios[0].nome
                }
    
                setForms(prevState => ({...prevState, socio }));
                setForms(prevState => ({...prevState, socios }));
            }
            else if(props.user.nomeEmpresarial){
                socios.push({
                    value: props.user.nomeEmpresarial,
                    label: props.user.nomeEmpresarial
                })
    
                socio = {
                    value: props.user.nomeEmpresarial,
                    label: props.user.nomeEmpresarial
                }
                setForms(prevState => ({...prevState, socio }));
                setForms(prevState => ({...prevState, socios }));
            }

        }
        if(props.user.email_validate === 'sending'){
            setForms(prevState => ({...prevState, email: props.user.email }));
            setForms(prevState => ({...prevState, telefone: props.user.telefone }));
        }
        else if(props.user.email_validate === 'validated'){
            setForms(prevState => ({...prevState, email: props.user.email }));
            setForms(prevState => ({...prevState, telefone: props.user.telefone }));
        }
    }, [props.user]);

    
    const getPlanos = () => {
        var url = ENDPOINT+"bancos_list";
        axios.get(url)
        .then((response) => {
            var data = response.data;
            var bank_list = []
            data.data.forEach(e => {
                bank_list.push({
                    value: e.Número_Código,
                    label: e.Nome_Reduzido
                })
            });

            setBancosList(bank_list);
        })
    }

    const getMCC = () => {
        var url = ENDPOINT+"mcc_list";
        axios.get(url)
        .then((response) => {
            var data = response.data;
            var mcc_list = []
            data.data.forEach(e => {
                mcc_list.push({
                    value: e.id,
                    label: e.category + ' - ' + e.description
                })
            });

            setMccList(mcc_list);
        })
    
    }

    const removeSocio = (index) => {
        var socios = forms.socios_adds;
        socios.splice(index, 1);
        setForms(prevState => ({...prevState, socios_adds: socios }));
    }
    const addSocio = () => {
        if(validSocios() == 0){
            var value = {
                cpf: forms.cpf_add,
                logradouro: forms.logradouro_add,
                cep: forms.cep_add,
                bairro: forms.bairro_add,
                numero: forms.numero_add,
                complemento: forms.complemento_add,
                uf: forms.uf_add,
                cidade: forms.cidade_add
            }
    
            var socios = forms.socios_adds;
            socios.push(value);
    
            setForms(prevState => ({...prevState, socios_adds: socios }));
            setForms(prevState => ({
                ...prevState, 
                cpf_add: '',
                logradouro_add: '',
                cep_add: '',
                bairro_add: '',
                numero_add: '',
                complemento_add: '',
                uf_add: '',
                cidade_add: ''
            }));
        }
    }

    const onError = () => {
        setApi(prevState => ({...prevState, loading: false }));
        setApi(prevState => ({...prevState, error_message: '' }));
        setApi(prevState => ({...prevState, request: null }));
    }

    const sendToken = () => {
        setApi(prevState => ({...prevState, loading: true }));

        var url = ENDPOINT+"contabilidade/enviar_token";

        if(props.url_user){
            url += '?'+props.url_user();
        }

        var formData = new FormData();
        formData.append('document', props.user.ni.replace(/([^\d])+/gim, ''));
        formData.append('email', forms.email);
        formData.append('telefone', forms.telefone.replace(/([^\d])+/gim, ''));
        
        axios.post(url, formData)
        .then((response) => {
            setApi(prevState => ({...prevState, loading: false }));
            var resp = response.data;
            props.updateRequest('id', resp.id);
            props.updateRequest('email_validate', 'sending');
            setForms(prevState => ({...prevState, email_validate: 'sending' }));
        })
        .catch((error) => {
            console.log(error)
            var response_erro = error.response.data;
            toast.error(response_erro.errors);
            setApi(prevState => ({...prevState, loading: false }));
        });

    }

    const validateToken = () => {
        setApi(prevState => ({...prevState, loading: true }));
        var url = ENDPOINT+"contabilidade/validar_token/"+props.user.id;

        if(props.url_user){
            url += '?'+props.url_user();
        }

        var formData = new FormData();
        formData.append('id', props.user.id);
        formData.append('token_sms', forms.token_sms);
        formData.append('token_email', forms.token_email);

        axios.post(url, formData)
        .then((response) => {
            var resp = response.data;
            setApi(prevState => ({...prevState, loading: false }));
            props.updateRequest('email_validate', 'validated');
            setForms(prevState => ({...prevState, email_validate: 'validated' }));
        })
        .catch((error) => {
            var response_erro = error.response.data;
            toast.error(response_erro.errors);
            setApi(prevState => ({...prevState, loading: false }));
        });

    }

    const storeUser = () => {
        setApi(prevState => ({...prevState, loading: true }));
        var url = ENDPOINT+"contabilidade/store_user";

        if(props.url_user){
            url += '?'+props.url_user();
        }

        var formData = new FormData();
        formData.append('document', props.user.ni.replace(/([^\d])+/gim, ''));
        if(props.user.tipo === `titular`){
            formData.append('holder_name', props.user.nome);
            formData.append('telefone', forms.telefone.replace(/([^\d])+/gim, ''));
            formData.append('cep', forms.cep);
            formData.append('rua', forms.logradouro);
            formData.append('numero', forms.numero);
            formData.append('complemento', forms.complemento);
            formData.append('bairro', forms.bairro);
            formData.append('cidade', forms.cidade);
            formData.append('uf', forms.uf);
            formData.append('iptu', forms.iptu);
            formData.append('nome_fantasia', forms.nome_fantasia_pretendido);
            formData.append('capital_social', forms.capital_social);
            formData.append('porte_empresa', forms.porte_empresa);
            formData.append('pais', 'Brasil');
        }
        else{
            formData.append('holder_name', forms.socio.value);
            formData.append('socio_name',forms.socio.value);
            formData.append('telefone', forms.telefone.replace(/([^\d])+/gim, ''));
        }
        formData.append('email', forms.email);

        if(forms.mcc_info){
            formData.append('mcc_info', forms.mcc_info);
        }
        if(forms.mcc){
            formData.append('mcc', forms.mcc.value);
        }

        if(forms.socio_checked_add == true){
            formData.append("socios_cnpj_checked", true);

            forms.socios_adds.forEach((e, index) => {
                e.cpf = e.cpf.replace(/([^\d])+/gim, '');
                formData.append("socios_cnpj["+index+"]", JSON.stringify(e));
            });
        }

        formData.append('feito_por_pedido', 1);

        axios.post(url, formData)
        .then((response) => {
            var data = response.data;
            onData(data);
            return data;
        })
        .catch((error) => {
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                onData(obj);
                return obj;
            }
            var data = error.response.data;
            onData(data);
            return data;
        });
    }

    const onData = (data) => {
        if(data.success === true){
            
            setApi(prevState => ({...prevState, request: data.data }));
            setApi(prevState => ({...prevState, success_message: data.message }));
        }
        else{
            setApi(prevState => ({...prevState, error_message: data.errors }));
        }
    }

    return (
        <div hidden={!props.visible} size="lg">
            <div hidden={request_api.loading}>
                {props.user.tipo == 'empresarial' ?
                    <FormByEmpresarial 
                        user={props.user}
                        form={forms}
                        vendas_step={vendas_step}
                        banks_list={banks_list}
                        mcc_list={mcc_list}
                        updateRequestNotRefresh={props.updateRequestNotRefresh}
                        sendToken={sendToken}
                        handleChange={handleChange}
                        handleSelectChange={handleSelectChange}
                    />
                    :
                    <FormByIndividual
                        vendas_step={vendas_step}
                        user={props.user}
                        form={forms}
                        banks_list={banks_list}
                        mcc_list={mcc_list}
                        updateRequestNotRefresh={props.updateRequestNotRefresh}
                        sendToken={sendToken}
                        addSocio={addSocio}
                        removeSocio={removeSocio}
                        handleChange={handleChange}
                        handleSelectChange={handleSelectChange}
                    />
                }
            </div>
            <div 
                hidden={!request_api.loading}>
                    <center>
                        <ModalReturn
                            error_message={request_api.error_message}
                            success_message={request_api.success_message}
                            loading={request_api.loading}
                            onClose={onClose}
                            onError={onError}
                        />
                    </center>

            </div>
            <div hidden={request_api.loading}>
                <Button className="btn-color-default" onClick={() => onRegister()}>
                    Continuar <MdArrowForward />
                </Button>
            </div>
        </div>
    )
}