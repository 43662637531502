import axios from "axios";
import React, { useState } from "react"
import { toast } from "react-toastify";
import { Button, FormFeedback, Input } from "reactstrap";
import { ENDPOINT } from "../../auth/endpoint";
import Basic from "../../DropFile";
import ClipLoader from 'react-spinners/ClipLoader'
import { MdBusiness, MdCameraFront, MdHome, MdPrint } from "react-icons/md";
import { HiIdentification } from "react-icons/hi";

export const StepAll = (props) => {
    const [fileFrente, setFileFrente] = useState([]);
    const [fileFrente64, setFileFrente64] = useState([]);
    const [fileFrenteInvalid, setFileFrenteInvalid] = useState(false);
    const [fileVerso, setFileVerso] = useState([]);
    const [fileVerso64, setFileVerso64] = useState([]);
    const [fileVersoInvalid, setFileVersoInvalid] = useState(false);
    const [fileSelfie, setFileSelfie] = useState([]);
    const [fileSelfie64, setFileSelfie64] = useState([]);
    const [fileSelfieInvalid, setFileSelfieInvalid] = useState(false);
    const [fileComprovante, setFileComprovante] = useState([]);
    const [fileComprovante64, setFileComprovante64] = useState([]);
    const [fileComprovanteInvalid, setFileComprovanteInvalid] = useState(false);
    const [fileMei, setFileMei] = useState([]);
    const [fileMei64, setFileMei64] = useState([]);
    const [fileMeiInvalid, setFileMeiInvalid] = useState(false);
    const [progress, setProgress] = useState(0)
    
    const searchFiles = (tipo_documento) => {
        var exists = 0;
        props.documentos_enviados.forEach(element => {
            if(element.descricao === tipo_documento){
                exists++;
            }
        });

        if(exists > 0){
            return false
        }

        return true;
    }
    const onDropFrente = (e) => {
        setFileFrente(e);

        var file = e[0];
        toBase64Frente(file);
    }

    const toBase64Frente = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(
            setFileFrente64(reader.result)
        );
        reader.onerror = error => reject(error);
    });

    const onDropVerso = (e) => {
        setFileVerso(e);

        var file = e[0];
        toBase64Verso(file);
    }

    const toBase64Verso = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(
            setFileVerso64(reader.result)
        );
        reader.onerror = error => reject(error);
    });

    const onDropSelfie = (e) => {
        setFileSelfie(e);

        var file = e[0];
        toBase64Selfie(file);
    }

    const toBase64Selfie = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(
            setFileSelfie64(reader.result)
        );
        reader.onerror = error => reject(error);
    });

    const onDropComprovante = (e) => {
        setFileComprovante(e);
        console.log(e)
        var file = e[0];
        toBase64Comprovante(file);
    }

    const toBase64Comprovante = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(
            setFileComprovante64(reader.result)
        );
        reader.onerror = error => reject(error);
    });

    const onDropMei = (e) => {
        setFileMei(e);

        var file = e[0];
        toBase64Mei(file);
    }

    const toBase64Mei = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(
            setFileMei64(reader.result)
        );
        reader.onerror = error => reject(error);
    });



    const validFields = () => {
        var invalids = 0;
        if(searchFiles('Documento de Identidade (RG/CNH) - Frente') === true){
            if(fileFrente.length === 0){
                invalids++;
                setFileFrenteInvalid(true);
            }
            else{
                setFileFrenteInvalid(false);
            }
        }
        else{
            setFileFrenteInvalid(false);
        }

        if(searchFiles('Documento de Identidade (RG/CNH) - Verso') === true){
            if(fileVerso.length === 0){
                invalids++;
                setFileVersoInvalid(true);
            }
            else{
                setFileVersoInvalid(false);
            }
        }
        else{
            setFileVersoInvalid(false);
        }

        if(invalids > 0){
            return false;
        }
        return true;
    }
    
    const enviarDocumentos = async(value, file) =>{
        var url = ENDPOINT + 'enviar-documentos';

        if(props.url_user){
            url += '?'+props.url_user();
        }
        
        var index = 0;

        var formData = new FormData();
        formData.append('id', props.id);

        if(searchFiles(value) === true){
            var form1 = {
                descricao: value,
                categoria: 'identificacao'
            }
            
            formData.append(`arquivos[${index}]`, JSON.stringify(form1));
            formData.append(`anexo[${index}]`, file);
            index++;
        }

        return await axios.post(url, formData);
    }

    const valid_docs = (value) => {
        var valido = false;
        props.documentos_enviados.forEach(e => {
            if(e.descricao == value){
                valido = true;
            }
        });

        return valido;
    }

    const sendDocuments = async () =>{
        if(validFields() === true){
            var documentos = [
                { value: 'Documento de Identidade (RG/CNH) - Frente', file: fileFrente64 },
                { value: 'Documento de Identidade (RG/CNH) - Verso', file: fileVerso64 },
                { value: 'Selfie com você segurando seu documento de Identidade', file: fileSelfie64 },
                { value: 'Comprovante de Endereço', file: fileComprovante64 },
            ];
            
            if(props.documento.length > 11){
                documentos.push(
                    { value: 'Certificado Registro MEI, Contrato social ou Registro da Empresa', file: fileFrente64 },
                );
            }

            documentos.forEach((p, i) => {
                if(searchFiles(p.value) === false){
                    documentos.splice(i, 1)
                }
            })

            var refresh_toast = (progress, total) => {
                var progress_id = (100 * progress) / total;

                toast.update('envio01', {
                    toastId: 'envio01',
                    type: toast.TYPE.DARK,
                    render: <><ClipLoader color="#ffffff" size={20}/> {parseInt(progress_id)}% Os arquivos já estão sendo enviados, por favor aguarde</>,
                    toastId: 'envio01',
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

            }

            var invalids = 0;
            var documentos_enviados = [];
            var documentos_recusados = '';

            var sendAllDocs = async () => {
                documentos.forEach(async (p, i) => {
                    if(valid_docs(p.value) == false){
                        var form1 = {
                            descricao: p.value,
                            categoria: 'identificacao'
                        }
    
                        var formData = new FormData();
                        
                        formData.append(`arquivos[0]`, JSON.stringify(form1));
                        formData.append(`anexo[0]`, p.file);
                        
                        enviarDocumentos(p.value, p.file)
                            .then(function () {
                                //props.addDocuments(p.value);
                                finalize();
                            })
                            .catch(function (error) { 
                                invalids++;

                                var response = error.response.data;
                                toast.error(response.errors); 
                                finalize(p.value, true);
                            });
                    }
                    else{
                        finalize();
                    }
                });

                return true;
            };

            var finalize = (value = null, exception = false) => {
                refresh_toast(documentos_enviados.length + 1, documentos.length);

                if(exception = false){
                    documentos_enviados.push(value);
                }
                else{
                    documentos_enviados.push('Exception');
                    documentos_recusados += value + '; ';
                }

                if(documentos_enviados.length >= documentos.length){
                    if(documentos_enviados.indexOf('Exception') > 0){
                        toast.update('envio01', {
                            toastId: 'envio01',
                            type: toast.TYPE.ERROR,
                            render: 'Ocorreu um erro ao enviar os seguintes documentos: '+documentos_recusados,
                            autoClose: 5000,
                            progress: undefined,
                        });
                    }
                    else{
                        toast.update('envio01', {
                            toastId: 'envio01',
                            type: toast.TYPE.SUCCESS,
                            render: 'Arquivos enviados com exito',
                            autoClose: 3000,
                            progress: undefined
                        }); 

                        if(invalids == 0){
                            if(props.integracao == true){
                                props.stepCurrent(4);
                            }
                            else{
                                props.stepCurrent(2);
                            }
                            //props.stepCurrent(2);
                        }
                    }
                }
            }

            if(documentos.length > 0){
                toast.dark(<><ClipLoader color="#ffffff" size={20}/> {progress}% Os arquivos já estão sendo enviados, por favor aguarde</>, {
                    toastId: 'envio01',
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
    
                sendAllDocs();
            }
            else{
                if(props.integracao == true){
                    props.stepCurrent(4);
                }
                else{
                    props.stepCurrent(2);
                }
            }
            /* var funqueue = [];
            funqueue.push(sendAllDocs);
            //funqueue.push(finalize);
            (funqueue.shift())(finalize); */
        }
    }

    return (
        <>
            {props.documentacao == true ?
                <>
                    <h4>
                        <MdPrint style={{padding: 0}}/> DOCUMENTAÇÃO 
                    </h4>
                    <br/>
                </>
                :<></>}
            <h5>Selecione seus documentos de Identidade</h5>
            <br/>
            <h6><HiIdentification /> Documento de Identidade (RG/CNH) - Frente</h6>
            <hr/>
            <Basic
                //getUser={getUser}
                id={props.id}
                documentos={fileFrente}
                documentos_enviados={props.documentos_enviados}
                onDrop={onDropFrente}
                tipo_documento={"Documento de Identidade (RG/CNH) - Frente"}
            />
            <Input hidden invalid={fileFrenteInvalid} />
            <FormFeedback>Selecione o arquivo referente ao Documento de Identidade (RG/CNH) - Frente</FormFeedback>
            <br/>
            <h6><HiIdentification /> Documento de Identidade (RG/CNH) - Verso</h6>
            <hr/>
            <Basic
                //getUser={getUser}
                id={props.id}
                documentos_enviados={props.documentos_enviados}
                documentos={fileVerso}
                onDrop={onDropVerso}
                tipo_documento={"Documento de Identidade (RG/CNH) - Verso"}
            />
            <Input hidden invalid={fileVersoInvalid} />
            <FormFeedback>Selecione o arquivo referente ao Documento de Identidade (RG/CNH) - Verso</FormFeedback>
            <br/>
            <h6><MdCameraFront /> Selfie com você segurando seu documento de Identidade</h6>
            <hr/>
            <Basic
                //getUser={getUser}
                id={props.id}
                documentos_enviados={props.documentos_enviados}
                documentos={fileSelfie}
                onDrop={onDropSelfie}
                tipo_documento={"Selfie com você segurando seu documento de Identidade"}
            />
            <Input hidden invalid={fileSelfieInvalid} />
            <FormFeedback>Selecione o arquivo referente ao Selfie com você segurando seu documento de Identidade</FormFeedback>
            <br/>
            <h6><MdHome /> Comprovante de Endereço</h6>
            <hr/>
            <Basic
                //getUser={getUser}
                id={props.id}
                documentos_enviados={props.documentos_enviados}
                documentos={fileComprovante}
                onDrop={onDropComprovante}
                tipo_documento={"Comprovante de Endereço"}
            />
            <Input hidden invalid={fileComprovanteInvalid} />
            <FormFeedback>Selecione o arquivo referente ao Comprovante de Endereço</FormFeedback>
            <hr/>
            {props.documento.length > 11 ?
                <>
                    <h6><MdBusiness /> Certificado Registro MEI, Contrato social ou Registro da Empresa</h6>
                    <hr/>
                    <Basic
                        //getUser={getUser}
                        id={props.id}
                        documentos_enviados={props.documentos_enviados}
                        documentos={fileMei}
                        onDrop={onDropMei}
                        tipo_documento={"Certificado Registro MEI, Contrato social ou Registro da Empresa"}
                    />
                    <Input hidden invalid={fileMeiInvalid} />
                    <FormFeedback>Selecione o arquivo referente ao Certificado Registro MEI, Contrato social ou Registro da Empresa</FormFeedback>
                    <hr/>
                    </>
            : null}
            <div style={{display: 'flex', float: 'right'}}>
                {/* <Button color="danger" style={{margin: '2px'}} onClick={() => props.stepCurrent(false)}>Voltar</Button> */}
                <Button color="success" style={{margin: '2px'}} onClick={() => sendDocuments()}>Seguinte</Button>
            </div>
        </>
    )
}