import axios from "axios";
import { cnpj, cpf } from "cpf-cnpj-validator";
import { useState } from "react";
import { toast } from "react-toastify";
import { Button, FormFeedback, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ENDPOINT } from "../../auth/endpoint";
import { InputMasked } from "../InputText/InputMasked";
import { InputText } from "../InputText/InputText";
import ModalReturn from "../ReturnsApiModals/ModalReturn";

var CryptoJS = require("crypto-js");

const ModalLoja = (props) => {
    const [step, setStep] = useState(0);
    const [cpf_cnpj, setCpfCnpj] = useState('');
    const [documento_cpf, setDocumentoCpf] = useState('');
    const [documento_cpf_invalid, setDocumentoCpfInvalid] = useState(false);
    const [nome_completo, setNomeCompleto] = useState('');
    const [nome_completo_invalid, setNomeCompletoInvalid] = useState(false);
    const [data_nascimento, setDataNascimento] = useState('');
    const [data_nascimento_invalid, setDataNascimentoInvalid] = useState(false);

    const [token, setToken] = useState('');
    const [user_id, setUserId] = useState(0);
    const [isCnpj, setIsCnpj] = useState(false);
    const [isCpfCnpjInvalid, setIsCpfCnpjInvalid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [id_transacao, setIdTransacao] = useState('');
    const [error_message, setErrorMessage] = useState('');
    const [success_message, setSuccessMessage] = useState('');
    const [loading_modal, setLoadingModal] = useState(false);

    const gerar_token = () => {
        setLoading(true);

        var url = ENDPOINT + 'lojas/consultar_cliente/' + cpf_cnpj.replace(/([^\d])+/gim, '') +'?'+getUrlUser();
        
        axios.get(url)
        .then((response) => {
            var data = response.data;
            setUserId(data.data);
            setLoading(false);
            setStep(1);
        })
        .catch((error) => {
            var response = error.response.data;
            setLoading(false);
            toast.error(response.errors);
        });
    }
    
    const getUrlUser = () => {
        var url_user = 'cpf_cnpj='+cpf_cnpj.replace(/([^\d])+/gim, '')+'&name_or_business='+nome_completo+
                                '&birthdate='+data_nascimento.replace(/([^\d])+/gim, '');
        
        if(isCnpj === true){
            url_user += '&cpf_business='+documento_cpf.replace(/([^\d])+/gim, '')
        }
        if(nome_completo.length > 9){
            function dataAtualFormatada(){
                var currentdate = new Date(); 
                let weekday = new Date().toLocaleString('en-us', {weekday:'long'});
                var datetime = currentdate.getDate().toString().padStart(2, '0') + "/"
                            + (currentdate.getMonth()+1).toString().padStart(2, '0')  + "/" 
                            + currentdate.getFullYear() + " - "  
                            + currentdate.getHours().toString().padStart(2, '0') + ":"  
                            + currentdate.getMinutes().toString().padStart(2, '0') + ":" 
                            + currentdate.getSeconds().toString().padStart(2, '0') + '#' + weekday;

                return datetime;
            }
            function dataAtual(){
                var currentdate = new Date(); 
                var datetime = currentdate.getDate().toString().padStart(2, '0') + "/"
                            + (currentdate.getMonth()+1).toString().padStart(2, '0')  + "/" 
                            + currentdate.getFullYear() + " - "  
                            + currentdate.getHours().toString().padStart(2, '0') + ":"  
                            + currentdate.getMinutes().toString().padStart(2, '0') + ":" 
                            + currentdate.getSeconds().toString().padStart(2, '0');

                return datetime;
            }

            var tokenHash = CryptoJS.MD5(
                'aqbank_key@'
                +nome_completo.replace(/\s/g, '').substr(0, 9).toUpperCase()+''
                +nome_completo.replace(/\s/g, '').substr(-8).toUpperCase()
                +'#'+cpf_cnpj.replace(/([^\d])+/gim, '').substr(0, 3)
                +'#'+cpf_cnpj.replace(/([^\d])+/gim, '')
                +'#'+data_nascimento.replace(/([^\d])+/gim, '')
                +'#d'+dataAtualFormatada());

            url_user += '&aqbank_key='+tokenHash.toString()+'&date_atual='+dataAtual();
        }

        return url_user;
    }

    const confirmar_token = () => {
        setLoadingModal(true);

        var url = ENDPOINT + 'lojas/confirmar_token/' + user_id +'?'+getUrlUser();
        
        var formData = new FormData();
        formData.append('token', token);

        axios.post(url, formData)
        .then((response) => {
            var data = response.data;
            setIdTransacao(data.data.id_transacao);
            setStep(1);
            setSuccessMessage(data.message)
        })
        .catch((error) => {
            var response = error.response.data;
            setErrorMessage(response.errors);
        });
    }

    const handleChange = (event, callback) => {
        let value = event.target.value;

        callback(value);
    }

    const onRedirect = () => {
        window.location.href = 'shop-buy-aqpago/'+id_transacao;
    } 
    const onError = () => {
        setLoadingModal(false);
        setErrorMessage('');
        setSuccessMessage('');
        setToken('');
    }

    const validCpfCnpj = () => {
        var invalids = 0;
        if(isCnpj == false){
            if(cpf.isValid(cpf_cnpj.replace(/([^\d])+/gim, '')) == true){
                setIsCpfCnpjInvalid(false);
            }
            else{
                invalids++;
                setIsCpfCnpjInvalid(true);
            }
        }
        else{
            if(cnpj.isValid(cpf_cnpj.replace(/([^\d])+/gim, '')) == true){
                setIsCpfCnpjInvalid(false);
            }
            else{
                invalids++;
                setIsCpfCnpjInvalid(true);
            }

            if(cpf.isValid(documento_cpf.replace(/([^\d])+/gim, '')) == true){
                setDocumentoCpfInvalid(false);
            }
            else{
                invalids++;
                setDocumentoCpfInvalid(true);
            }
        }

        if(nome_completo.length > 0){
            setNomeCompletoInvalid(false);
        }
        else{
            invalids++;
            setNomeCompletoInvalid(true);
        }

        if(data_nascimento.replace(/([^\d])+/gim, '').length > 7){
            setDataNascimentoInvalid(false);
        }
        else{
            invalids++;
            setDataNascimentoInvalid(true);
        }

        if(invalids > 0){
            return false;
        }

        return true;
    }

    const onNext = () => {
        if(step == 0){
            if(validCpfCnpj() == true){
                gerar_token();
            }
        }
        else{
            confirmar_token();
        }
    }

    const onCloseStore = () => {
        setStep(0);
        setCpfCnpj('');
        setToken('');
        setUserId(0);
        setIsCnpj(false);
        setIsCpfCnpjInvalid(false);
        setLoading(false);
        setIdTransacao('');
        setErrorMessage('');
        setSuccessMessage('');
        setLoadingModal(false);

        props.onClose();
    }

    return (
        <Modal isOpen={props.visible}>
            <ModalHeader>Registrar Loja</ModalHeader>
            <ModalBody hidden={loading_modal}>
                {step == 0 ?
                    <>
                        <p>Para começar, insira o CPF/CNPJ no qual você realizou a compra de seu E-commerce</p>
                        <InputMasked
                            mask={isCnpj === true ? "99.999.999/9999-99" :"999.999.999-99"}
                            name="cpf_cnpj"
                            placeholder={isCnpj === true ? "CNPJ" : "CPF"}
                            value={cpf_cnpj}
                            disabled={loading}
                            invalid={isCpfCnpjInvalid}
                            onChange={(e) => handleChange(e, setCpfCnpj)}
                        />
                        <FormFeedback>CPF/CNPJ Inválido</FormFeedback>
                        <FormGroup>
                            &nbsp;&nbsp;
                            <input type="radio" checked={isCnpj === false} onChange={() => setIsCnpj(false)}/>
                            &nbsp;CPF &nbsp;
                            &nbsp;
                            <input type="radio" checked={isCnpj} onChange={() => setIsCnpj(true)}/>
                            &nbsp;CNPJ
                        </FormGroup>
                        {isCnpj === true ?
                            <>
                                <InputMasked
                                    mask={"999.999.999-99"}
                                    value={documento_cpf}
                                    placeholder={'CPF'}
                                    invalid={documento_cpf_invalid}
                                    onChange={(e) => handleChange(e, setDocumentoCpf)}
                                />
                                <FormFeedback>CPF Inválido</FormFeedback>
                            </>
                        : null}
                        <>
                            <InputMasked
                                mask="99/99/9999"
                                placeholder="Data de nascimento"
                                value={data_nascimento}
                                invalid={data_nascimento_invalid}
                                onChange={(e) => handleChange(e, setDataNascimento)}
                            />
                            <FormFeedback>Data de Nascimento Inválida</FormFeedback>
                        </>
                        <>
                            <InputText
                                placeholder="Nome completo"
                                value={nome_completo}
                                invalid={nome_completo_invalid}
                                onChange={(e) => handleChange(e, setNomeCompleto)}
                            />
                            <FormFeedback>Nome complemento Inválido</FormFeedback>
                        </>
                    </>
                :
                    <>
                        <p>Compra de E-commerce encontrada. Por favor, digite o token enviado para seu e-mail</p>
                        <InputMasked
                            mask="999999"
                            name="token"
                            placeholder="Token de e-mail"
                            value={token}
                            onChange={(e) => handleChange(e, setToken)}
                        />
                    </>
                }
            </ModalBody>
            <div 
                className="modal-body"
                hidden={!loading_modal}
                style={{
                    minHeight: '114px',
                    display: 'flex',
                    alignItems: 'center',
                    alignSelf: 'center',
                    paddingBottom: '47px'
                }}>
                <ModalReturn
                    error_message={error_message}
                    success_message={success_message}
                    loading={loading_modal}
                    onClose={onRedirect}
                    onError={onError}
                />

            </div>
            <ModalFooter hidden={loading_modal}>
                <Button color="danger" onClick={() => onCloseStore()}>Cancelar</Button>
                <Button color="success" onClick={() => onNext()}>Avançar</Button>
            </ModalFooter>
        </Modal>
    )
}

export default ModalLoja;