/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import Principal from './Principal';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";

export default () => {
  return (
    <BrowserRouter>
        <Switch>
            <Route path="/" component={Principal} exact/>
            <Route path="/:modelo_maquininha" component={Principal} />
        </Switch>
        <ToastContainer hideProgressBar/>
    </BrowserRouter>
  );
};
