import React, { useState } from "react"
import { MdAttachMoney, MdCheck, MdCreditCard } from "react-icons/md"
import { AiOutlineBarcode } from "react-icons/ai"
import { Button, ButtonGroup, Card, CardBody, CardColumns, CardDeck, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row, Toast } from "reactstrap"
import { LayouPagamento } from "./LayouPagamento"
import { InputText } from "../InputText/InputText"
import { InputMasked } from "../InputText/InputMasked"
import { ENDPOINT } from "../../auth/endpoint"
import { toast } from "react-toastify"
import axios from "axios"
import FadeLoader from "react-spinners/FadeLoader";
import { cpf, cnpj } from 'cpf-cnpj-validator';

var CryptoJS = require("crypto-js");

function insertParam(key, value) {
    key = encodeURIComponent(key);
    value = encodeURIComponent(value);

    const url = new URL(window.location.href);
    url.searchParams.set(key, value);
    window.history.replaceState(null, null, url);
}
export const StepPagamento = (props) => {
    const [tipo_pagamento, setTipoPagamento] = useState('credit');
    const [credit_card, setCreditCard] = useState('');
    const [credit_card_invalid, setCreditCardInvalid] = useState(false);
    const [fullname, setFullName] = useState('');
    const [fullname_invalid, setFullNameInvalid] = useState(false);
    const [validade, setValidade] = useState('');
    const [validade_invalid, setValidadeInvalid] = useState(false);
    const [seguranca, setSeguranca] = useState('');
    const [seguranca_invalid, setSegurancaInvalid] = useState(false);
    const [cpf_is, setCpf] = useState('');
    const [cpf_invalid, setCpfInvalid] = useState(false);
    const [parcelas, setParcelas] = useState(1);
    const [step_card, setStepCard] = useState(1);
    const [card_select, serCardSelect] = useState(null);
    const [loading_cartao, setLoadingCartao] = useState(false);

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);


    const onRegister = () => {
        var invalids = 0;
        if(tipo_pagamento == 'credit'){
            if(props.cartoes.length > 0){
                if(step_card === 1){
                    if(card_select !== null){
                        if(seguranca.length > 2){
                            setSegurancaInvalid(false);
                        }
                        else{
                            invalids++;
                            setSegurancaInvalid(true);
                        }
                    }
                    else{
                        invalids++;
                        toast.error('Por favor, selecione o cartão')
                    }

                }
                else if(step_card === 2){
                    if(fullname.length > 0){
                        setFullNameInvalid(false)
                    }
                    else{
                        invalids++;
                        setFullNameInvalid(true);
                    }

                    if(credit_card.length > 6){
                        setCreditCardInvalid(false)
                    }
                    else{
                        invalids++;
                        setCreditCardInvalid(true);
                    }
                    
                    if(cpf.isValid(cpf_is.replace(/([^\d])+/gim, '')) == true){
                        setCpfInvalid(false);
                    }
                    else{
                        invalids++;
                        setCpfInvalid(true);
                    }

                    if(validade.replace(/([^\d])+/gim, '').length > 5){
                        if(parseInt(validade.slice(0, 2)) > 12){
                            invalids++;
                            setSegurancaInvalid(true);
                        }
                        else if(parseInt(validade.slice(-4)) < new Date().getFullYear()){
                            invalids++;
                            setSegurancaInvalid(true);
                        }
                        else{
                            setValidadeInvalid(false);
                        }
                    }
                    else{
                        invalids++;
                        setValidadeInvalid(true);
                    }
                    if(seguranca.length > 2){
                        setSegurancaInvalid(false);
                    }
                    else{
                        invalids++;
                        setSegurancaInvalid(true);
                    }
                }
            }
            else{
                if(fullname.length > 0){
                    setFullNameInvalid(false)
                }
                else{
                    invalids++;
                    setFullNameInvalid(true);
                }

                if(credit_card.length > 6){
                    setCreditCardInvalid(false)
                }
                else{
                    invalids++;
                    setCreditCardInvalid(true);
                }
                
                if(cpf.isValid(cpf_is.replace(/([^\d])+/gim, '')) == true){
                    setCpfInvalid(false);
                }
                else{
                    invalids++;
                    setCpfInvalid(true);
                }

                if(validade.replace(/([^\d])+/gim, '').length > 5){
                    if(parseInt(validade.slice(0, 2)) > 12){
                        invalids++;
                        setSegurancaInvalid(true);
                    }
                    else if(parseInt(validade.slice(-4)) < new Date().getFullYear()){
                        invalids++;
                        setSegurancaInvalid(true);
                    }
                    else{
                        setValidadeInvalid(false);
                    }
                }
                else{
                    invalids++;
                    setValidadeInvalid(true);
                }
                if(seguranca.length > 2){
                    setSegurancaInvalid(false);
                }
                else{
                    invalids++;
                    setSegurancaInvalid(true);
                }
            }
        }
        else{
            if(props.validar_cpf == true){
                if(cpf.isValid(cpf_is.replace(/([^\d])+/gim, '')) == true){
                    setCpfInvalid(false);
                }
                else{
                    invalids++;
                    setCpfInvalid(true);
                }
            }
        }

        if(invalids == 0){
            pagar();
        }

    }
    
    const date_time_f = () => {
        var url_new = 'cpf_cnpj='+props.url_user.documento.replace(/([^\d])+/gim, '')+'&name_or_business='+props.url_user.nome_completo+
                '&birthdate='+props.url_user.data_nascimento.replace(/([^\d])+/gim, '');

        if(props.url_user.documento_cpf){
            url_new += '&cpf_business='+props.url_user.documento_cpf.replace(/([^\d])+/gim, '')
        }

        if(props.url_user.nome_completo.length > 9){
            function dataAtualFormatada(){
                var currentdate = new Date(); 
                let weekday = new Date().toLocaleString('en-us', {weekday:'long'});
                var datetime = currentdate.getDate().toString().padStart(2, '0') + "/"
                            + (currentdate.getMonth()+1).toString().padStart(2, '0')  + "/" 
                            + currentdate.getFullYear() + " - "  
                            + currentdate.getHours().toString().padStart(2, '0') + ":"  
                            + currentdate.getMinutes().toString().padStart(2, '0') + ":" 
                            + currentdate.getSeconds().toString().padStart(2, '0') + '#' + weekday;

                return datetime;
            }

            function dataAtual(){
                var currentdate = new Date(); 
                var datetime = currentdate.getDate().toString().padStart(2, '0') + "/"
                            + (currentdate.getMonth()+1).toString().padStart(2, '0')  + "/" 
                            + currentdate.getFullYear() + " - "  
                            + currentdate.getHours().toString().padStart(2, '0') + ":"  
                            + currentdate.getMinutes().toString().padStart(2, '0') + ":" 
                            + currentdate.getSeconds().toString().padStart(2, '0');

                return datetime;
            }

            var tokenHash = CryptoJS.MD5(
                'aqbank_key@'
                +props.url_user.nome_completo.replace(/\s/g, '').substr(0, 9).toUpperCase()+''
                +props.url_user.nome_completo.replace(/\s/g, '').substr(-8).toUpperCase()
                +'#'+props.url_user.documento.replace(/([^\d])+/gim, '').substr(0, 3)
                +'#'+props.url_user.documento.replace(/([^\d])+/gim, '')
                +'#'+props.url_user.data_nascimento.replace(/([^\d])+/gim, '')
                +'#d'+dataAtualFormatada());

            url_new += '&aqbank_key='+tokenHash.toString() +'&date_atual='+dataAtual();
        }

        return url_new;

    }
    const pagar = () => {
        var url = ENDPOINT + 'contabilidade/store_pay';
        setLoadingCartao(true);
        
        if(props.url_user){
            url += '?'+date_time_f();
        }

        var tokenHash = CryptoJS.MD5(props.token_email);

        if(tipo_pagamento == 'credit'){
            var payment = {
                "opcao_contratacao": props.opcao_contratacao,
                "tipo_pagamento": 'credit',
                "cupom": props.cupom !== null ? props.cupom.hash : null,
                "valor": getValorTotal(),
                "tipo_user": "buyer",
                "user_id": props.user_id,
                "auth_by_script": tokenHash.toString(),
                "cartao_payload": {},
                "num_funcionarios": parseInt(props.funcionario, 10),
                "plano_flag": props.maquininhas[0].modelo
            };

            if(props.cartoes.length > 0){
                if(step_card === 1){
                    payment.cartao_payload = {
                        "cartao_id": card_select.id,
                        "cvc": seguranca
                    }
                }
                else{
                    payment.cartao_payload = {
                        "apelido": "Primeiro cartão",
                        "prop_cartao": fullname,
                        "validade_mes": validade.substr(0, 2),
                        "validade_ano": validade.substr(-2),
                        "bandeira": getBandeiraCartao(),
                        "cvc": seguranca,
                        "num_cartao": credit_card.replace(/([^\d])+/gim, ''),
                        "prop_cpf": cpf_is.replace(/([^\d])+/gim, '')
                    }
                }
            }
            else{
                payment.cartao_payload = {
                    "apelido": "Primeiro cartão",
                    "prop_cartao": fullname,
                    "validade_mes": validade.substr(0, 2),
                    "validade_ano": validade.substr(-2),
                    "bandeira": getBandeiraCartao(),
                    "cvc": seguranca,
                    "num_cartao": credit_card.replace(/([^\d])+/gim, ''),
                    "prop_cpf": cpf_is.replace(/([^\d])+/gim, '')
                }
    
            }
            
            if(props.validar_cpf == true){
                payment.cpf = cpf_is.replace(/([^\d])+/gim, '');
            }


            if(props.endereco_fical > 0){
                payment.endereco_fiscal = props.endereco_fical;

            }

            if(props.certificado_a1 > 0){
                payment.certificado_a1 = props.certificado_a1;

            }
        }
        else{
            var payment = {
                "opcao_contratacao": props.opcao_contratacao,
                "cupom": props.cupom !== null ? props.cupom.hash : null,
                "tipo_pagamento": 'boleto',
                "numero_parcelas": parcelas,
                "valor": getValorTotal(),
                "tipo_user": "buyer",
                "auth_by_script": tokenHash.toString(),
                "user_id": props.user_id,
                "num_funcionarios": parseInt(props.funcionario, 10),
                "plano_flag": props.maquininhas[0].modelo
            };
            if(props.validar_cpf == true){
                payment.cpf = cpf_is.replace(/([^\d])+/gim, '');
            }

            if(props.endereco_fical > 0){
                payment.endereco_fiscal = props.endereco_fical;
                payment.regiao_endereco_fiscal = props.regiao;
            }

            if(props.certificado_a1 > 0){
                payment.certificado_a1 = props.certificado_a1;

            }
        }


        axios.post(url, payment)
        .then((response) => {
            //setLoading(false);
            insertParam('pagamento_aprovado', 'true');

            var data = response.data;
            if(data.data.pagamento.forma_pagamento == 'credit'){
                props.aprovar_pagamento_por_cartao(data.data.pagamento, credit_card.replace(/([^\d])+/gim, ''), parcelas);
            }
            else if(data.data.pagamento.forma_pagamento == 'boleto'){
                props.aprovar_pagamento_por_boleto(data.data.pagamento);
            }
            else if(data.data.pagamento.forma_pagamento == 'cupom100'){
                props.aprovar_pagamento_por_cupom(data.data.pagamento);
            }

        })
        .catch((error) => {
            insertParam('pagamento_aprovado', 'false');

            var response = error.response.data;
            if(tipo_pagamento == 'credit'){
                props.reprovar_pagamento(response.errors, parcelas)
            }
            else{
                props.reprovar_pagamento(response.errors, 1)
            }
            //props.aprovar_pagamento_por_boleto(data.data.pagamento);
        });
    }
    
    function onChangeTxtCardNumber(e) {		
        var txtCardNumber = e.target;
        var cardNumber = txtCardNumber.value;
    
        // Do not allow users to write invalid characters
        var formattedCardNumber = cardNumber.replace(/[^\d]/g, "");
        formattedCardNumber = formattedCardNumber.substring(0, 16);
    
        // Split the card number is groups of 4
        var cardNumberSections = formattedCardNumber.match(/\d{1,4}/g);
        if (cardNumberSections !== null) {
            formattedCardNumber = cardNumberSections.join(' ');	
        }
        
        console.log("'"+ cardNumber + "' to '" + formattedCardNumber + "'");
    
        // If the formmattedCardNumber is different to what is shown, change the value
        /* if (cardNumber !== formattedCardNumber) {
            txtCardNumber.value = formattedCardNumber;
        } */

        setCreditCard(formattedCardNumber);
    }


    const _getKeyValue_ = (key) => (obj) => {
        return obj[key];
    };

    const getBandeiraCartao = () => {
        var cardnumber = credit_card.replace(/[^0-9]+/g, '');
        var cards = {
          visa: /^4[0-9]{12}(?:[0-9]{3})/,
          mastercard: /^5[1-5][0-9]{14}/,
          diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
          amex: /^3[47][0-9]{13}/,
          discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
          hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
          elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
          jcb: /^(?:2131|1800|35\d{3})\d{11}/,
          aura: /^(5078\d{2})(\d{2})(\d{11})$/,
        };
    
        for (var flag in cards) {
          const card = _getKeyValue_(flag)(cards);
          if (card.test(cardnumber)) {
            return flag;
          }
        }
        return '';
      };
      const getImage = () => {
        if (getBandeiraCartao() !== '') {
          var image = require('../../assets/bandeiras/' +
            getBandeiraCartao() +
            '.svg');
          return image.default;
        }
        else {
          return (null)
        }
    };
    const getImageParam = (cartao) => {
        console.log(cartao)
      if (cartao !== '' &&  cartao !== null) {
        var image = require('../../assets/bandeiras/' +
        cartao +
          '.svg');
        return image.default;
      }
      else {
        var image = require('../../assets/bandeiras/undefined.svg');
        return image.default;
      }
  };
  
    
    const getValorTotal = () => {
        var valor = 0;
        props.maquininhas.forEach(e => {
            valor += (e.valor * e.quantidade);
            
            if(props.funcionarios){
                var funcionarios = parseInt(props.funcionarios);
                
                if(funcionarios > e.funcionarios){
                    var valor_adicional = 25 * (funcionarios - e.funcionarios);
                    valor += valor_adicional;
                }
            }

            if (props.certificado_a1) {
                if (props.certificado_a1 > 0) {
                    var valor_adicional_a1 = 99;
                    valor += valor_adicional_a1;
                }
            }
            
            if (props.endereco_fical) {
                if (props.endereco_fical > 0) {
                    var valor_adicional_end = 99;
                    valor += valor_adicional_end;
                }
            }
        });
        if(props.cupom !== null){
            if(props.cupom.condicao !== 'plano'){
                if(props.cupom.valor_tipo == 'porcentagem'){
                    var desconto = (valor * props.cupom.valor) / 100;
                    valor = valor - desconto;
                }
                else{
                    valor = valor - props.cupom.valor;
                }
            }
            else{
                var desconto = 0;

                props.maquininhas.forEach(e => {
                    if(e.texto == props.cupom.plano){
                        if(props.cupom.valor_tipo == 'porcentagem'){
                            desconto = (e.valor * props.cupom.valor) / 100;
                            valor = valor - desconto;
                        }
                        else{
                            valor = valor - props.cupom.valor;
                        }
                    }
                })

            }
        }

        return valor;
    }
    return(
        <>
            <br/>
            <LayouPagamento 
                dropdownOpen={dropdownOpen}
                setDropdownOpen={setDropdownOpen}
                toggleDropdown={toggle}
                {...props}
                >
                <div hidden={loading_cartao}>
                    <Row>
                        <Col>
                            <h5>Selecione a forma de pagamento:</h5>
                            <ButtonGroup>
                                <Button color="dark" outline={tipo_pagamento !== 'credit'} onClick={() => setTipoPagamento('credit')}><MdCreditCard /> Cartão de crédito</Button>
                                <Button color="dark" outline={tipo_pagamento !== 'boleto'} onClick={() => setTipoPagamento('boleto')}><AiOutlineBarcode /> Boleto</Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                    <Form hidden={tipo_pagamento === 'boleto'}>
                        <hr/>
                        <div hidden={props.cartoes.length > 0}>
                            <Row>
                                <Col md={6} xs={12}>
                                    <FormGroup>
                                        <Label>Número de cartão</Label>
                                        <div style={{display: 'flex'}}>
                                            <InputText
                                                placeholder="Número de cartão"
                                                onChange={onChangeTxtCardNumber}
                                                value={credit_card}
                                                invalid={credit_card_invalid}
                                                />
                                                {getBandeiraCartao() === '' ? null : (
                                                    <img 
                                                        className="bandeira" 
                                                        height="25px" 
                                                        style={{
                                                            marginTop: '6px', 
                                                            marginLeft: '6px'
                                                        }}
                                                        src={getImage()} />
                                                )}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md={6} xs={12}>
                                    <FormGroup>
                                        <Label>Nome no cartão</Label>
                                        <InputText 
                                            placeholder="Nome no cartão"
                                            onChange={e => setFullName(e.target.value)}
                                            value={fullname}
                                            invalid={fullname_invalid}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4} xs={12}>
                                    <FormGroup>
                                        <Label>Validade (MM/AA)</Label>
                                        <InputMasked 
                                            mask="99/9999" 
                                            placeholder="Validade (MM/AA)"
                                            onChange={e => setValidade(e.target.value)}
                                            value={validade}
                                            invalid={validade_invalid}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4} xs={12}>
                                    <FormGroup>
                                        <Label>Cód. de Segurança</Label>
                                        <InputText 
                                            mask="9999" 
                                            placeholder="Cód. de Segurança"
                                            onChange={e => setSeguranca(e.target.value)}
                                            value={seguranca}
                                            invalid={seguranca_invalid}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4} xs={12}>
                                    <FormGroup>
                                        <Label>CPF do Titular do cartão</Label>
                                        <InputMasked 
                                            placeholder="Insira o CPF"
                                            onChange={e => setCpf(e.target.value)}
                                            value={cpf_is}
                                            invalid={cpf_invalid}
                                            mask="999.999.999-99"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                        {step_card === 1 && props.cartoes.length > 0 ?
                            <>
                                <Button color="success" hidden={card_select !== null} onClick={() => setStepCard(2)}>Novo cartão</Button>
                                <br/>
                                <Row style={{textAlign: 'center'}} hidden={card_select !== null}>
                                    {props.cartoes.map(p => (
                                        <Col style={{padding: 15, maxWidth: '350px'}}>
                                            <center>
                                                <Card style={{ padding: '15px', width: '300px', borderRadius: '25px', border: 'none', background: 'rgb(130,18,143)', background: 'linear-gradient(90deg, rgba(130,18,143,1) 0%, rgba(145,28,158,1) 28%, rgba(183,27,201,1) 100%)', color: 'white' }}>
                                                    <CardBody>
                                                        <div style={{textAlign: 'right'}}>
                                                            <img src={getImageParam(p.bandeira)} height="23" />
                                                        </div>
                                                        <br/>
                                                        <h6>{p.numero}</h6>
                                                    </CardBody>
                                                </Card>
                                            </center>
                                            <hr/>
                                            <center>
                                                <Button color="dark" onClick={() => serCardSelect(p)}>Selecionar Cartão</Button>
                                            </center>
                                        </Col>
                                    ))}
                                </Row>
                                {card_select !== null ?
                                    <div style={{textAlign: 'center'}}>
                                        <center>
                                            <Card style={{ padding: '15px', maxWidth: '300px', borderRadius: '25px', border: 'none', width: '18rem', background: 'rgb(130,18,143)', background: 'linear-gradient(90deg, rgba(130,18,143,1) 0%, rgba(145,28,158,1) 28%, rgba(183,27,201,1) 100%)', color: 'white' }}>
                                                <CardBody>
                                                    <div style={{textAlign: 'right'}}>
                                                        <img src={getImageParam(card_select.bandeira)} height="30" />
                                                    </div>
                                                    <br/>
                                                    <h6>{card_select.numero}</h6>
                                                    <Input 
                                                        style={{
                                                            borderRadius: '15px',
                                                            border: 'none'
                                                        }}
                                                        mask="9999" 
                                                        placeholder="Cód. de Segurança (CVV)"
                                                        onChange={e => setSeguranca(e.target.value)}
                                                        value={seguranca}
                                                        invalid={seguranca_invalid}
                                                    />
                                                </CardBody>
                                            </Card>
                                            <br/>
                                        </center>
                                        <Button color="danger" onClick={() => serCardSelect(null)}>Voltar</Button>
                                    </div>
                                    :
                                    null
                                }
                            </>
                        :
                        step_card === 2 && props.cartoes.length > 0 ?
                            <>
                                <Row>
                                    <Col md={6} xs={12}>
                                        <FormGroup>
                                            <Label>Número de cartão</Label>
                                            <div style={{display: 'flex'}}>
                                                <InputText
                                                    placeholder="Número de cartão"
                                                    onChange={onChangeTxtCardNumber}
                                                    value={credit_card}
                                                    invalid={credit_card_invalid}
                                                    />
                                                    {getBandeiraCartao() === '' ? null : (
                                                        <img 
                                                            className="bandeira" 
                                                            height="25px" 
                                                            style={{
                                                                marginTop: '6px', 
                                                                marginLeft: '6px'
                                                            }}
                                                            src={getImage()} />
                                                    )}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6} xs={12}>
                                        <FormGroup>
                                            <Label>Nome no cartão</Label>
                                            <InputText 
                                                placeholder="Nome no cartão"
                                                onChange={e => setFullName(e.target.value)}
                                                value={fullname}
                                                invalid={fullname_invalid}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4} xs={12}>
                                        <FormGroup>
                                            <Label>Validade (MM/AA)</Label>
                                            <InputMasked 
                                                mask="99/9999" 
                                                placeholder="Validade (MM/AA)"
                                                onChange={e => setValidade(e.target.value)}
                                                value={validade}
                                                invalid={validade_invalid}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4} xs={12}>
                                        <FormGroup>
                                            <Label>Cód. de Segurança</Label>
                                            <InputText 
                                                mask="9999" 
                                                placeholder="Cód. de Segurança"
                                                onChange={e => setSeguranca(e.target.value)}
                                                value={seguranca}
                                                invalid={seguranca_invalid}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4} xs={12}>
                                        <FormGroup>
                                            <Label>CPF do Titular do cartão</Label>
                                            <InputMasked 
                                                placeholder="Insira o CPF"
                                                onChange={e => setCpf(e.target.value)}
                                                value={cpf_is}
                                                invalid={cpf_invalid}
                                                mask="999.999.999-99"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </>
                        :
                        null
                        }
                    </Form>
                    <Form hidden={tipo_pagamento !== 'boleto'}>
                        {props.validar_cpf == true ?
                            <FormGroup>
                                <br/>
                                <Label>Insira seu CPF</Label>
                                <InputMasked 
                                    placeholder="Insira o CPF"
                                    onChange={e => setCpf(e.target.value)}
                                    value={cpf_is}
                                    invalid={cpf_invalid}
                                    mask="999.999.999-99"
                                />
                            </FormGroup>
                        :
                        <p>
                            <br/>
                            Titular do Boleto: {props.url_user.nome_completo}<br/>
                            Documento: {props.url_user.documento}
                        </p>
                        }
                    </Form>
                    <br/>
                    <br/>
                    <br/>
                    <div style={{ position: 'absolute', bottom: '7px', width: '92%', textAlign: 'right' }}>
                        <hr/>
                        <Button className="btn-next" color="success" onClick={() => onRegister()}>
                            <h5 style={{margin: 0}}> <MdAttachMoney/> Realizar Pagamento</h5>
                        </Button>
                    </div>
                </div>
                <div hidden={!loading_cartao} style={{textAlign: 'center', minHeight: '250px'}}>
                    <FadeLoader color={'#004723'} margin={0} loading={50} size={60} /><br />
                </div>
            </LayouPagamento>
        </>
    )
}
