import axios from 'axios';
import React, { useEffect } from 'react';
import {useDropzone} from 'react-dropzone';
import 'react-dropzone/dist/'
import { toast } from 'react-toastify';
import { ENDPOINT } from './auth/endpoint';
import get_extension from 'get-url-extension';
import { MdPictureAsPdf, MdFileDownload, MdCheck } from 'react-icons/md';
import ClipLoader from "react-spinners/ClipLoader";
import { Card, CardBody, Col, Row } from 'reactstrap';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 250,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 100,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

const pdf = {
  display: 'block',
  width: 'auto',
  height: '100%',
  color: '#af0000'
};
function Basic(props) {
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
    onDrop: props.onDrop,
    accept: 'image/jpeg, image/png',
    maxSize: 5242880,
    maxFiles: 1
  });

  const getFileExtension = (url) =>{
    var extension = get_extension(url);
    console.log(extension)

    if(extension === '.png' ||
        extension === '.PNG' ||
        extension === '.jpg' ||
        extension === '.jpeg' ||
        extension === '.svg' ||
        extension === '.webp'){
        return 'image'
    }
    else if(extension === '.pdf' ||
        extension === '.PDF'){
        return 'pdf';
    }

    return 'file';
  }

  const thumbs = props.documentos.map((file, i) => (
    <>
      <div style={thumb} key={i}>
        <div style={thumbInner}>
          <a href="#" alt={"picturing-image-"+i}>
            {file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'image/jpg' ?
              <>
                <img
                  src={URL.createObjectURL(file)}
                  style={img}
                />
              </>
              :
              getFileExtension(file.url) === 'pdf' ?
                <MdPictureAsPdf 
                  style={pdf}/>
              :
                <MdFileDownload 
                  style={pdf}/>
            }
          </a>
        </div>
        <div style={{marginLeft: 15}}>
          <small>{props.tipo_documento}</small>
        </div>
      </div>
    </>
  ));
  const searchFiles = () => {
    var exists = 0;
    props.documentos_enviados.forEach(element => {
      if(element.descricao === props.tipo_documento){
        exists++;
      }
    });

    if(exists > 0){
      return false
    }

    return true;
  }
  return (
    <section className="container">
      <Row>
          {searchFiles() === true ?
            <>
              <Col md={6} sm={12}>
                  <div hidden={props.tipo_documento === null} {...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()} />
                    <h5>Arraste o arquivo ou clique para selecionar os arquivos</h5>
                    <small>Tamanho maximo (5 MB)</small>
                  </div>
              </Col>
              <Col md={6} sm={12}>
                <aside>
                  <ul style={{padding: 0}}>{thumbs}</ul>
                </aside>
              </Col>
            </>
          :
          <>
            <Col>
              <Card>
                <CardBody>
                  <MdCheck /> Documento já enviado
                </CardBody>
              </Card>
            </Col>
          </>
          }
      </Row>
    </section>
  );
}
export default Basic;