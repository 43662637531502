import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import background from './assets/background_aqcontabil.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import { StepPagamento } from "./components/Pages/StepPagamento";
import { StepPessoais } from "./components/Pages/StepPessoais";
import { PageInicio } from "./components/Pages/PageInicio";
import { PagePagamento } from "./components/Pages/LayouPagamento";
import { StepFinal } from "./components/Pages/StepFinal";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import axios from "axios";
import logo_aqpago from "./assets/logo-aqpag.png";
import { ENDPOINT } from "./auth/endpoint";
import { ModalMaquinas } from "./components/Modal/ModalMaquinas";
import logo_ecommerce from "./assets/ecommerce-aqpago.svg";
import mp35p from "./assets/mp35p.png";
import d195 from "./assets/d195.webp";
import aqpagofacilidade from "./assets/AQPAGO-FACILIDADE.svg";
import linkpagamento from "./assets/link-pagamento.svg";
import { useLocation } from "react-router-dom";
import ModalLoja from "./components/LojaComponents/ModalLoja";
import { MdCheck } from "react-icons/md";
import ClipLoader from "react-spinners/ClipLoader";
import GoogleAds from "./GoogleAds";
import StepEcommerce from "./components/Pages/StepEcommerce";

import logo_basico from "./assets/icons-aqcontabil/basico.svg";
import logo_padrao from "./assets/icons-aqcontabil/padrao.svg";
import logo_contador_essencial from "./assets/icons-aqcontabil/contador__essencial.svg";
import logo_contador_pro from "./assets/icons-aqcontabil/contador_pro.svg";
import aqcontabil_background from './assets/aqcontabil-back.svg';
import aqcontabil from './assets/aqcontabil.svg';
import contador_empreendedor from "./assets/icons-aqcontabil/contador_empreendedor.svg";

var CryptoJS = require("crypto-js");

const DataPayment = styled.div`
`

const Div = styled.div`
    min-height: 100vh;
    height: 100%;
    max-width: 100%;
    background: rgb(23, 73, 69);
    background-size: 100%;
    background-image: url(${background});
    margin: 0;
    min-height: 100vh;
    font-family: 'FiraSans Light';
    
    #header-title{
        background: black;
        padding: 15px;
        border: 1px solid #00000038;
        border-top-left-radius: 30px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 10px;
        box-shadow: 0 0 10px #0000004f;
        color: white;
        position: absolute;
        top: -35px
    }
    .dropzone {
        background: #f5f5f5;
        border: 1px dashed #c2c2c2;
        border-radius: 3px;
        text-align: center;
        padding: 36px;
        font-family: "Montserrat", sans-serif;
        font-size: 12px;
        font-weight: 600;
        font-family: 'FiraSans Light';
      }
      
      .dropzone:hover {
        background: rgb(144 106 159);
        border: 1px dashed #fff;
        color: #fff;
      }

      .dropzone:[drop-active=true] {
        background: rgb(144 106 159);
        border: 1px dashed #fff;
        color: #fff;
      }
      
    @media screen and (max-width: 800px) {
        .name-step{
            display: none;
        }
    } 
`;

const maquininhas_list = [
    {
        modelo: 'CONTADOR-BASICO',
        texto: 'Contador Básico',
        texto2: 'Básico',
        valor: 79.00,
        quantidade_disponivel: 1,
        limite: 255,
        funcionarios: 0,
        descricao: 'Sem Funcionário Incluso',
        image: logo_basico,
        entrega: ''
    },
    {
        modelo: 'CONTADOR-PADRAO',
        texto: 'Contador Padrão',
        texto2: 'Padrão',
        valor: 129.00,
        quantidade_disponivel: 1,
        limite: 1,
        funcionarios: 1,
        descricao: '1 Funcionário Incluso',
        image: logo_padrao,
        entrega: ''
    },
    {
        modelo: 'CONTADOR-EMPREENDEDOR',
        texto: 'Contador Empreendedor Plus+',
        texto2: 'Empreendedor Plus+',
        valor: 293.00,
        quantidade_disponivel: 1,
        limite: 1,
        funcionarios: 1,
        descricao: '1 Funcionários Inclusos',
        image: contador_empreendedor,
        entrega: ''
    },
    {
        modelo: 'CONTADOR-ESSENCIAL',
        texto: 'Contador Essencial',
        texto2: 'Essencial',
        valor: 399.00,
        quantidade_disponivel: 1,
        limite: 1,
        funcionarios: 3,
        descricao: '3 Funcionários Inclusos',
        image: logo_contador_essencial,
        entrega: ''
    },
    {
        modelo: 'CONTADOR-PRO',
        texto: 'Contador PRO',
        texto2: 'Pro',
        valor: 599.00,
        quantidade_disponivel: 1,
        limite: 1,
        funcionarios: 5,
        descricao: '5 Funcionários Inclusos',
        image: logo_contador_pro,
        entrega: ''
    }
];


const Principal = (props) => {
    const [tipo_value, setTipoValue] = useState('');
    const [funcionario, setFuncionario] = useState(0);
    const [certificado_a1, setCertificadoA1] = useState(0);
    const [maquininhas, setMaquininha] = useState([]);
    const [step, setStep] = useState(0);
    const [user_id, setUserId] = useState(0);
    const [cartoes, setCartoes] = useState([]);
    const [loading_cartao, setLoadingCartao] = useState(false);
    const [validar_cpf, setValidarCpf] = useState(false);
    const [request_final, setRequestFinal] = useState({});
    const [token_email, setTokenEmail] = useState('');
    const [cupom, setCupom] = useState(null);
    const [registrar_loja_btn, setReistrarLojaBtn] = useState(false);
    const [url_loja, setUrlLoja] = useState('');
    const [visible_maquininhas, setVisibleMaquininhas] = useState(false);
    const [visible_loja, setVisibleLoja] = useState(false);
    const [integracao_loading, setIntegracaoLoading] = useState(false);
    const [integracao_visible, setIntegracaoVisible] = useState(false);
    const [url_user, setUrlUser] = useState('');
    const [shop_aqpago, setShopAqpago] = useState(null);

    const [endereco_fical, setEnderecoFiscal] = useState(0);
    const [regiao, setRegiao] = useState(0);
    

    const { modelo_maquininha } = useParams();
    const parametros_url = useLocation().search;

    useEffect(() => {
        if(modelo_maquininha){
            adicionar_maquininha(modelo_maquininha);
        }
        else{
            setVisibleMaquininhas(true);
        }
    }, []);

    const validar_maquininhas = (modelo_maquininha) => {
        var existe = false;
        maquininhas.forEach(e => {
            if(e.modelo == modelo_maquininha){
                if(e.quantidade_disponivel > 0){
                    existe = true
                }
            }
        });

        return existe;
    }

    const validar_existencia_maquininhas = (modelo_maquininha) => {
        var existe = false;
        maquininhas_list.forEach(e => {
            if(e.modelo == modelo_maquininha){
                if(e.quantidade_disponivel > 0){
                    existe = true
                }
            }
        });

        return existe;
    }
    const validar_se_existe_integracao = () => {
        var validar = false;
        maquininhas.forEach(p => {
            if(p.modelo == 'INTEGRACAO-PAGAMENTO'){
                validar = true;
            }
        });

        return validar;
    }
    const adicionar_maquininha = (modelo_maquininha) => {
        if(modelo_maquininha == "CERTIFICADO_A1"){
            var maquininha = {}; 
            
            maquininha.modelo = "CERTIFICADO_A1";
            maquininha.texto = "Certificado A1";
            maquininha.texto2 = "Certificado A1";
            maquininha.quantidade = 1;
            maquininha.descricao = "*Certificado emitido pela empresa Soluti";
            maquininha.valor = 99;
            maquininha.funcionarios = 0;
            maquininha.parcelas = 1;
            maquininha.limite = 1;
            maquininha.entrega =  '';

            var is_maquininhas = [];
            is_maquininhas.push(maquininha);

            setMaquininha([ ...is_maquininhas ]);
            setEnderecoFiscal(0);
            setRegiao('');
            setCertificadoA1(0);
            setStepCurrent(1);
        }
        else{
            modelo_maquininha = modelo_maquininha.toUpperCase();
            if(validar_maquininhas(modelo_maquininha) === false){
                if(validar_existencia_maquininhas(modelo_maquininha) === true){
                    var maquininha = {}; 
            
                    maquininhas_list.forEach(e => {
                        if(modelo_maquininha == e.modelo){
                            maquininha.modelo = e.modelo;
                            maquininha.texto = e.texto;
                            maquininha.texto2 = e.texto2;
                            maquininha.quantidade = 1;
                            maquininha.descricao = e.descricao;
                            maquininha.valor = e.valor;
                            maquininha.funcionarios = e.funcionarios;
                            maquininha.parcelas = 1;
                            maquininha.limite = e.limite;
                            maquininha.entrega =  e.entrega;
                        }
                    });
            
                    var is_maquininhas = [];
                    is_maquininhas.push(maquininha);
        
                    setMaquininha([ ...is_maquininhas ]);
                }
            }
        }  
    }
    const validar_cupom = (user) => {
        if(cupom !== null){
            /* if(cupom.condicao == 'plano'){
                if(cupom.plano != user.plano){
                    setCupom(null);
                    toast.error('Cupom removido, esse cupom não é habilitado para seu plano');
                }
            }
            else if(cupom.condicao == 'planos'){
                var valid = false;
                cupom.planos.forEach(e => {
                    if(user.plano == e.plano){
                        valid = true;
                    }
                })

                if(valid == false){
                    setCupom(null);
                    toast.error('Cupom removido, esse cupom não é habilitado para seu plano');
                }
            }
            else  */
            if(cupom.condicao == 'cliente'){
                if(cupom.documento != user.cpf && cupom.documento != user.cnpj){
                    setCupom(null);
                    toast.error('Cupom removido, esse cupom não é habilitado para você');
                }
            }
            else if(cupom.condicao == 'plano'){
                var valid = false;
                maquininhas.forEach(e => {
                    if(cupom.plano == e.texto){
                        valid = true;
                    }
                })

                if(valid == false){
                    setCupom(null);
                    toast.error('Cupom removido, esse cupom vale para o plamp '+cupom.plano+', que não está em sua compra');
                }
            }
            else if(cupom.condicao == 'planos'){
                var valid = false;
                maquininhas.forEach(e => {
                    var result = cupom.planos.filter(p => p.plano == e.texto);
                    if(result.length > 0){
                        valid = true;
                    }
                })

                if(valid == false){
                    setCupom(null);
                    toast.error('Cupom removido, não vale para nenhum dos planos que está em sua compra');
                }

            }
        }
    }

    const reprovar_pagamento = (message, parcelas) => {
        var reprovacao = {
            tipo_pagamento: 'erro',
            message_error: message,
            parcelas: parcelas
        }

        setRequestFinal(reprovacao);
        setStep(3);
    }

    const aprovar_pagamento_por_boleto = (pagamento) => {
        var reprovacao = {
            tipo_pagamento: 'boleto',
            codigo_barras: pagamento.boleto_codigo_barras,
            url: pagamento.boleto_url,
            id_transacao: pagamento.api_transacao_id,
            parcelas: 1
        }
        verificar_shop(pagamento.api_transacao_id);
        setRequestFinal(reprovacao);
        setStep(3);
    }

    const aprovar_pagamento_por_cartao = (pagamento, num_cartao, parcelas) => {
        var reprovacao = {
            tipo_pagamento: 'cartao',
            numero_cartao: num_cartao.slice(-4),
            id_transacao: pagamento.api_transacao_id,
            parcelas: parcelas
        }
        
        verificar_shop(pagamento.api_transacao_id);
        setRequestFinal(reprovacao);
        setStep(3);
    }

    const aprovar_pagamento_por_cupom = (pagamento) => {
        var reprovacao = {
            tipo_pagamento: 'cartao',
            numero_cartao: cupom.hash,
            id_transacao: pagamento.api_transacao_id,
            parcelas: 1
        }

        setRequestFinal(reprovacao);
        setStep(3);
    }

    const verificar_shop = (api_transacao_id) => {
        maquininhas.forEach(e => {
            if(e.modelo == 'SHOPAQPAGO'){
                const tema = new URLSearchParams(parametros_url).get('tema');

                var url_shop = '/shop-buy-aqpago/'+api_transacao_id;
                if(tema !== null){
                    url_shop += '?tema='+tema;
                }

                setReistrarLojaBtn(true)
                setUrlLoja(url_shop);
            }
        })
    }

    const adicionar_quantidade = (index, value) => {
        var maquininha = maquininhas;
        maquininha[index].quantidade = value;
        
        setMaquininha([...maquininha]);

    }

    const remover_maquina = (index) => {
        var maquininha = maquininhas;
        maquininha.splice(index, 1);
        
        setMaquininha([...maquininha]);

    }
    const setStepCurrent = (step_is) => {
        setStep(step_is);
    }
    const addCupom = (cupom) => {
        var url = ENDPOINT + 'adicionar_cupom_aqcontabil/'+cupom;

        axios.get(url)
            .then((response) => {
                //setLoading(false);
                var response = response.data;
                setCupom(response.data);

            })
            .catch((error) => {
                var response = error.response.data;
                toast.error(response.errors); 
            });
    }
    const integrarPagamento = () => {
        setIntegracaoLoading(true);
        var url = ENDPOINT + 'vendas_maquininha/integracao_pagamento?'+date_time_f();
        
        var formData = new FormData;
        formData.append('user_id', user_id);
        axios.post(url, formData)
        .then((response) => {
            var response = response.data;
            toast.success(response.message); 
            setIntegracaoLoading(false);
            setIntegracaoVisible(true)
            //setCupom(response.data);
        })
        .catch((error) => {
            var response = error.response.data;
            toast.error(response.errors); 
            setIntegracaoLoading(false);
        });
    }

    const date_time_f = () => {
        var url_new = 'cpf_cnpj='+url_user.documento.replace(/([^\d])+/gim, '')+'&name_or_business='+url_user.nome_completo+
                '&birthdate='+url_user.data_nascimento.replace(/([^\d])+/gim, '');

        if(url_user.documento_cpf){
            url_new += '&cpf_business='+url_user.documento_cpf.replace(/([^\d])+/gim, '')
        }

        if(url_user.nome_completo.length > 9){
            function dataAtualFormatada(){
                var currentdate = new Date(); 
                let weekday = new Date().toLocaleString('en-us', {weekday:'long'});
                var datetime = currentdate.getDate().toString().padStart(2, '0') + "/"
                            + (currentdate.getMonth()+1).toString().padStart(2, '0')  + "/" 
                            + currentdate.getFullYear() + " - "  
                            + currentdate.getHours().toString().padStart(2, '0') + ":"  
                            + currentdate.getMinutes().toString().padStart(2, '0') + ":" 
                            + currentdate.getSeconds().toString().padStart(2, '0') + '#' + weekday;

                return datetime;
            }

            function dataAtual(){
                var currentdate = new Date(); 
                var datetime = currentdate.getDate().toString().padStart(2, '0') + "/"
                            + (currentdate.getMonth()+1).toString().padStart(2, '0')  + "/" 
                            + currentdate.getFullYear() + " - "  
                            + currentdate.getHours().toString().padStart(2, '0') + ":"  
                            + currentdate.getMinutes().toString().padStart(2, '0') + ":" 
                            + currentdate.getSeconds().toString().padStart(2, '0');

                return datetime;
            }

            var tokenHash = CryptoJS.MD5(
                'aqbank_key@'
                +url_user.nome_completo.replace(/\s/g, '').substr(0, 9).toUpperCase()+''
                +url_user.nome_completo.replace(/\s/g, '').substr(-8).toUpperCase()
                +'#'+url_user.documento.replace(/([^\d])+/gim, '').substr(0, 3)
                +'#'+url_user.documento.replace(/([^\d])+/gim, '')
                +'#'+url_user.data_nascimento.replace(/([^\d])+/gim, '')
                +'#d'+dataAtualFormatada());

            url_new += '&aqbank_key='+tokenHash.toString() +'&date_atual='+dataAtual();
        }

        return url_new;

    }
    return (
        <>
            <Div>
                <Row style={{placeContent: 'center', width: '100%', margin: 0}}>
                    <Col md={10} sm={12}>
                        <br/>
                        <img style={{ width: '200px'}} src={aqcontabil} />
                        <DataPayment>
                                {/* <div id="header-title">
                                    <h5 style={{margin: 0}}>&nbsp;&nbsp;CARRINHO - AQCONTABIL</h5>
                                </div> */}
                                {step === 0 ? 
                                    <PageInicio
                                        maquininhas={maquininhas}
                                        cupom={cupom}
                                        integracao={validar_se_existe_integracao()}
                                        maquininhas_list={maquininhas_list}
                                        adicionar_quantidade={adicionar_quantidade}
                                        remover_maquina={remover_maquina}
                                        setStepCurrent={setStepCurrent}
                                        setVisibleMaquininhas={setVisibleMaquininhas}
                                        addCupom={addCupom}
                                        removeCupom={setCupom}
                                        url_user={url_user}
                                        setTipoValue={setTipoValue}
                                        setFuncionario={setFuncionario}
                                        funcionario={funcionario}
                                        setCertificadoA1={setCertificadoA1}
                                        certificado_a1={certificado_a1}
                                        endereco_fical={endereco_fical}
                                        setEnderecoFiscal={setEnderecoFiscal}
                                        setRegiao={setRegiao}
                                    />
                                    :
                                    step === 1 ?
                                        <StepPessoais
                                            maquininhas={maquininhas}
                                            cupom={cupom}
                                            funcionarios={funcionario}
                                            endereco_fical={endereco_fical}
                                            setCertificadoA1={setCertificadoA1}
                                            certificado_a1={certificado_a1}
                                            validar_cupom={validar_cupom}
                                            maquininhas_list={maquininhas_list}
                                            adicionar_quantidade={adicionar_quantidade}
                                            remover_maquina={remover_maquina}
                                            setStepCurrent={setStepCurrent}
                                            setUserId={setUserId}
                                            setTokenEmail={setTokenEmail}
                                            setCartoes={setCartoes}
                                            setValidarCpf={setValidarCpf}
                                            setUrlUser={setUrlUser}
                                            setTipoValue={setTipoValue}
                                        />
                                    :
                                    step === 2 ?
                                        <StepPagamento
                                            maquininhas={maquininhas}
                                            cupom={cupom}
                                            maquininhas_list={maquininhas_list}
                                            funcionarios={funcionario}
                                            adicionar_quantidade={adicionar_quantidade}
                                            remover_maquina={remover_maquina}
                                            user_id={user_id}
                                            cartoes={cartoes}
                                            token_email={token_email}
                                            validar_cpf={validar_cpf}
                                            aprovar_pagamento_por_boleto={aprovar_pagamento_por_boleto}
                                            aprovar_pagamento_por_cartao={aprovar_pagamento_por_cartao}
                                            aprovar_pagamento_por_cupom={aprovar_pagamento_por_cupom}
                                            reprovar_pagamento={reprovar_pagamento}
                                            url_user={url_user}
                                            shop_aqpago={shop_aqpago}
                                            funcionario={funcionario}
                                            endereco_fical={endereco_fical}
                                            certificado_a1={certificado_a1}
                                            regiao={regiao}
                                            opcao_contratacao={tipo_value}
                                            setTipoValue={setTipoValue}
                                        />
                                    :
                                    step === 3 ?
                                        <StepFinal 
                                            maquininhas={maquininhas}
                                            api={request_final}
                                            cupom={cupom}
                                            url_loja={url_loja}
                                            registrar_loja_btn={registrar_loja_btn}
                                            url_user={url_user}
                                            funcionarios={funcionario}
                                            endereco_fical={endereco_fical}
                                            regiao={regiao}
                                            certificado_a1={certificado_a1}
                                        />
                                    :
                                    <div>
                                        <br/>
                                        <div style={{textAlign: 'center'}} hidden={integracao_visible}>
                                            <Button 
                                                style={{fontSize: '20px'}}
                                                color="success"
                                                disabled={integracao_loading}
                                                onClick={() => integrarPagamento()}
                                            >
                                                {integracao_loading == true ?
                                                    
                                                    <ClipLoader color="#fff" size={20}/>
                                                    :
                                                    null
                                                }
                                                Concluir Integração de Pagamento
                                            </Button>
                                        </div>
                                        <h4 style={{textAlign: 'center'}}  hidden={!integracao_visible}>
                                            Sua solicitação de integração de<br/> pagamento foi concluida <MdCheck size="40" color="green"/> 
                                        </h4>
                                    </div>
                                }
                        </DataPayment>
                    </Col>
                </Row>
                <ModalLoja 
                    visible={visible_loja}
                    onClose={() => {
                        setVisibleLoja(false);
                    }}
                />
                <ModalMaquinas 
                    visible={visible_maquininhas}   
                    maquininhas={maquininhas}
                    adicionar_maquininha={(modelo) => {
                        setVisibleMaquininhas(false);
                        adicionar_maquininha(modelo)
                    }}
                    onClose={() => setVisibleMaquininhas(false)}
                />
            </Div>
            <GoogleAds />
        </>
    )
}

export default Principal;