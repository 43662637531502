//LOCAL
//export const ENDPOINT = 'http://127.0.0.1:8000/api/';

//LOCAL IP
//export const ENDPOINT = 'http://192.168.137.1:8000/api/';

//PRODUCTION
//export const ENDPOINT = 'https://apiadmin.aqpasso.com.br/api/';

//ENDPOINT Chat
export const ENDPOINT = 'https://apicrm.aqbank.com.br/api/';