
import { useState } from "react";
import { IoCalculator } from "react-icons/io5"
import { MdAdd, MdArrowForward, MdCheck, MdClear, MdDelete, MdRemove } from "react-icons/md";
import { RiSubtractLine } from "react-icons/ri";

import { Button, ButtonGroup, Card, CardBody, CardImg, Col, Collapse, Input, Label, Row } from "reactstrap";
import styled from "styled-components";
import aqcontabil_lines from './../../assets/lines-back.svg';


const PagamentoDetalhes = styled.div`
text-align: center;
align-self: center;
.tabela-machine-sifrao{
    font-size: 1.2rem;
}
.valor-painel{
    display: flex;
    place-content: center;
    .secound-value{
        border-left: 1px solid
    }
    h4{
        padding-right: 15px;
        padding-left: 15px;
        small{
            margin: 3px;
            font-size: 13px
        }
    }
}
.title-painel{
}
.painel-valor-total{
}
`

const DataPayment = styled.div`
    background-color: #F1FCF9;
    border-radius: 5px;
    @media screen and (max-width: 768px) {
        .border-card{
            margin: 13px;
        }
    } 
    height: 100%;
    padding: 34px;
`

export const PageInicio = (props) => {
    const [funcionario, setFuncionario] = useState(0);
    const [certificado_a1, setCertificadoA1] = useState(0);
    const [endereco_fical, setEnderecoFiscal] = useState(0);
    const [regiao, setRegiao] = useState(0);
    const [cupom, setCupom] = useState('');

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);



    const getImage = (modelo) => {
        var image_src = '';
        props.maquininhas_list.forEach(e => {
            if (modelo === e.modelo) {
                image_src = e.image
            }
        });

        return image_src;
    }
    const getValorTotal = () => {
        var valor = 0;

        props.maquininhas.forEach(e => {
            valor += (e.valor * e.quantidade);

            if (funcionario) {
                var funcionarios = parseInt(funcionario);

                if (funcionarios > e.funcionarios) {
                    var valor_adicional = 25 * (funcionarios - e.funcionarios);
                    valor += valor_adicional;
                }
            }


            if (certificado_a1) {
                if (certificado_a1 > 0) {
                    var valor_adicional_a1 = 99;
                    valor += valor_adicional_a1;
                }
            }

            

            if (endereco_fical) {
                if (endereco_fical > 0) {
                    var valor_adicional_end = 99;
                    valor += valor_adicional_end;
                }
            }
        });

        if (props.cupom !== null) {
            if (props.cupom.condicao !== 'plano') {
                if (props.cupom.valor_tipo == 'porcentagem') {
                    var desconto = (valor * props.cupom.valor) / 100;
                    valor = valor - desconto;
                }
                else {
                    valor = valor - props.cupom.valor;
                }
            }
            else {
                var desconto = 0;

                props.maquininhas.forEach(e => {
                    if (e.texto == props.cupom.plano) {
                        if (props.cupom.valor_tipo == 'porcentagem') {
                            desconto = (e.valor * props.cupom.valor) / 100;
                            valor = valor - desconto;
                        }
                        else {
                            valor = valor - props.cupom.valor;
                        }
                    }
                })

            }
        }

        return valor;
    }


    const validar_integracao = () => {
        var validar = false;
        props.maquininhas.forEach(p => {
            if (p.modelo == 'INTEGRACAO-PAGAMENTO') {
                validar = true;
            }
        });

        return validar;
    }

    const nextStep = () => {
        props.setStepCurrent(1);
        props.setFuncionario(funcionario);
        props.setCertificadoA1(certificado_a1);
        props.setEnderecoFiscal(endereco_fical);
        props.setRegiao(regiao);
    }

    return (
        <>
            <br />
            <div className="mobile-pagamento">
                <Button className="button-payment-resumo" color="light" onClick={toggle} style={{ marginBottom: '1rem', width: '100%' }}>
                    Ver resumo de compra
                </Button>
                <Collapse isOpen={dropdownOpen}>
                    <DataPayment>
                        <PagamentoDetalhes>
                            <h4 className="title-painel">Resumo</h4>
                            <br />
                            <Button
                                color="secondary"
                                outline
                                onClick={() => props.setVisibleMaquininhas(true)}
                                style={{
                                    fontSize: '14px',
                                    fontWeight: '700'
                                }}
                            >Trocar Plano</Button><br />
                            {props.maquininhas.map(p => (
                                <div>
                                    <br />
                                    <small style={{ color: '#65656E', fontSize: '14px' }}>Plano selecionado:</small>
                                    <h5 className="bold" style={{ fontSize: '14px' }}>{p.texto2}</h5>
                                    <div style={{ marginTop: 0 }}>
                                        <h3 style={{ color: '#174945', fontSize: '36px', fontWeight: '600', margin: 0 }}>R$ {parseFloat(p.valor * p.quantidade).toFixed(2).replace('.', ',')}</h3>
                                        <b style={{ color: '#737373', fontSize: '16px' }}>/mês</b>
                                    </div>
                                </div>
                            ))}
                            <br />
                            <hr style={{ backgroundColor: 'rgb(5, 189, 174)' }} />
                            <div className="painel-valor-total">
                                {validar_integracao() == false ?
                                    <div>
                                        <span style={{ fontSize: '16px', fontWeight: '400' }}>Valor total</span>
                                        <div className="valor-painel">
                                            <h5 className="bold" style={{ fontSize: '24px', fontWeight: '600', color: '#2F9461' }}>
                                                <span className="tabela-machine-sifrao">R$ </span>{parseFloat(getValorTotal()).toFixed(2).replace('.', ',')}
                                            </h5>
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                        </PagamentoDetalhes>

                    </DataPayment>
                </Collapse>
            </div>
            <Row>
                <Col md={8} className="border-card">
                    <div
                        style={{ padding: '15px' }}>
                        {props.maquininhas.map((p, index) => (
                            <div>
                                <div style={{ marginLeft: '5px', maxWidth: '600px' }}>
                                    <p style={{ fontSize: '16px', lineHeight: '19.2px', fontWeight: '400' }}>
                                        Informe a quantidade de funcionários que sua empresa já tem registrado ou que você já vai registrar
                                    </p>
                                    <div className="funcionarios_adicionais_div">
                                        <Row>
                                            <Col style={{ maxWidth: '174px' }}>
                                                <div style={{ maxWidth: '170px', margin: 8, display: 'flex' }}>
                                                    <Button
                                                        className="btn-color-default"
                                                        style={{
                                                            border: '100%',
                                                            borderRadius: '100%'
                                                        }}
                                                        disabled={funcionario < 1}
                                                        onClick={() => setFuncionario(funcionario - 1)}
                                                    >
                                                        <MdRemove style={{ padding: 0, height: '25px' }} />
                                                    </Button>
                                                    <Input
                                                        placeholder="Funcionarios"
                                                        type="number"
                                                        min={0}
                                                        disabled
                                                        value={funcionario}
                                                        style={{
                                                            padding: '5px',
                                                            border: 'none',
                                                            width: '59px',
                                                            textAlign: 'center',
                                                            backgroundColor: 'white'
                                                        }}
                                                        onChange={(e) => setFuncionario(e.target.value)}
                                                    />
                                                    <Button
                                                        className="btn-color-default"
                                                        style={{
                                                            border: '100%',
                                                            borderRadius: '100%',
                                                        }}
                                                        onClick={() => setFuncionario(funcionario + 1)}
                                                    >
                                                        <MdAdd style={{ padding: 0, height: '25px' }} />
                                                    </Button>
                                                </div>
                                            </Col>
                                            <Col>
                                                {p.funcionarios < funcionario ?
                                                    <small><b>Taxa: </b><br />{funcionario - p.funcionarios} Funcionario{funcionario - p.funcionarios > 1 ? 's' : ''} Adiciona{funcionario - p.funcionarios > 1 ? 'is' : 'l'} - R$ {parseFloat(25 * (funcionario - p.funcionarios)).toFixed(2)}</small>
                                                    : <></>}
                                            </Col>
                                        </Row>
                                    </div>
                                    <br />
                                    <div className="certificado_a1_div">
                                        <p style={{ fontSize: '16px', lineHeight: '19.2px', fontWeight: '400' }}>
                                            Deseja adicionar Certificado A1?
                                        </p>
                                        <Row>
                                            <Col style={{ maxWidth: '174px' }}>
                                                <div style={{ maxWidth: '170px', margin: 8, display: 'flex' }}>
                                                    <Button
                                                        className="btn-color-default"
                                                        style={{
                                                            border: '100%',
                                                            borderRadius: '100%'
                                                        }}
                                                        disabled={certificado_a1 < 1}
                                                        onClick={() => setCertificadoA1(certificado_a1 - 1)}
                                                    >
                                                        <MdRemove style={{ padding: 0, height: '25px' }} />
                                                    </Button>
                                                    <Input
                                                        placeholder="Certificado A1"
                                                        type="number"
                                                        min={0}
                                                        disabled
                                                        value={certificado_a1}
                                                        style={{
                                                            padding: '5px',
                                                            border: 'none',
                                                            width: '59px',
                                                            textAlign: 'center',
                                                            backgroundColor: 'white'
                                                        }}
                                                        onChange={(e) => setCertificadoA1(e.target.value)}
                                                    />
                                                    <Button
                                                        className="btn-color-default"
                                                        style={{
                                                            border: '100%',
                                                            borderRadius: '100%',
                                                        }}
                                                        disabled={certificado_a1 > 0}
                                                        onClick={() => setCertificadoA1(certificado_a1 + 1)}
                                                    >
                                                        <MdAdd style={{ padding: 0, height: '25px' }} />
                                                    </Button>
                                                </div>
                                            </Col>
                                            <Col>
                                                <small><b>Valor: </b><br /> R$ 99,00</small>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="endereco_fiscal_div">
                                        <br/>
                                        <p style={{ fontSize: '16px', lineHeight: '19.2px', fontWeight: '400' }}>
                                            Deseja adicionar Endereço fiscal e comercial?
                                        </p>
                                        <Row>
                                            <Col style={{ maxWidth: '174px' }}>
                                                <div style={{ maxWidth: '170px', margin: 8, display: 'flex' }}>
                                                    <Button
                                                        className="btn-color-default"
                                                        style={{
                                                            border: '100%',
                                                            borderRadius: '100%'
                                                        }}
                                                        disabled={endereco_fical < 1}
                                                        onClick={() => setEnderecoFiscal(endereco_fical - 1)}
                                                    >
                                                        <MdRemove style={{ padding: 0, height: '25px' }} />
                                                    </Button>
                                                    <Input
                                                        placeholder="Endereço Fiscal"
                                                        type="number"
                                                        min={0}
                                                        disabled
                                                        value={endereco_fical}
                                                        style={{
                                                            padding: '5px',
                                                            border: 'none',
                                                            width: '59px',
                                                            textAlign: 'center',
                                                            backgroundColor: 'white'
                                                        }}
                                                        onChange={(e) => setEnderecoFiscal(e.target.value)}
                                                    />
                                                    <Button
                                                        className="btn-color-default"
                                                        style={{
                                                            border: '100%',
                                                            borderRadius: '100%',
                                                        }}
                                                        disabled={endereco_fical > 0}
                                                        onClick={() => setEnderecoFiscal(endereco_fical + 1)}
                                                    >
                                                        <MdAdd style={{ padding: 0, height: '25px' }} />
                                                    </Button>
                                                </div>
                                            </Col>
                                            <Col>
                                                <small><b>Valor: </b><br /> R$99,00</small>
                                            </Col>
                                        </Row>
                                        <br/>
                                        <p style={{ fontSize: '16px', lineHeight: '19.2px', fontWeight: '400' }}>
                                            Em qual região?
                                        </p>
                                        <Input 
                                            placeholder="Região (Cidade)" 
                                            style={{
                                                width: '170px'
                                            }}
                                            onChange={(e) => setRegiao(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div style={{ marginTop: '100px', width: '95%' }}>
                            <div style={{ display: 'flex' }} className="cupom-panel" hidden={props.integracao}>
                                {props.cupom === null ?
                                    <>
                                        <Input
                                            placeholder="Cupom"
                                            style={{
                                                //paddingLeft: '24px',
                                                width: '155px'
                                            }}
                                            onChange={(e) => setCupom(e.target.value)}
                                        />&nbsp;&nbsp;
                                        <Button
                                            className="btn-color-default"
                                            onClick={() => props.addCupom(cupom)}
                                        >Ativar</Button>
                                    </>
                                    :
                                    <div>
                                        <p style={{ color: 'green' }}><MdCheck /> Cupom de desconto adicionado&nbsp;<br />
                                            <Button
                                                color="danger"
                                                outline
                                                onClick={() => props.removeCupom(null)}
                                                style={{
                                                    borderRadius: '30px',
                                                    padding: 0,
                                                    paddingLeft: '3px',
                                                    paddingRight: '3px',
                                                    fontSize: '12px',
                                                    border: 'none'
                                                }}
                                            ><MdClear /> Remover cupom</Button>
                                        </p>
                                    </div>
                                }
                            </div>
                            <br />
                            <Button
                                className="btn-next"
                                disabled={props.maquininhas.length > 0 ? parseInt(funcionario, 10) < 0 ? true : false : true} onClick={() => nextStep()}>
                                Continuar <MdArrowForward />
                            </Button>
                        </div>
                    </div>
                </Col>
                <Col md={4} className="desktop-pagamento">
                    <DataPayment>
                        <PagamentoDetalhes>
                            <h4 className="title-painel">Resumo</h4>
                            <br />
                            <Button
                                color="secondary"
                                outline
                                onClick={() => props.setVisibleMaquininhas(true)}
                                style={{
                                    fontSize: '14px',
                                    fontWeight: '700'
                                }}
                            >Trocar Plano</Button><br />
                            {props.maquininhas.map(p => (
                                <div>
                                    <br />
                                    <small style={{ color: '#65656E', fontSize: '14px' }}>Plano selecionado:</small>
                                    <h5 className="bold" style={{ fontSize: '14px' }}>{p.texto2}</h5>
                                    <div style={{ marginTop: 0 }}>
                                        <h3 style={{ color: '#174945', fontSize: '36px', fontWeight: '600', margin: 0 }}>R$ {parseFloat(p.valor * p.quantidade).toFixed(2).replace('.', ',')}</h3>
                                        <b style={{ color: '#737373', fontSize: '16px' }}>/mês</b>
                                    </div>
                                </div>
                            ))}
                            <br />
                            <hr style={{ backgroundColor: 'rgb(5, 189, 174)' }} />
                            <div className="painel-valor-total">
                                {validar_integracao() == false ?
                                    <div>
                                        <span style={{ fontSize: '16px', fontWeight: '400' }}>Valor total</span>
                                        <div className="valor-painel">
                                            <h5 className="bold" style={{ fontSize: '24px', fontWeight: '600', color: '#2F9461' }}>
                                                <span className="tabela-machine-sifrao">R$ </span>{parseFloat(getValorTotal()).toFixed(2).replace('.', ',')}
                                            </h5>
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                        </PagamentoDetalhes>

                    </DataPayment>
                </Col>
            </Row>
        </>
    );
}