import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

const GoogleAdsDefault = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag(){
        window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-B4Z56QG7DY');
    
    return <>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-B4Z56QG7DY"></script>
    </>
}

const MP5GoogleAds = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag(){
        window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'AW-10865717564');

    return <>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-10865717564"></script>
    </>
}

const D195GoogleAds = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag(){
        window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'AW-10865717564');

    return <>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-10865717564"></script>
    </>
}

const S920GoogleAds = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag(){
        window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'AW-10865717564');
    return <>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-10865717564"></script>
    </>
}

const MP35PGoogleAds = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag(){
        window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'AW-10865717564');

    return <>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-10865717564"></script>
    </>
}

export default () => {
    return (
      <BrowserRouter>
          <Switch>
              <Route path="/" component={GoogleAdsDefault} exact/>
              <Route path="/mp5" component={MP5GoogleAds} exact/>
              <Route path="/d195" component={D195GoogleAds} exact/>
              <Route path="/s920" component={S920GoogleAds} exact/>
          </Switch>
          <ToastContainer hideProgressBar/>
      </BrowserRouter>
    );

}